import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Row,
    Col,
    Space,
    Typography,
} from 'antd';
import { InfoCircleIcon } from '@/assets/images/icons/screens';
import { selectors } from '@/redux/invoice/create/model';
import PersonCard from './PersonCard';
import PersonNumberInput from './PersonNumberInput';

const Participants = ({
    form,
    onFinish,
    clients,
    usedFor,
}) => {
    const { t } = useTranslation();

    const reservationDataFromCreate = useSelector(selectors.reservationData);
    const invoiceDetails = useSelector(selectors.invoiceDetails);
    // define reservation type
    const reservationType = reservationDataFromCreate?.reservation_type
    || (invoiceDetails !== null && invoiceDetails.invoice_data.reservation_type);

    const [types, setTypes] = useState([
        { id: 'adults', singular: t('Adult'), plural: t('Adults') },
        { id: 'children', singular: t('Child'), plural: t('Children') },
        { id: 'infants', singular: t('Infant'), plural: t('Infants') },
    ]);

    const [participants, setParticipants] = useState(() => {
        return types.reduce((endState, type) => ({
            ...endState,
            [type.id]: 0,
        }), {});
    });

    const onChange = useCallback((type, count) => {
        setParticipants((prev) => ({
            ...prev,
            [type]: count,
        }));
    }, [setParticipants]);

    useEffect(() => {
        if (clients?.[0].adults !== 0) {
            clients?.forEach((c) => (
                setParticipants((prev) => ({
                    ...prev,
                    [Object.keys(c)[0]]: Object.values(c)[0],
                }))));
            setTypes(types.map((type, i) => ({
                ...type,
                value: clients?.[i][type.id],
            })));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [clients]);

    return (
        <Form
            form={form}
            className="participants"
            onFinish={onFinish}
        >
            <Row justify="space-between" gutter={[30, 30]}>
                {reservationType === 'Hotel' ? (
                    types.slice(0, 2).map((type) => (
                        <Col xxl={8} lg={12} md={8} sm={12} xs={24} key={type.id}>
                            <PersonNumberInput
                                type={type}
                                onChange={onChange}
                            />
                        </Col>
                    ))
                ) : (
                    types.map((type) => (
                        <Col xxl={8} lg={12} md={8} sm={12} xs={24} key={type.id}>
                            <PersonNumberInput
                                type={type}
                                onChange={onChange}
                            />
                        </Col>
                    ))
                )}
            </Row>

            {types.map((type) => new Array(participants[type.id])
                .fill(null)
                .map((_, i) => (
                    <div key={`${type.id} ${i + 1}`}>
                        <PersonCard
                            title={type.singular}
                            name={type.id}
                            index={i}
                            usedFor={usedFor}
                        />
                        {usedFor === 'flights' && type.id === 'adults' && i === 0 && (
                            <Space>
                                <InfoCircleIcon />
                                <Typography.Text>
                                    {t('The first adult will be used for invoice details')}
                                </Typography.Text>
                            </Space>
                        )}
                    </div>
                )))}
        </Form>
    );
};

export default Participants;
