import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Divider,
    Button,
    Form,
} from 'antd';
import { PlusIcon, DeleteIcon } from '@/assets/images/icons/screens';
import PriceItem from './PriceItem';

const ChildPricePolicy = ({ currency }) => {
    const { t } = useTranslation();

    return (
        <Row gutter={[28, 0]}>
            <Col xs={24}>
                <Form.List name="prices">
                    {(fields, { add, remove }) => (
                        <>
                            {fields.map((field, index) => (
                                <div key={field.key} className="child-price-policy-item">
                                    <Form.List
                                        name={[field.name, `child_${index + 1}`]}
                                        initialValue={[{}]}
                                    >
                                        {(childFields, { add: addChild, remove: removeChild }) => (
                                            <>
                                                {childFields.map((childField) => (
                                                    <PriceItem
                                                        key={childField.key}
                                                        field={childField}
                                                        remove={removeChild}
                                                        formListName="prices"
                                                        nestedFormListName={`child_${index + 1}`}
                                                        currency={currency}
                                                        sectionIndex={field.key}
                                                    />
                                                ))}
                                                <Row justify="end" gutter={16}>
                                                    <Col>
                                                        <Button
                                                            className="button add-button-outlined"
                                                            onClick={() => addChild()}
                                                        >
                                                            <PlusIcon />
                                                            {t('add policy').toUpperCase()}
                                                        </Button>
                                                    </Col>
                                                    <Col>
                                                        <Button
                                                            className="button btn-grey"
                                                            onClick={() => remove(field.name)}
                                                        >
                                                            <DeleteIcon />
                                                            {t('remove child policy').toUpperCase()}
                                                        </Button>
                                                    </Col>
                                                </Row>
                                                <Divider dashed />
                                            </>
                                        )}
                                    </Form.List>
                                </div>
                            ))}
                            <Form.Item className="add-button-form">
                                <Row justify="end">
                                    <Col>
                                        <Button
                                            className="button add-button-outlined"
                                            onClick={() => add()}
                                        >
                                            <PlusIcon />
                                            {t('add child policy').toUpperCase()}
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
            </Col>
        </Row>
    );
};

export default ChildPricePolicy;
