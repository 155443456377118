import { createActions, createReducer } from 'reduxsauce';
import initialState from './model';

export const { Types: types, Creators: actions } = createActions({
    // view agency details
    requestViewAgencyDetails: ['agencyId'],
    viewAgencyDetailsSucceeded: ['data'],
    viewAgencyDetailsFailed: ['error'],

    // get agency services
    requestGetAgencyServices: ['agencyId', 'filters', 'isFetchingMore'],
    getAgencyServicesSucceeded: ['data'],
    getAgencyServicesFailed: ['error'],

    // fetch more agency services
    getMoreAgencyServices: ['filters'],
    getMoreAgencyServicesSucceeded: ['data'],
    getMoreAgencyServicesFailed: ['error'],
});

export default createReducer(initialState, {
    // view agency details
    [types.REQUEST_VIEW_AGENCY_DETAILS]: (state) => ({
        ...state,
        loading: true,
        error: null,
    }),
    [types.VIEW_AGENCY_DETAILS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        agencyDetails: data,
        loading: false,
        error: null,
    }),
    [types.VIEW_AGENCY_DETAILS_FAILED]: (state, { error }) => ({
        ...state,
        loading: false,
        error,
    }),

    // get agency services
    [types.REQUEST_GET_AGENCY_SERVICES]: (state, { isFetchingMore }) => ({
        ...state,
        fetchingLoading: !isFetchingMore,
        fetchingError: null,
    }),
    [types.GET_MORE_AGENCY_SERVICES]: (state) => ({
        ...state,
        isLoadingMore: true,
        isLoadingMoreError: null,
    }),
    [types.GET_AGENCY_SERVICES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        agencyServices: data,
        fetchingLoading: false,
        fetchingError: null,
        hasMore: true,
    }),
    [types.GET_MORE_AGENCY_SERVICES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        agencyServices: [...state.view.agencyServices, ...data],
        fetchingLoading: false,
        fetchingError: null,
        isLoadingMore: false,
        hasMore: data.length !== 0,
    }),
    [types.GET_AGENCY_SERVICES_FAILED]: (state, { error }) => ({
        ...state,
        fetchingLoading: false,
        fetchingError: error,
    }),
    [types.GET_MORE_AGENCY_SERVICES_FAILED]: (state, { error }) => ({
        ...state,
        isFetchingMore: false,
        isLoadingMoreError: error,
    }),
});
