import {
    useCallback,
    useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Form,
    Typography,
    Row,
    Col,
    Button,
} from 'antd';
import DashedContainer from '@/components/DashedContainer';
import TourExtraServices from '@/components/TourExtraServices';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { extractUrlFromFile } from '@/helpers/upload';
import { returnDeletedImages } from '@/helpers/deleteImages';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { selectors } from '@/redux/accommodation/hotel/create/model';
import { useCurrency } from '@/hooks/useCurrency';
import { formatExtraServicesForSubmit, eServicesFormat } from '@/helpers/mapping';

import RoomDetails from './RoomDetails';
import PersonAndBed from './PersonAndBed';
import ExtraBed from './ExtraBed';
import Amenities from './Amenities';
import ImagesUpload from './ImagesUpload';
import ChildPricePolicy from './ChildPricePolicy';

const formFields = [
    'type_name',
    'room_size',
    'no_rooms',
    'price',
    'commission',
    'commission_type',
    'adults',
    'adult_bed_no',
    'bed_name_adult',
    'children',
    'children_bed_no',
    'bed_name_children',
    'infants',
    'infant_bed_no',
    'bed_name_infant',
    'extra_beds',
    'extra_bed_charge',
    'extra_bed_charges_type',
    'amenities',
];

const AddNewRoom = ({ hotelId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { icon: currencyIcon } = useCurrency();

    const goToRoomList = useCallback(() => {
        dispatch(actions.goToViewRoomList());
    }, [dispatch]);

    const room = useSelector(selectors.roomDetails);

    const initialImages = room?.images;

    const onFinish = useCallback((values) => {
        const data = {
            type_name: values.type_name,
            room_size: values.room_size,
            no_rooms: values.no_rooms,

            price: values.price,
            commission: values.commission,
            commission_type: values.commission_type,

            price_bb: values.price_bb,
            commission_bb: values.commission_bb,
            commission_type_bb: values.commission_type_bb,

            price_bh: values.price_bh,
            commission_bh: values.commission_bh,
            commission_type_bh: values.commission_type_bh,

            price_ai: values.price_ai,
            commission_ai: values.commission_ai,
            commission_type_ai: values.commission_type_ai,

            adults: values.adults,
            adult_bed_no: values.adult_bed_no,
            bed_name_adult: values.bed_name_adult,

            children: values.children,
            children_bed_no: values.children_bed_no,
            bed_name_children: values.bed_name_children,

            extra_beds: values.extra_beds,
            extra_bed_charge: values.extra_bed_charge,
            extra_bed_charges_type: values.extra_bed_charges_type,

            prices: values.prices,

            amenities: values.amenities,
            images: values.images
                ? values.images.fileList?.map(extractUrlFromFile)
                : [],
            deleted_images: returnDeletedImages(initialImages, values.images?.fileList),
            extra_services: values.extra_services
                ? formatExtraServicesForSubmit(values.extra_services)
                : [],
        };
        dispatch(actions.requestSubmitAccommodationRoom(data));
    }, [dispatch]);

    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    const initialData = useMemo(() => {
        if (room) {
            return {
                type_name: room.type_name,
                room_size: room.room_size,
                no_rooms: room.no_rooms,

                price: room.price,
                commission: room.commission,
                commission_type: room.commission_type === 'Percentage' ? 4 : 5,

                price_bb: room.price_bb,
                commission_bb: room.commission_bb,
                commission_type_bb: room.commission_type_bb === 'Percentage' ? 4 : 5,

                price_bh: room.price_bh,
                commission_bh: room.commission_bh,
                commission_type_bh: room.commission_type_bh === 'Percentage' ? 4 : 5,

                price_ai: room.price_ai,
                commission_ai: room.commission_ai,
                commission_type_ai: room.commission_type_ai === 'Percentage' ? 4 : 5,

                adult_bed_no: room.adult_bed_no,
                bed_name_adult: room.bed_name_adult,

                children_bed_no: room.children_bed_no,
                bed_name_children: room.bed_name_children,

                extra_beds: room.extra_beds,
                extra_bed_charge: room.extra_bed_charge,
                extra_bed_charges_type: room.extra_bed_charges_type === 'Percentage' ? 4 : 5,

                prices: room.prices,

                amenities: room.amenities.selected
                    ? room.amenities.selected.map((amenity) => (amenity.id))
                    : [],
                images: room.images.map((image) => (
                    {
                        id: image.id,
                        url: image.path,
                        thumbUrl: image.path,
                    }
                )),
                extra_services: eServicesFormat(room.services),
            };
        } else {
            return {
                commission_type: 4,
                commission_type_bb: 4,
                commission_type_bh: 4,
                commission_type_ai: 4,
                extra_bed_charges_type: 4,
            };
        }
    }, [room]);

    return (
        <Form
            id="hotel-rooms-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={initialData || {}}
        >
            <Form.Item>
                <RoomDetails currency={currencyIcon} data={room} />
            </Form.Item>

            <Form.Item>
                <PersonAndBed />
            </Form.Item>

            <Form.Item>
                <ExtraBed currency={currencyIcon} />
            </Form.Item>

            <div className="child-price-policy">
                <Form.Item>
                    <ChildPricePolicy currency={currencyIcon} />
                </Form.Item>
            </div>

            <DashedContainer title="Amenities">
                <Form.Item>
                    <Amenities
                        hotelId={hotelId}
                        initialAmenities={initialData?.amenities}
                    />
                </Form.Item>
            </DashedContainer>

            <DashedContainer title="Extra Services">
                <TourExtraServices
                    name="extra_services"
                    checkboxText="The price is inclusive to the tour"
                />
            </DashedContainer>

            <Form.Item>
                <Typography.Text className="images-upload_text">
                    {t('Room Photo Gallery')}
                </Typography.Text>
                <ImagesUpload />
            </Form.Item>

            <Form.Item>
                <Row justify="end">
                    <Col pull={1}>
                        <Button
                            danger
                            type="primary"
                            onClick={goToRoomList}
                        >
                            {t('Cancel')}
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            {t('Submit')}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default AddNewRoom;
