import {
    useState,
    useMemo,
    useEffect,
    useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Radio } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from '@/components/ViewOneHeader';
import { selectors } from '@/redux/tour/trip/view/model';
import { actions } from '@/redux/tour/trip/view/reducer';
import { getSubPageByHash } from '@/helpers/routing';
import ProductCollection from '@/components/ProductCollection';

import TourInfo from './TourInfo';
import TourItenerary from './TourItenerary';
import Reservation from './Reservation';

const subPages = [
    { name: 'Tour Info', hash: 'tour-info' },
    { name: 'Tour Itenerary', hash: 'tour-itenerary' },
    { name: 'Reservation', hash: 'reservation' },
];

const ViewOne = ({ viewFromB2b = false }) => {
    const { t } = useTranslation();
    const { id } = useParams();
    const {
        location: { hash },
        push,
    } = useHistory();
    const dispatch = useDispatch();

    const loading = useSelector(selectors.loading);
    const generalInfo = useSelector(selectors.generalInfo);
    const { filterData: filters } = useSelector(selectors.groups);
    const { status: serviceStatus } = generalInfo !== null && generalInfo;
    const { b2b_agency_name: b2bAgency } = generalInfo !== null && generalInfo;

    const myService = b2bAgency === null; // if b2bAgency is null, it's my service

    const formattedSubPages = (myService) || (!myService && serviceStatus === '1')
        ? subPages : subPages;

    const [visibleDetails, setVisibleDetails] = useState(
        () => getSubPageByHash(subPages, hash).hash,
    );

    const options = useMemo(
        () => formattedSubPages.map((subPage) => ({
            label: t(subPage.name),
            value: subPage.hash,
        })),
        [formattedSubPages, t],
    );

    const ViewDetailsComponent = useMemo(() => {
        switch (visibleDetails) {
            default:
            case subPages[0].hash:
                return TourInfo;
            case subPages[1].hash:
                return TourItenerary;
            case subPages[2].hash:
                return Reservation;
        }
    }, [visibleDetails]);

    useEffect(() => {
        if (!viewFromB2b) {
            push(`/app/tours/trip/${id}#${visibleDetails}`);
        } else {
            push(`/app/b2b-partners/worldwide-supplier/trip/${id}#${visibleDetails}`);
        }
    }, [push, visibleDetails, id, viewFromB2b]);

    const [page, setPage] = useState(1);

    const handleFetchTripGroups = useCallback(
        ({ perPage = 10, currentPage = 1, fetchMore }) => {
            let data = null;
            if (filters) {
                data = {
                    ...filters,
                    page: currentPage,
                    // eslint-disable-next-line object-shorthand
                    perPage: perPage,
                };
            } else {
                data = {
                    page: currentPage,
                    // eslint-disable-next-line object-shorthand
                    perPage: perPage,
                    adults: 2,
                };
            }
            dispatch(actions.requestFetchTourTripGroups(id, data, fetchMore));
            setPage(currentPage + 1);
        },
        [
            dispatch,
            id,
            filters,
        ],
    );

    useEffect(() => {
        dispatch(
            actions.requestViewTourTrip(id),
        );
    }, [dispatch, id]);

    useEffect(() => {
        handleFetchTripGroups({});
    }, [handleFetchTripGroups]);

    // fetch more groups
    const handleFetchMore = useCallback(() => {
        handleFetchTripGroups({ currentPage: page, fetchMore: true });
    }, [handleFetchTripGroups, page]);

    return generalInfo === null || loading ? (
        'loading'
    ) : (
        <div id="view-tour-trip-page">
            {!myService
            && (serviceStatus === null || serviceStatus === '2')
            && (
                <ProductCollection
                    data={generalInfo}
                    serviceStatus={serviceStatus}
                    serviceId={id}
                />
            )}
            <Header
                goBackTitle={myService ? 'View all Tours' : 'Go back'}
                goBackUrl={myService ? '/app/tours/trip' : '/app/b2b-partners/worldwide-supplier'}
                title={generalInfo.name}
            >
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={options}
                    onChange={(e) => setVisibleDetails(e.target.value)}
                    value={visibleDetails}
                />
            </Header>

            <div className="view-content">
                <ViewDetailsComponent
                    myService={myService}
                    fetchMore={handleFetchMore}
                />
            </div>
        </div>
    );
};

export default ViewOne;
