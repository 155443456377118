import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Finished from '@/components/Invoice/InvoiceEndStep';
import { actions } from '@/redux/invoice/view/reducer';
import { selectors } from '@/redux/invoice/view/model';

const ViewOne = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const invoiceDetails = useSelector(selectors.invoiceDetails);
    const invoiceCustomer = useSelector(selectors.invoiceCustomer);
    const invoiceParticipants = useSelector(selectors.invoiceParticipants);
    const invoiceExtras = useSelector(selectors.invoiceExtras);
    const loading = useSelector(selectors.loading);
    const statusTypeList = useSelector(selectors.statusTypeList);

    useEffect(() => {
        dispatch(actions.requestViewInvoice(id));
        dispatch(actions.requestGetStatusTypeList());
    }, [dispatch, id]);

    return (invoiceDetails === null || loading ? 'loading' : (
        <Finished
            addUrl={`/app/invoices/${id}/edit`}
            backUrl="/app/invoices"
            invoiceDetails={invoiceDetails}
            invoiceCustomer={invoiceCustomer}
            invoiceParticipants={invoiceParticipants}
            invoiceExtras={invoiceExtras}
            statusTypeList={statusTypeList}
        />
    ));
};

export default ViewOne;
