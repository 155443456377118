import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    Table,
    Space,
    Modal,
} from 'antd';
import ActionIcon from '@/components/ActionIcon';
import InfoBlock from '@/components/Information/WithoutImage/Block';
import {
    DownLoadIcon,
    // EditIcon,
    EyeIcon,
    // PrintIcon,
    DeleteIcon,
} from '@/assets/images/icons/screens';
import { actions as createActions } from '@/redux/invoice/create/reducer';
import { actions as deleteActions } from '@/redux/invoice/delete/reducer';
import useNotification from '@/hooks/useNotification';

const InvoiceTable = ({ data }) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();

    const [product, setProduct] = useState(null);

    const deleteInvoice = useCallback((id) => {
        Modal.confirm({
            className: 'confirm-modal',
            title: `${t('Are you sure to delete this invoice')} ?`,
            onOk() {
                dispatch(deleteActions.requestDeleteInvoice(id));
            },
        });
    }, [dispatch, t]);

    const messageSuccess = useSelector((state) => state.invoice.delete.messageSuccess);
    const error = useSelector((state) => state.invoice.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: product,
        clearAction: deleteActions.clearDeleteInvoiceState(),
        mode: 'list',
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            sorter: true,
            width: '5%',
        },
        {
            title: t('Created').toUpperCase(),
            dataIndex: 'created',
            sorter: true,
            width: '15%',
        },
        {
            title: t('Product /Service').toUpperCase(),
            dataIndex: 'product',
            sorter: true,
            width: '20%',
        },
        {
            title: t('Customer').toUpperCase(),
            dataIndex: 'customer',
            sorter: true,
            width: '15%',
        },
        {
            title: t('Amount').toUpperCase(),
            dataIndex: 'amount',
            sorter: true,
            width: '15%',
        },
        {
            title: t('Status').toUpperCase(),
            dataIndex: 'status',
            sorter: true,
            width: '15%',
            render: (text, record) => {
                if (record.status === 1) {
                    return <p className="confirmed">{t('Confirmed')}</p>;
                } else if (record.status === 2) {
                    return <p className="pending">{t('Pending')}</p>;
                } else if (record.status === 3) {
                    return <p className="canceled">{t('Canceled')}</p>;
                } else return null;
            },
        },
        {
            title: t('Actions').toUpperCase(),
            dataIndex: 'actions',
            width: '15%',
            render: (text, record) => (
                <Space className="a-btns-m1" size="large">
                    <ActionIcon>
                        {/* <PrintIcon /> */}
                    </ActionIcon>
                    <ActionIcon>
                        <DownLoadIcon />
                    </ActionIcon>
                    <ActionIcon onClick={() => {
                        if (record.custom === '1') {
                            push(`/app/invoices/custom/${record.id}`);
                        } else {
                            push(`/app/invoices/${record.id}`);
                            dispatch(createActions.clearInvoiceState());
                        }
                    }}
                    >
                        <EyeIcon />
                    </ActionIcon>
                    {/* {record.service_type !== 'Custom' && (
                        <ActionIcon onClick={() => {
                            push(`/app/invoices/${record.id}/edit`);
                            dispatch(createActions.clearInvoiceState());
                        }}
                        >
                            <EditIcon />
                        </ActionIcon>
                    )} */}
                    <ActionIcon onClick={() => {
                        deleteInvoice(record.id);
                        setProduct(record.product.props.name);
                    }}
                    >
                        <DeleteIcon />
                    </ActionIcon>
                </Space>
            ),
        },
    ];

    const rowSelection = {};

    const datasource = data.map((row, i) => ({
        key: i,
        id: row.id,
        created:
    <InfoBlock name={row.date_insert} className="textSize14">
        <span className="textSize12">{row.added_by}</span>
    </InfoBlock>,
        product:
    <InfoBlock
        name={row.service_name}
        className="textSize14"
    >
        <span className="textSize12">
            {row.service_type}
        </span>
    </InfoBlock>,
        customer:
    <InfoBlock name={`${row.name} ${row.surname}`} className="textSize14">
        <span className="textSize12">{row.country}</span>
    </InfoBlock>,
        amount:
    <InfoBlock name={row.currency_icon?.concat(row.total)} className="textSize14">
        <span className="textSize12">
            {
                row.discount !== '0.00' && row.discount !== null
                    ? `${t('Discount')}: ${row.discount}`
                    : `${t('No discount')}`
            }
        </span>
    </InfoBlock>,
        status: row.status_id,
        custom: row.custom,
    }));

    return (
        <Table
            rowSelection={rowSelection}
            columns={columns}
            dataSource={datasource}
            pagination={false}
            rowKey={(record) => record.id}
            size="small"
        />
    );
};

export default InvoiceTable;
