import { createActions } from 'reduxsauce';
import model from './model';

export const { Types: types, Creators: actions } = createActions({
    // view invoice
    requestViewInvoice: ['id'],
    viewInvoiceSucceeded: [
        'generalData',
        'firstStep',
        'secondStep',
        'thirdStep',
    ],
    viewInvoiceFailed: ['error'],

    // pay invoice
    requestSubmitPayInvoice: ['id', 'data'],
    submitPayInvoiceSucceeded: ['message'],
    submitPayInvoiceFailed: ['error'],

    // download invoice pdf
    requestDownloadInvoicePdf: ['id'],
    downloadInvoicePdfSucceeded: ['message'],
    downloadInvoicePdfFailed: ['error'],

    // status type list
    requestGetStatusTypeList: null,
    getStatusTypeListSucceeded: ['data'],
    getStatusTypeListFailed: ['error'],

    // change invoice status
    requestChangeInvoiceStatus: ['data'],
    changeInvoiceStatusSucceeded: ['message'],
    changeInvoiceStatusFailed: ['error'],

    // clear invoice status
    clearInvoiceStatus: null,
});

export default {
    // view invoice
    [types.REQUEST_VIEW_INVOICE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            invoice: model.invoice,
            loading: true,
            error: null,
        },
    }),
    [types.VIEW_INVOICE_SUCCEEDED]: (
        state,
        {
            generalData,
            firstStep,
            secondStep,
            thirdStep,
        },
    ) => ({
        ...state,
        view: {
            ...state.view,
            invoiceDetails: generalData,
            invoiceCustomer: firstStep,
            invoiceParticipants: secondStep,
            invoiceExtras: thirdStep,
            loading: false,
        },
    }),
    [types.VIEW_INVOICE_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            loading: false,
            error,
        },
    }),

    // pay invoice
    [types.REQUEST_SUBMIT_PAY_INVOICE]: (state) => ({
        ...state,
        view: {
            ...state.view,
            submiting: true,
            error: null,
        },
    }),
    [types.SUBMIT_PAY_INVOICE_SUCCEEDED]: (state, { message }) => ({
        ...state,
        view: {
            ...state.view,
            message,
            loading: false,
        },
    }),
    [types.SUBMIT_PAY_INVOICE_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            error,
            loading: false,
        },
    }),

    // download invoice pdf
    [types.REQUEST_DOWNLOAD_INVOICE_PDF]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.DOWNLOAD_INVOICE_PDF_SUCCEEDED]: (state, { message }) => ({
        ...state,
        view: {
            ...state.view,
            message,
            loading: false,
        },
    }),
    [types.DOWNLOAD_INVOICE_PDF_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            error,
            loading: false,
        },
    }),

    // status type list
    [types.REQUEST_GET_STATUS_TYPE_LIST]: (state) => ({
        ...state,
        view: {
            ...state.view,
            loading: true,
            error: null,
        },
    }),
    [types.GET_STATUS_TYPE_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        view: {
            ...state.view,
            statusTypeList: data,
            loading: false,
        },
    }),
    [types.GET_STATUS_TYPE_LIST_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            error,
            loading: false,
        },
    }),

    // change invoice status
    [types.REQUEST_CHANGE_INVOICE_STATUS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            statusLoading: true,
            statusError: null,
        },
    }),
    [types.CHANGE_INVOICE_STATUS_SUCCEEDED]: (state, { message }) => ({
        ...state,
        view: {
            ...state.view,
            statusSuccess: message,
            statusLoading: false,
        },
    }),
    [types.CHANGE_INVOICE_STATUS_FAILED]: (state, { error }) => ({
        ...state,
        view: {
            ...state.view,
            statusError: error,
            statusLoading: false,
        },
    }),

    // clear invoice status
    [types.CLEAR_INVOICE_STATUS]: (state) => ({
        ...state,
        view: {
            ...state.view,
            statusLoading: false,
            statusSuccess: '',
            statusError: null,
        },
    }),
};
