import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
    Checkbox,
    Divider,
    DatePicker,
} from 'antd';
import { CalendarIcon } from '@/assets/images/icons/screens';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';

import CountrySelect from '../CountrySelect';
import MUIFormItem from '../MUIFormItem';
import GenderSelect from '../GenderSelect';
import baseValidations from './validations';

const AddCustomerForm = ({ isCompany = true }) => {
    const { t } = useTranslation();

    const validations = useTranslatedValidationConfig(baseValidations);

    const [checked, setChecked] = useState(false);

    return (
        <Row gutter={30}>
            <Col md={12} xs={24}>
                <MUIFormItem
                    name="name"
                    label={t('First Name')}
                    rules={validations.name}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col md={12} xs={24}>
                <MUIFormItem
                    name="surname"
                    label={t('Last Name')}
                    rules={validations.surname}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col md={12} xs={24}>
                <MUIFormItem
                    name="id_number"
                    label={t('ID Number')}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col md={12} xs={24}>
                <MUIFormItem
                    name="nationality"
                    label={t('Nationality')}
                    rules={validations.nationality}
                >
                    <CountrySelect />
                </MUIFormItem>
            </Col>

            <Col md={12} xs={24}>
                <MUIFormItem
                    name="phone"
                    label={t('Phone Number')}
                    rules={validations.phone}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col md={12} xs={24}>
                <MUIFormItem
                    name="email"
                    label={t('Email')}
                    rules={validations.email}
                >
                    <Input />
                </MUIFormItem>
            </Col>

            <Col md={12} xs={24}>
                <MUIFormItem
                    name="gender"
                    label={t('Gender')}
                    rules={validations.gender}
                >
                    <GenderSelect placeholder={false} />
                </MUIFormItem>
            </Col>

            <Col md={12} xs={24}>
                <MUIFormItem
                    name="birth"
                    label={t('Birthdate')}
                    rules={validations.birth}
                >
                    <DatePicker
                        placeholder=""
                        suffixIcon={<CalendarIcon />}
                    />
                </MUIFormItem>
            </Col>

            {isCompany && (
                <Col>
                    <Checkbox
                        checked={checked}
                        onClick={() => setChecked(!checked)}
                    >
                        {t('Is a company')}
                    </Checkbox>
                </Col>
            )}
            {isCompany && (
                <div className="divider"><Divider dashed /></div>
            )}

            {checked && (
                <Row gutter={30} className="if-company-content">
                    <Col md={12} xs={24}>
                        <MUIFormItem
                            name="company_name"
                            label={t('Company Name')}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col md={12} xs={24}>
                        <MUIFormItem
                            name="vat_number"
                            label={t('Registration Number')}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col xs={24}>
                        <MUIFormItem
                            name="address"
                            label={t('Address')}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col md={12} xs={24}>
                        <MUIFormItem
                            name="company_phone"
                            label={t('Phone Number')}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>

                    <Col md={12} xs={24}>
                        <MUIFormItem
                            name="company_email"
                            label={t('Email')}
                        >
                            <Input />
                        </MUIFormItem>
                    </Col>
                </Row>
            )}
        </Row>
    );
};

export default AddCustomerForm;
