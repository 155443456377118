import { useTranslation } from 'react-i18next';
import { useCallback, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
    Row,
    Col,
    Typography,
    Divider,
    Space,
    Dropdown,
    Button,
} from 'antd';
import SimpleDropdownMenu from '@/components/SimpleDropdownMenu';
import TooltipInfo from '@/components/Tooltip';
import { selectors } from '@/redux/tour/activities/view/model';
import { actions } from '@/redux/tour/activities/delete/reducer';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import { selectors as shareSelectors } from '@/redux/b2b/share/model';
import {
    CalendarIcon,
    LocationIcon,
    MoreOutlinedIcon,
    TagsIcon,
} from '@/assets/images/icons/screens';
// import { useCurrency } from '@/hooks/useCurrency';
import useNotification from '@/hooks/useNotification';
import decodeHtml from '@/helpers/decodeHtml';
import TourPriceAndB2bSection from '@/components/TourPriceAndB2bSection';

const GeneralData = ({
    data,
    myService,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const dispatch = useDispatch();
    // const { icon: currency } = useCurrency();

    const partnerData = useSelector(selectors.partner);

    const editUrl = `/app/tours/activities/${data.id}/edit`;

    const onEdit = useCallback(() => {
        push(editUrl);
    }, [push, editUrl]);

    // Message from delete action
    const messageSuccess = useSelector((state) => state.tour.activities.delete.messageSuccess);
    const error = useSelector((state) => state.tour.activities.delete.error);

    useNotification({
        messageSuccess,
        error,
        itemToDelete: data.name,
        clearAction: actions.clearDeleteActivityState(),
        mode: 'view',
        pushUrl: '/app/tours/activities',
    });

    // Message from share/remove actions
    const message = useSelector(shareSelectors.message);
    const [greenButton, setGreenButton] = useState(data.share);

    useEffect(() => {
        if (message === 'Shared successfully') {
            setGreenButton(true);
        }
        if (message === 'Removed successfully') {
            setGreenButton(false);
            dispatch(shareActions.clearShareRemoveMessage());
        }
    }, [message, dispatch]);

    return (
        <>
            <Row
                className="activity-general-data"
                justify="space-between"
            >
                <Col>
                    <Space size={10}>
                        <LocationIcon />

                        <span>{data.address}</span>
                    </Space>
                </Col>

                <Col>
                    <Space size={11}>
                        <CalendarIcon />

                        <span>
                            {data.start_date}
                            {' - '}
                            {data.end_date}
                        </span>
                    </Space>
                </Col>

                {partnerData && partnerData.partner && (
                    <Col>
                        <Space size={14}>
                            <TagsIcon />
                            <TooltipInfo data={partnerData} />
                        </Space>
                    </Col>
                )}

                {/* Temporary */}
                {/* <Col className="price-section">
                    <div className="price">
                        {`${currency}${data.price} / ${data.price_type}`}
                    </div>
                </Col> */}

                <Col>
                    <Space className="price-section">
                        <TourPriceAndB2bSection
                            myService={myService}
                            data={data}
                            greenButton={greenButton}
                            type="activity"
                        />

                        {myService && (
                            <Dropdown
                                trigger={['click']}
                                placement="bottom"
                                overlayClassName={clsx(
                                    'edit-delete-dropdown',
                                    'edit-delete-dropdown-with-text',
                                )}
                                menu={{
                                    items: SimpleDropdownMenu({
                                        editText: t('Edit this activity'),
                                        deleteText: t('Delete this activity'),
                                        type: 'activity',
                                        onEditClick: onEdit,
                                        deleteAction: actions.requestDeleteActivity(data.id),
                                        removeSharedText: t('Remove this shared activity'),
                                        removeSharedServiceAction:
                                            shareActions.requestRemoveSharedService(
                                                data.id,
                                                { type: 1 },
                                            ),
                                        removeSharedCollectedText: t('Remove this shared collected activity'),
                                        removeSharedCollectedServiceAction:
                                            shareActions.requestRemoveSharedCollectedService(
                                                data.id,
                                                { type: 1 },
                                            ),
                                        isShared: greenButton,
                                    }),
                                }}
                            >
                                <Button type="text">
                                    <MoreOutlinedIcon />
                                </Button>
                            </Dropdown>
                        )}
                    </Space>
                </Col>
            </Row>

            <Divider />

            <Typography.Text className="activity-description">
                <div dangerouslySetInnerHTML={{ __html: decodeHtml(data.description) }} />
            </Typography.Text>

            <Divider />
        </>
    );
};

export default GeneralData;
