import { useTranslation } from 'react-i18next';
import { Space, Typography } from 'antd';
import LinkButton from '@/components/LinkButton';
import { RightIcon } from '@/assets/images/icons/screens';

const ButtonContent = ({
    isList,
    viewUrl,
    reservationUrl,
    price,
    priceType,
    totalStep,
    registerStep,
    type,
    currency,
}) => {
    const { t } = useTranslation();

    return (
        isList ? (
            <Space direction="vertical" size={8}>
                <div className="price">
                    <Typography.Text>
                        {t('from')}
                    </Typography.Text>
                    {' '}
                    <span>{`${currency}${price} / ${priceType}`}</span>
                </div>

                <LinkButton
                    className="reservation-button"
                    to={reservationUrl}
                    totalStep={totalStep}
                    registerStep={registerStep}
                    block
                    type={type}
                >
                    {t('Make reservation').toUpperCase()}
                    <RightIcon />
                </LinkButton>

                <LinkButton
                    className="view-grey-button"
                    to={viewUrl}
                    totalStep={totalStep}
                    registerStep={registerStep}
                    block
                    type={type}
                >
                    {t('More details').toUpperCase()}
                    <RightIcon />
                </LinkButton>
            </Space>
        ) : (
            <Space>
                <LinkButton
                    className="view-grey-button"
                    to={viewUrl}
                    totalStep={totalStep}
                    registerStep={registerStep}
                    block
                    type={type}
                >
                    {t('More details').toUpperCase()}
                    <RightIcon />
                </LinkButton>
                <LinkButton
                    className="reservation-button"
                    to={reservationUrl}
                    totalStep={totalStep}
                    registerStep={registerStep}
                    block
                    type={type}
                >
                    {t('Make reservation').toUpperCase()}
                    <RightIcon />
                </LinkButton>
            </Space>
        )
    );
};

export default ButtonContent;
