import {
    Avatar,
    Col,
    Rate,
    Row,
    Typography,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { CalendarIcon, UserIcon } from '@/assets/images/icons/screens';
import BlockDescription from '@/components/Information/WithImage/Block';
import SectionLayout from '../../SectionLayout';

const Accommodation = ({ reservationData }) => {
    const { t } = useTranslation();

    let totalAdults = 0;
    let totalChild = 0;
    const rooms = reservationData?.rooms || reservationData?.data;
    rooms?.forEach((room) => {
        totalAdults += room.adults;
        totalChild += room.children;
    });

    return reservationData !== undefined && (
        <SectionLayout
            className="accommodation-details"
            icon={(
                <Avatar
                    size={60}
                    src={reservationData.hotel?.image || reservationData.image}
                />
            )}
            leftCol={3}
            rightCol={21}
        >
            <Row>
                <Col xs={12}>
                    <Rate
                        count={5}
                        disabled
                        value={reservationData.hotel?.stars || reservationData.stars}
                    />
                    <Typography.Title level={5}>
                        { reservationData.hotel?.name || reservationData.name}
                    </Typography.Title>
                    <Typography.Text>
                        { reservationData.hotel?.location || reservationData.location}
                    </Typography.Text>
                </Col>

                <Col xs={12}>
                    <div>
                        <BlockDescription
                            spaceSize={20}
                            icon={<CalendarIcon />}
                            label={`${t('Check in - Check out')}`}
                            content={`${reservationData.check_in} - ${reservationData.check_out}`}
                        />
                    </div>
                    <div>
                        <BlockDescription
                            spaceSize={20}
                            icon={<UserIcon />}
                            label={t('Guests')}
                            content={`
                                ${totalChild === 0 ? `${totalAdults} ${t('Adults')}` : `${totalAdults} ${t('Adults')}`},
                                ${totalChild} ${t('Children')}
                            `} // to do
                        />
                    </div>
                </Col>
            </Row>
        </SectionLayout>
    );
};

export default Accommodation;
