import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import { selectors as shareSelectors } from '@/redux/b2b/share/model';
import {
    Col,
    Card,
    Avatar,
    message,
} from 'antd';
import clsx from 'clsx';
import Cover from './Cover';
import ListDescription from './Description/List';
import GridDescription from './Description/Grid';
import TitleContent from './TitleContent';

const AccommodationCard = ({
    id,
    name,
    address,
    rooms,
    facilities,
    avatar,
    cover,
    rate,
    price,
    priceType,
    displayType = 'list',
    viewUrl,
    reservationUrl,
    editUrl,
    deleteAction,
    type,
    totalStep,
    registerStep,
    isShared,
    agency,
    removeSharedServiceAction,
    removeSharedCollectedServiceAction,
    myService,
    currency,
    setItemName,
}) => {
    const dispatch = useDispatch();
    const isList = (displayType === 'list');

    const cardClass = clsx(
        'accommodation-card-style',
        isList ? 'list' : 'grid',
    );
    const reduxMessage = useSelector(shareSelectors.message);
    const serviceId = useSelector(shareSelectors.serviceId);

    const [isServiceShared, setIsServiceShared] = useState(isShared);

    useEffect(() => {
        if (reduxMessage === 'Removed successfully' && id === serviceId) {
            setIsServiceShared(false);
            message.success('Remove shared service successfully');
            dispatch(shareActions.clearShareRemoveMessage());
        }
    }, [reduxMessage, dispatch, id, serviceId]);

    return (
        <Col xs={isList ? 24 : 6} xl={isList ? 24 : 6} className={isList ? 'list-col' : 'grid-col'}>
            {isList ? (
                <Card className={cardClass}>
                    <Card.Meta
                        avatar={(
                            <Avatar
                                className="image"
                                shape="square"
                                src={avatar}
                            />
                        )}
                        description={(
                            <ListDescription
                                name={name}
                                address={address}
                                rooms={rooms}
                                facilities={facilities}
                                rate={rate}
                                price={price}
                                priceType={priceType}
                                isList={isList}
                                viewUrl={viewUrl}
                                reservationUrl={reservationUrl}
                                editUrl={editUrl}
                                deleteAction={deleteAction}
                                type={type}
                                totalStep={totalStep}
                                registerStep={registerStep}
                                isShared={isServiceShared}
                                agency={agency}
                                removeSharedServiceAction={removeSharedServiceAction}
                                removeSharedCollectedServiceAction={
                                    removeSharedCollectedServiceAction
                                }
                                myService={myService}
                                currency={currency}
                                setItemName={setItemName}
                            />
                        )}
                    />
                </Card>
            ) : (
                <Card
                    className={cardClass}
                    cover={(
                        <Cover
                            src={cover}
                            price={price}
                            priceType={priceType}
                            rooms={rooms}
                            editUrl={editUrl}
                            deleteAction={deleteAction}
                            name={name}
                            type={type}
                            isShared={isServiceShared}
                            agency={agency}
                            removeSharedServiceAction={removeSharedServiceAction}
                            removeSharedCollectedServiceAction={
                                removeSharedCollectedServiceAction
                            }
                            myService={myService}
                            currency={currency}
                            setItemName={setItemName}
                        />
                    )}
                >
                    <Card.Meta
                        title={(
                            <TitleContent
                                name={name}
                                viewUrl={viewUrl}
                                address={address}
                                rate={rate}
                                isList={isList}
                            />
                        )}
                        description={(
                            <GridDescription
                                facilities={facilities}
                                viewUrl={viewUrl}
                                reservationUrl={reservationUrl}
                                isList={isList}
                                totalStep={totalStep}
                                registerStep={registerStep}
                                type={type}
                            />
                        )}
                    />
                </Card>

            )}
        </Col>
    );
};

export default AccommodationCard;
