import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, Avatar, Badge } from 'antd';
import {
    WorldwideIcon,
    TickIcon,
} from '@/assets/images/icons/screens';
import { actions as collectActions } from '@/redux/b2b/supplier/collect/reducer';
import { selectors as collectSelectors } from '@/redux/b2b/supplier/collect/model';
import ShareProduct from '@/components/ShareProduct/ShareProduct';

const getMinPrice = (priceRules) => {
    let smallestPrice = Number.POSITIVE_INFINITY;

    priceRules?.forEach((rule) => {
        if (rule.price < smallestPrice) {
            smallestPrice = rule.price;
        }
    });
    return smallestPrice;
};

const getMaxPrice = (priceRules) => {
    let largestPrice = Number.NEGATIVE_INFINITY;

    priceRules?.forEach((rule) => {
        if (rule.price > largestPrice) {
            largestPrice = rule.price;
        }
    });
    return largestPrice;
};
const TourPriceAndB2bSection = ({
    myService,
    data,
    greenButton,
    type,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [visible, setVisible] = useState(false);

    // Message from request action (send request to service owner)
    const requestMessage = useSelector(collectSelectors.message);

    const [pendingStatus, setPendingStatus] = useState(false);

    useEffect(() => {
        if (data.status === '2') {
            setPendingStatus(true);
        }
        if (requestMessage === 'Success') {
            setPendingStatus(true);
            dispatch(collectActions.clearRequestSuccess());
        }
    }, [data.status, requestMessage, dispatch]);

    return (
        <div>
            {!myService && (
                <Badge
                    count={
                        data.status === '1' && (
                            <div className="svg-wrapper">
                                <TickIcon />
                            </div>
                        )
                    }
                >
                    <div className={clsx('agency-name',
                        pendingStatus && 'pending-color',
                        data.status === '1' && 'collected-color',
                        data.status === null && 'base-color')}
                    >
                        <Avatar size={30} src={data.agency_logo} />
                        <Link to={`/app/b2b-partners/agency-profile/${data.b2b_agency_id}`}>
                            {data.b2b_agency_name}
                        </Link>
                    </div>
                </Badge>
            )}
            <div className="price-description">
                {t('Avg. Price')}
            </div>

            {type !== 'activity' ? (
                <div className="price">
                    {`
                        ${data.currency_icon}
                        ${data.price_rules && data.price_rules.length !== 0 ? `${getMinPrice(data.price_rules)} - ${getMaxPrice(data.price_rules)}` : data.adult_price}
                        / ${data.adult_price_type}
                    `}
                </div>
            ) : (
                <div className="price">
                    {`
                        ${data.currency_icon}
                        ${data.price}
                        / ${data.price_type}
                    `}
                </div>
            )}
            {myService && (
                <Button
                    className={clsx(greenButton && 'shared-success')}
                    onClick={() => setVisible(true)}
                >
                    {greenButton ? (
                        <span className="btn-text">
                            <span className="svg-wrapper">
                                <TickIcon />
                            </span>
                            {`${t('Shared')} / ${t('Edit Details')}`}
                        </span>
                    ) : (
                        <span className="btn-text">
                            <WorldwideIcon />
                            {t('Share to Worldwide B2B')}
                        </span>
                    )}
                </Button>
            )}
            <ShareProduct
                visible={visible}
                setVisible={setVisible}
                serviceName={data.name}
                serviceType="tour"
                serviceId={data.id}
                isShared={greenButton}
            />
            {!myService && (
                <div className="earning">
                    <span>
                        {data.earning}
                        {data.commission_type === 'Percentage' ? '%' : data.currency_icon}
                    </span>
                    <span>{t('your earning')}</span>
                </div>
            )}
        </div>
    );
};

export default TourPriceAndB2bSection;
