import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { Divider, Form } from 'antd';

import FindOrCreateResource from '@/components/FindOrCreateResource';
import AddCustomerForm from '@/components/AddCustomerForm';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { dateFormat } from '@/helpers/dateFormat';
import { actions } from '@/redux/invoice/create/reducer';
import { selectors } from '@/redux/invoice/create/model';

const formFields = [
    'name',
    'surname',
    'id_number',
    'nationality',
    'phone',
    'email',
    'gender',
    'birth',
    'company_name',
    'vat_number',
    'company_phone',
    'company_email',
    'address',
];

const Details = ({ form, invoiceId }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [vendorId, setVendorId] = useState(null);

    const reservationData = useSelector(selectors.reservationData);
    const customer = useSelector(selectors.invoiceCustomer);
    const errors = useSelector(selectors.errors);

    // in case of edit invoice
    const invoiceDetails = useSelector(selectors.invoiceDetails);
    const {
        tour_cruise_general_data: cruiseDetails,
        hotel_general_data: hotelDetails,
        tour_package_general_data: packageDetails,
    } = invoiceDetails !== null && invoiceDetails;

    let reservationDataToSubmit = null;
    if (reservationData) {
        if (reservationData?.reservation_type === 'Tour Trip') {
            reservationDataToSubmit = {
                data: reservationData.data,
                tour_id: reservationData.tour_id,
                reservation_type: reservationData.reservation_type,
            };
        } else if (reservationData?.reservation_type === 'Activity') {
            reservationDataToSubmit = {
                date: reservationData.date,
                days: reservationData.days,
                tour_id: reservationData.tour_id,
                reservation_type: reservationData.reservation_type,
            };
        } else if (reservationData?.reservation_type === 'Hotel') {
            reservationDataToSubmit = {
                check_in: reservationData.check_in,
                check_out: reservationData.check_out,
                data: reservationData.data,
                tour_id: reservationData.tour_id,
                reservation_type: reservationData.reservation_type,
            };
        } else {
            reservationDataToSubmit = {
                date_start: reservationData?.date_start,
                date_end: reservationData?.date_end,
                data: reservationData?.data,
                tour_id: reservationData?.tour_id,
                reservation_type: reservationData?.reservation_type,
            };
        }
    } else {
        if (cruiseDetails) {
            reservationDataToSubmit = {
                date_start: cruiseDetails?.date_start,
                date_end: cruiseDetails?.date_end,
            };
        } if (hotelDetails) {
            reservationDataToSubmit = {
                check_in: hotelDetails?.check_in,
                check_out: hotelDetails?.check_out,
            };
        } else {
            reservationDataToSubmit = {
                date_start: packageDetails?.date_start,
                date_end: packageDetails?.date_end,
            };
        }
    }

    const onFinish = useCallback((values) => {
        const data = {
            invoice_id: invoiceId,
            ...reservationDataToSubmit,
            invoice: {
                vendor_id: vendorId,
                customer: {
                    id: customer?.customer_id ? customer?.customer_id : null,
                    name: values.name,
                    surname: values.surname,
                    id_number: values.id_number,
                    nationality: values.nationality?.value,
                    phone: values.phone,
                    email: values.email,
                    gender: values.gender,
                    birth: values.birth?.format(dateFormat),
                },
                company: {
                    company_name: values.company_name,
                    vat_number: values.vat_number,
                    address: values.address,
                    company_phone: values.company_phone,
                    company_email: values.company_email,
                },
            },
        };
        dispatch(actions.requestSubmitInvoiceDetails(data));
    }, [dispatch, invoiceId, reservationDataToSubmit, customer, vendorId]);

    useApiValidationsForForm({ form, errors, formFields });

    useEffect(() => {
        let initialData = {};
        if (customer) {
            initialData = {
                name: customer.name,
                surname: customer.surname,
                id_number: customer.id_number,
                nationality: { value: customer.country_id || customer.nationality_id },
                phone: customer.phone,
                email: customer.email,
                gender: customer.gender,
                birth: moment(customer.birth),

                // is a company
                company_name: customer.company_name,
                vat_number: customer.vat_number,
                address: customer.address,
                company_phone: customer.company_phone,
                company_email: customer.company_email,

            };
        } form.setFieldsValue(initialData);
    }, [customer, form]);
    return (
        <Form
            form={form}
            className="details"
            onFinish={onFinish}
            requiredMark={false}
        >
            <FindOrCreateResource
                customerSearchText={t('Search for an Existing Customer')}
                vendorSearchText={t('Search for an Existing Vendor')}
                createForm={<AddCustomerForm />}
                seperator={<Divider />}
                setVendorId={setVendorId}
            />
        </Form>
    );
};

export default Details;
