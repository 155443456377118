import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
    Form,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import IncrementNumberInput from '@/components/IncrementNumberInput';
import InputSelect from '@/components/InputSelect';
import { selectors } from '@/redux/accommodation/hotel/create/model';

const PersonAndBed = () => {
    const { t } = useTranslation();

    const room = useSelector(selectors.roomDetails);

    const [adultNumber, setAdultNumber] = useState(0);
    const [childNumber, setChildNumber] = useState(0);

    return (
        <Row gutter={[28, 0]}>
            <Col xs={24} lg={12}>
                <Row>
                    <Col span={24}>
                        <Form.Item name="adults">
                            <IncrementNumberInput
                                onChange={setAdultNumber}
                                label={` ${t('Adult')}`}
                                max={10}
                                defaultCount={room?.adults || adultNumber}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="input-select-wrapper">
                            <div className="input-select-children">
                                <MUIFormItem
                                    label={t('Bed Type for adult')}
                                    name="bed_name_adult"
                                    // validateFirst
                                    // hasFeedback
                                    // rules={validations.bedNameAdults}
                                >
                                    <Input disabled={adultNumber === 0} />
                                </MUIFormItem>
                            </div>
                            <div>
                                <InputSelect
                                    posibleValue={adultNumber}
                                    isDisabled={adultNumber === 0}
                                    name="adult_bed_no"
                                    // rule={validations.adults}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={24} lg={12}>
                <Row>
                    <Col span={24}>
                        <Form.Item name="children">
                            <IncrementNumberInput
                                onChange={setChildNumber}
                                label={` ${t('Child')}`}
                                max={10}
                                defaultCount={room?.children || childNumber}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <div className="input-select-wrapper">
                            <div className="input-select-children">
                                <MUIFormItem
                                    label={t('Bed Type for child')}
                                    name="bed_name_children"
                                    // validateFirst
                                    // hasFeedback
                                    // rules={validations.bedNameChildren}
                                >
                                    <Input disabled={childNumber === 0} />
                                </MUIFormItem>
                            </div>
                            <div>
                                <InputSelect
                                    posibleValue={childNumber}
                                    isDisabled={childNumber === 0}
                                    name="children_bed_no"
                                    // rule={validations.children}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};

export default PersonAndBed;
