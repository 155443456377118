import {
    all,
    fork,
    takeEvery,
    call,
    put,
} from 'redux-saga/effects';
import AgencyProfileService from '@/services/B2BPartnerServices/AgencyProfileService';
import { extractError } from '@/helpers/api';
import { types, actions } from './reducer';

function* viewAgencyDetails({ agencyId }) {
    try {
        const response = yield call(AgencyProfileService.agencyProfile, agencyId);
        yield put(actions.viewAgencyDetailsSucceeded(response.data));
    } catch (error) {
        yield put(actions.viewAgencyDetailsFailed(extractError(error)));
    }
}

function* getAgencyServices({ agencyId, filters, isFetchingMore }) {
    if (isFetchingMore) {
        yield put(actions.getMoreAgencyServices());
    }
    try {
        const response = yield call(AgencyProfileService.agencyServices, agencyId, filters);
        const { shared_service: sharedServices } = response.data;
        if (isFetchingMore) {
            yield put(
                actions.getMoreAgencyServicesSucceeded(sharedServices),
            );
            return;
        }
        yield put(actions.getAgencyServicesSucceeded(sharedServices));
    } catch (error) {
        if (isFetchingMore) {
            yield put(actions.getMoreAgencyServicesFailed(extractError(error)));
            return;
        }
        yield put(actions.getAgencyServicesFailed(extractError(error)));
    }
}

function* watchViewAgencyDetails() {
    yield takeEvery(
        types.REQUEST_VIEW_AGENCY_DETAILS,
        viewAgencyDetails,
    );
}

function* watchGetAgencyServices() {
    yield takeEvery(
        types.REQUEST_GET_AGENCY_SERVICES,
        getAgencyServices,
    );
}

export default function* viewSaga() {
    yield all([
        fork(watchViewAgencyDetails),
        fork(watchGetAgencyServices),
    ]);
}
