import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import Price from '../../../Price';

const RoomDetails = ({ currency, data }) => {
    const { t } = useTranslation();

    const options = useMemo(() => ([
        { label: t('%'), value: 4 },
        { label: t(currency), value: 5 },
    ]), [t]);

    return (
        <>
            <Row gutter={[28, 0]}>
                <Col span={12}>
                    <MUIFormItem
                        label={t('Room Type Name')}
                        name="type_name"
                        // validateFirst
                        // hasFeedback
                        // rules={validations.typeName}
                    >
                        <Input />
                    </MUIFormItem>
                </Col>
                <Col span={6}>
                    <MUIFormItem
                        label={t('Room Size')}
                        name="room_size"
                    >
                        <Input suffix="m2" />
                    </MUIFormItem>
                </Col>
                <Col span={6}>
                    <MUIFormItem
                        label={t('Number of Rooms')}
                        name="no_rooms"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>
                <Price
                    currency={currency}
                    options={options}
                    priceLabel={data?.charges_type?.name === 'Per Person' ? 'Price per Person' : 'Price per Night'}
                    priceName="price"
                    commissionLabel="My Commission"
                    commissionName="commission"
                    commissionTypeName="commission_type"
                />
                <Price
                    currency={currency}
                    options={options}
                    priceLabel={data?.charges_type?.name === 'Per Person' ? 'Price per Person BB' : 'Price per Night BB'}
                    priceName="price_bb"
                    commissionLabel="My Commission"
                    commissionName="commission_bb"
                    commissionTypeName="commission_type_bb"
                />
                <Price
                    currency={currency}
                    options={options}
                    priceLabel={data?.charges_type?.name === 'Per Person' ? 'Price per Person HB' : 'Price per Night HB'}
                    priceName="price_bh"
                    commissionLabel="My Commission"
                    commissionName="commission_bh"
                    commissionTypeName="commission_type_bh"
                />
                <Price
                    currency={currency}
                    options={options}
                    priceLabel={data?.charges_type?.name === 'Per Person' ? 'Price per Person AI' : 'Price per Night AI'}
                    priceName="price_ai"
                    commissionLabel="My Commission"
                    commissionName="commission_ai"
                    commissionTypeName="commission_type_ai"
                />
            </Row>
        </>
    );
};

export default RoomDetails;
