import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    Input,
    Typography,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import GoogleMap from '@/components/GoogleMap';

const Location = ({
    lat,
    lng,
    address,
    form,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Typography.Text>
                {t('Add location to the map (Optional)')}
            </Typography.Text>
            <Row gutter={25} className="row-typography">
                <Col lg={12} xs={24}>
                    <MUIFormItem
                        label={t('Map Latitude')}
                        name="latitude"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>

                <Col lg={12} xs={24}>
                    <MUIFormItem
                        label={t('Map Longitude')}
                        name="longitude"
                    >
                        <Input />
                    </MUIFormItem>
                </Col>
            </Row>

            <Form.Item>
                <GoogleMap
                    latitude={lat}
                    longitude={lng}
                    address={address}
                    form={form}
                    zoom={8}
                />
            </Form.Item>
        </>
    );
};

export default Location;
