import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Row,
    Col,
    Typography,
    Button,
    Dropdown,
    Divider,
    Space,
    Avatar,
} from 'antd';

import SimpleDropdownMenu from '@/components/SimpleDropdownMenu';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import { actions as collectActions } from '@/redux/b2b/supplier/collect/reducer';
import { selectors as shareSelectors } from '@/redux/b2b/share/model';
import { selectors as collectSelectors } from '@/redux/b2b/supplier/collect/model';
import {
    BedIcon,
    MoreOutlinedIcon,
    PlusIcon,
    WorldwideIcon,
    TickIcon,
} from '@/assets/images/icons/screens';
import { selectors } from '@/redux/accommodation/hotel/view/model';
import ShareProduct from '@/components/ShareProduct/ShareProduct';
import useNotification from '@/hooks/useNotification';
import decodeHtml from '@/helpers/decodeHtml';

import Offers from './Offers';
import AddNewOffer from './AddNewOffer';

const GeneralData = ({
    generalDetails,
    roomList,
    priceData,
    myService,
}) => {
    const { t } = useTranslation();
    const { type } = useParams();
    const { push } = useHistory();
    const dispatch = useDispatch();

    const [visibleOffers, setVisibleOffers] = useState(false);
    const [visibleShareForm, setVisibleShareForm] = useState(false);

    const editUrl = `/app/accommodations/${type}/${generalDetails.id}/edit`;

    const onEdit = useCallback(() => {
        push(editUrl);
    }, [push, editUrl]);

    const offers = useSelector(selectors.offerList);
    const messageSuccess = useSelector((state) => state.accommodation.hotel.delete.messageSuccess);
    const error = useSelector((state) => state.accommodation.hotel.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: generalDetails.name,
        clearAction: actions.clearDeleteAccommodationState(),
        mode: 'view',
        pushUrl: `/app/accommodations/${type}`,
    });

    // Message from share/remove actions
    const message = useSelector(shareSelectors.message);
    const [greenButton, setGreenButton] = useState(generalDetails.share);

    useEffect(() => {
        if (message === 'Shared successfully') {
            setGreenButton(true);
        }
        if (message === 'Removed successfully') {
            setGreenButton(false);
            dispatch(shareActions.clearShareRemoveMessage());
        }
    }, [message, dispatch]);

    // Message from request action (send request to service owner)
    const requestMessage = useSelector(collectSelectors.message);

    const [pendingStatus, setPendingStatus] = useState(false);

    useEffect(() => {
        if (generalDetails.status === '2') {
            setPendingStatus(true);
        }
        if (requestMessage === 'Success') {
            setPendingStatus(true);
            dispatch(collectActions.clearRequestSuccess());
        }
    }, [generalDetails.status, requestMessage, dispatch]);

    return (
        <div>
            <Row justify="space-between" align="middle">
                <Col className="accommodation-general_data">
                    <div className="icon_bg">
                        <BedIcon />
                    </div>
                    {' '}
                    <Typography.Text>
                        {roomList.length === 1 ? `${roomList.length} ${t('Room')}` : `${roomList.length} ${t('Rooms')}`}
                    </Typography.Text>
                </Col>
                <Col>
                    <Space className="offer-section">
                        <div className="price_data">
                            <div>
                                <Typography.Text>
                                    {`${t('from')} ${generalDetails.currency_icon}${priceData.from} / ${t(priceData.price_type)}`}
                                </Typography.Text>
                            </div>

                            {myService && (
                                <Button
                                    type="text"
                                    onClick={() => setVisibleOffers(true)}
                                >
                                    {`${offers.length} ${t('Active Offers')}`}
                                </Button>
                            )}

                            <Offers
                                offers={offers}
                                visible={visibleOffers}
                                setVisible={setVisibleOffers}
                            />
                        </div>

                        {myService && (
                            <div>
                                <Button
                                    className="add-button-outlined"
                                    onClick={() => dispatch(actions.setFormVisibleTrue())}
                                >
                                    <PlusIcon />
                                    {t('Add new Offer')}
                                </Button>

                                <AddNewOffer currency={generalDetails.currency_icon} />
                            </div>
                        )}

                        <div>
                            {myService && (
                                <Button
                                    className={clsx(
                                        'share-button',
                                        greenButton && 'shared-success',
                                    )}
                                    onClick={() => setVisibleShareForm(true)}
                                >
                                    {greenButton ? (
                                        <span className="btn-text">
                                            <span className="svg-wrapper">
                                                <TickIcon />
                                            </span>
                                            {`${t('Shared')} / ${t('Edit Details')}`}
                                        </span>
                                    ) : (
                                        <span className="btn-text">
                                            <WorldwideIcon />
                                            {t('Share to Worldwide B2B')}
                                        </span>
                                    )}
                                </Button>
                            )}
                            <ShareProduct
                                visible={visibleShareForm}
                                setVisible={setVisibleShareForm}
                                serviceName={generalDetails.name}
                                serviceType="accommodation"
                                serviceId={generalDetails.id}
                                isShared={greenButton}
                            />
                        </div>

                        {!myService && (
                            <>
                                <div className={clsx('agency-name',
                                    pendingStatus && 'pending-color',
                                    generalDetails.status === '1' && 'collected-color',
                                    generalDetails.status === null && 'base-color')}
                                >
                                    <Avatar size={30} src={generalDetails.agency_logo} />
                                    <Link to={`/app/b2b-partners/agency-profile/${generalDetails.b2b_agency_id}`}>
                                        {generalDetails.b2b_agency}
                                    </Link>
                                    {generalDetails.status === '1' && (
                                        <div className="svg-wrapper">
                                            <TickIcon />
                                        </div>
                                    )}
                                </div>

                                <div className="earning">
                                    <span>
                                        {generalDetails.earning}
                                        {generalDetails.commission_type === 'Percentage' ? '%' : generalDetails.currency_icon}
                                    </span>
                                    <span>{t('your earning')}</span>
                                </div>
                            </>
                        )}

                        {myService && (
                            <Dropdown
                                trigger={['click']}
                                placement="bottom"
                                overlayClassName={clsx(
                                    'edit-delete-dropdown',
                                    'edit-delete-dropdown-with-text',
                                )}
                                menu={{
                                    items: SimpleDropdownMenu({
                                        editText: t(`Edit this ${type}`),
                                        deleteText: t(`Delete this ${type}`),
                                        onEditClick: onEdit,
                                        deleteAction: actions.requestDeleteAccommodation(
                                            generalDetails.id,
                                            type,
                                        ),
                                        type,
                                        removeSharedText: t(`Remove this shared ${type}`),
                                        removeSharedServiceAction:
                                            shareActions.requestRemoveSharedService(
                                                generalDetails.id,
                                                { type: 2 },
                                            ),
                                        removeSharedCollectedText: t(`Remove this shared collected ${type}`),
                                        removeSharedCollectedServiceAction:
                                            shareActions.requestRemoveSharedCollectedService(
                                                generalDetails.id,
                                                { type: 2 },
                                            ),
                                        isShared: greenButton,
                                    }),
                                }}
                            >
                                <Button type="text">
                                    <MoreOutlinedIcon />
                                </Button>
                            </Dropdown>
                        )}
                    </Space>
                </Col>
            </Row>

            <Divider />

            <Typography.Text>
                <div dangerouslySetInnerHTML={{ __html: decodeHtml(generalDetails.description) }} />
            </Typography.Text>
        </div>
    );
};

export default GeneralData;
