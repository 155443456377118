import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Divider,
    Space,
    Checkbox,
    Typography,
} from 'antd';

import BlockDescription from '@/components/Information/WithImage/Block';
import IncrementNumberInput from '@/components/IncrementNumberInput';
import {
    AdultIcon,
    ChildIcon,
    InfantIcon,
    InfoCircleIcon,
} from '@/assets/images/icons/screens';
import SelectCabin from './SelectCabin';

const CabinDetails = ({
    roomListSelected,
    setRoomListSelected,
    startDate,
    endDate,
    item,
    type,
    tourId,
}) => {
    const { t } = useTranslation();

    const [checkedRoom, setCheckedRoom] = useState(false);

    // data to submit
    const [noRooms, setNoRooms] = useState(0);
    const [selectedExtraServices, setSelectedExtraServices] = useState([]);

    return (
        <Row className="room">
            <Col lg={4}>
                <BlockDescription
                    icon={(
                        <AdultIcon />
                    )}
                    label={`${t('Max. Adults')} ${item.adults}`}
                    content={`${item.currency_icon}${item.price} / ${t('adult')}`}
                    spaceSize={14}
                />
                <BlockDescription
                    icon={(
                        <ChildIcon />
                    )}
                    label={`${t('Max. Children')} ${item.children}`}
                    content={`${item.currency_icon}${item.child_charges} / ${t('child')}`}
                    spaceSize={14}
                />
                <BlockDescription
                    icon={(
                        <InfantIcon />
                    )}
                    label={`${t('Max. Infants')} ${item.infants}`}
                    content={`${item.currency_icon}${item.infant_charges} / ${t('infant')}`}
                    spaceSize={14}
                />
            </Col>

            <Col lg={9}>
                <Row>
                    {item.amenities?.map((amenity) => (
                        <Col lg={8} key={amenity.id}>
                            <li className="inclusion">{t(amenity.name)}</li>
                        </Col>
                    ))}
                </Row>
            </Col>

            <Col lg={6}>
                <span className="title-extra-services">{t('Extra Services')}</span>
                <Space direction="vertical" className="extra-services">
                    {item.extra_services.map((service) => (
                        <Checkbox
                            key={service.id}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setSelectedExtraServices(
                                        (prev) => [...prev, service.id],
                                    );
                                } else {
                                    setSelectedExtraServices(
                                        (prev) => prev.filter((id) => id !== service.id),
                                    );
                                }
                            }}
                        >
                            {service.name}
                        </Checkbox>
                    ))}
                </Space>
            </Col>

            <Col lg={1} className="reservation-list-divider">
                <Divider type="vertical" />
            </Col>

            <Col lg={4}>
                <Row gutter={[0, 10]} className="reservation-list_right-col">
                    <Col lg={24}>
                        {item.number_of_cabins !== '0' ? (
                            <div className="no-rooms">
                                <Space size={20}>
                                    <span>{t('Available Cabins')}</span>
                                    <IncrementNumberInput
                                        max={item.number_of_cabins}
                                        hasInputBorder={false}
                                        onChange={setNoRooms}
                                        defaultCount={noRooms}
                                        disableButton={checkedRoom}
                                    />
                                </Space>
                            </div>
                        ) : (
                            <div className="no-rooms">
                                <Typography.Text>
                                    {t('No available cabins')}
                                </Typography.Text>
                            </div>
                        )}
                    </Col>
                    {item.number_of_cabins !== '0' && (
                        <Col lg={24}>
                            <SelectCabin
                                roomListSelected={roomListSelected}
                                setRoomListSelected={setRoomListSelected}
                                startDate={startDate}
                                endDate={endDate}
                                checkedRoom={checkedRoom}
                                setCheckedRoom={setCheckedRoom}
                                roomId={item.id}
                                roomName={item.type_name}
                                roomPrice={item.price}
                                noRooms={noRooms}
                                adultNumber={noRooms * item.adults}
                                childNumber={noRooms * item.children}
                                infantNumber={noRooms * item.infants}
                                selectedExtraServices={selectedExtraServices}
                                type={type}
                                tourId={tourId}
                                cabinImg={item.images?.[0]?.path}
                                currency={item.currency_icon}
                            />
                        </Col>
                    )}
                    {item.number_of_cabins !== '0' && (startDate === undefined || endDate === undefined || noRooms === 0) && (
                        <Col lg={24} className="reservation-info">
                            <Space>
                                <InfoCircleIcon />
                                <span>{t('Choose cabin and start/end date before reservation')}</span>
                            </Space>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default CabinDetails;
