import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import {
    Row,
    Col,
    Form,
    Input,
    Button,
    Space,
    Select,
    Slider,
    Divider,
    Typography,
} from 'antd';
import BlockDescription from '@/components/Information/WithoutImage/Block';
import { firstToUpper } from '@/helpers/string';
import useFacilities from '@/hooks/useFacilities';
import { useCurrency } from '@/hooks/useCurrency';
import {
    AppsIcon,
    FilterIcon,
    ListIcon,
    LocationIcon,
    PlusIcon,
    SortIcon,
} from '@/assets/images/icons/screens';
import { actions } from '@/redux/maxprice/reducer';

const options = new Array(5).fill(null).map((_, i) => (
    <Select.Option
        key={(i).toString()}
        value={i + 1}
    >
        {i + 1}
    </Select.Option>
));

const Filters = ({
    viewMode,
    setViewMode,
    filters,
    setFilters,
}) => {
    const { t } = useTranslation();
    const { type } = useParams();
    const dispatch = useDispatch();
    const formattedType = firstToUpper(type);
    const { data, isValidating } = useFacilities();
    const { icon: currencyIcon } = useCurrency();

    const addAccommodationUrl = `/app/accommodations/${type}/add/`;
    const [isOpen, setIsOpen] = useState(false);

    const maxPrice = useSelector((state) => state.maxprice.accommodationMaxPrice);

    useEffect(() => {
        dispatch(actions.requestGetMaxPrice({ type: 2 }));
    }, []);

    if (filters.prices[1] === 0) {
        return null;
    }

    return (
        <Form
            initialValues={filters}
            onFieldsChange={([changedField]) => {
                const { name, value } = changedField;
                if (name[0] !== 'prices') {
                    setFilters(name[0], value);
                }
            }}
        >
            <Row
                className="filter-content"
                justify="space-between"
            >
                <Col>
                    <BlockDescription
                        className="bigger-text-size"
                        name={t(type).concat('', 's').toUpperCase()}
                    >
                        <Typography.Text>
                            {t('Accomodation')}
                        </Typography.Text>
                    </BlockDescription>
                </Col>

                <Col>
                    <Space size={30}>
                        <Form.Item name="name" noStyle>
                            <Input.Search
                                className="search"
                                placeholder={t('Search')}
                            />
                        </Form.Item>

                        <Button
                            className="button filter-button"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <FilterIcon />
                            {t('Filter').toUpperCase()}
                        </Button>

                        <Form.Item name="sort" noStyle>
                            <Select
                                placeholder={t('Sort by')}
                                suffixIcon={<SortIcon />}
                                allowClear
                            >
                                <Select.Option value="stars">stars</Select.Option>
                            </Select>
                        </Form.Item>

                        <Button
                            className="change-mode-button"
                            onClick={() => {
                                setViewMode((viewMode === 'list') ? 'grid' : 'list');
                            }}
                        >
                            {(viewMode === 'list')
                                ? (
                                    <AppsIcon />
                                ) : (
                                    <ListIcon />
                                )}
                        </Button>

                        <Link to={addAccommodationUrl}>
                            <Button className="button add-button">
                                <PlusIcon />

                                {t(`Add new ${formattedType}`)}
                            </Button>
                        </Link>
                    </Space>
                </Col>
            </Row>

            {isOpen && (
                <>
                    <Divider dashed className="filter-divider" />

                    <Row
                        className="advanced-filter-content"
                        gutter={30}
                    >
                        <Col lg={5}>
                            <Form.Item name="location">
                                <Input
                                    placeholder={t('Location')}
                                    suffix={<LocationIcon />}
                                />
                            </Form.Item>
                        </Col>

                        <Col lg={5}>
                            <Form.Item name="rate" noStyle>
                                <Select
                                    className="select-option"
                                    placeholder={t(`${formattedType} Rates`)}
                                    mode="multiple"
                                >
                                    {options}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={5}>
                            <Form.Item name="facilities" noStyle>
                                <Select
                                    className="select-option"
                                    mode="multiple"
                                    loading={isValidating}
                                    placeholder={t(`${formattedType} Facilities & Services`)}
                                >
                                    {data.data.map((facility) => (
                                        <Select.Option
                                            key={facility.id}
                                            value={facility.id}
                                        >
                                            {facility.name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col lg={9}>
                            <div>
                                <span className="display-price">
                                    {currencyIcon}
                                    {filters.prices[0]}
                                    {' - '}
                                    {currencyIcon}
                                    {filters.prices[1]}
                                    {'  '}
                                    (
                                    {t('night')}
                                    /
                                    {t('person')}
                                    )
                                </span>
                            </div>
                            <div>
                                <Form.Item name="prices" noStyle>
                                    <Slider
                                        className="trip-slider"
                                        range
                                        min={0}
                                        max={maxPrice}
                                        step={5}
                                        tooltip={{
                                            open: false,
                                        }}
                                        onAfterChange={(value) => {
                                            setFilters('prices', value);
                                        }}
                                    />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </>
            )}
        </Form>
    );
};

export default Filters;
