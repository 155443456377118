import { createActions } from 'reduxsauce';

export const { Types: types, Creators: actions } = createActions({

    // go to step
    goToCreateInvoiceStep: ['step'],

    // prepare reservation data for submit
    prepareReservationDataForSubmit: ['data'],

    // get reservation data before creating an invoice
    requestGetReservationData: ['id', 'data'],
    getReservationDataSucceeded: ['registeredData'],
    getReservationDataFailed: ['errors'],

    // fetch for edit
    requestFetchInvoiceData: ['id'],
    fetchInvoiceDataSucceeded: [
        'generalData',
        'firstStep',
        'secondStep',
        'thirdStep',
    ],
    fetchInvoiceDataFailed: ['errors'],

    // submit first step
    requestSubmitInvoiceDetails: ['data'],
    submitInvoiceDetailsSucceeded: [
        'generalData',
        'firstStep',
    ],
    submitInvoiceDetailsFailed: ['errors'],

    // submit second step
    requestSubmitInvoiceParticipants: ['data'],
    submitInvoiceParticipantsSucceeded: ['data'],
    submitInvoiceParticipantsFailed: ['errors'],

    // submit third step
    requestSubmitInvoiceExtras: ['data'],
    submitInvoiceExtrasSucceeded: ['data'],
    submitInvoiceExtrasFailed: ['errors'],

    // customer list
    requestGetCustomerList: ['filters'],
    getCustomerListSucceeded: ['data'],
    getCustomerListFailed: ['errors'],

    // vendor list
    requestGetVendorList: ['filters'],
    getVendorListSucceeded: ['data'],
    getVendorListFailed: ['errors'],

    // get customer details
    requestGetExistingCustomer: ['id'],
    getExistingCustomerSucceeded: ['data'],
    getExistingCustomerFailed: ['errors'],

    // get extra services
    requestGetExtraServices: ['tourId', 'tourType'],
    getExtraServicesSucceeded: ['data'],
    getExtraServicesFailed: ['errors'],

    // clear state
    clearInvoiceState: null,
});

export default {

    // go to step
    [types.GO_TO_CREATE_INVOICE_STEP]: (state, { step }) => ({
        ...state,
        create: { ...state.create, step },
    }),

    // prepare reservation data for submit
    [types.PREPARE_RESERVATION_DATA_FOR_SUBMIT]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            reservationData: data,
        },
    }),

    // get reservation data before creating an invoice
    [types.REQUEST_GET_RESERVATION_DATA]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            errors: null,
        },
    }),
    [types.GET_RESERVATION_DATA_SUCCEEDED]: (state, { registeredData }) => ({
        ...state,
        create: {
            ...state.create,
            reservationData: {
                ...state.create.reservationData,
                ...registeredData,
            },
            loading: false,
        },
    }),
    [types.GET_RESERVATION_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
            loading: false,
        },
    }),

    // fetch for edit
    [types.REQUEST_FETCH_INVOICE_DATA]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.FETCH_INVOICE_DATA_SUCCEEDED]: (
        state,
        {
            generalData,
            firstStep,
            secondStep,
            thirdStep,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            invoiceDetails: generalData,
            invoiceCustomer: firstStep,
            invoiceParticipants: secondStep,
            invoiceExtras: thirdStep,
        },
    }),
    [types.FETCH_INVOICE_DATA_FAILED]: (state, { errors }) => ({
        ...state,
        create: { ...state.create, loading: false, errors },
    }),

    // submit first step
    [types.REQUEST_SUBMIT_INVOICE_DETAILS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_INVOICE_DETAILS_SUCCEEDED]: (
        state,
        {
            generalData,
            firstStep,
        },
    ) => ({
        ...state,
        create: {
            ...state.create,
            step: 1,
            completedStep: 0,
            submitting: false,
            invoiceDetails: generalData,
            invoiceCustomer: firstStep,
        },
    }),
    [types.SUBMIT_INVOICE_DETAILS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit second step
    [types.REQUEST_SUBMIT_INVOICE_PARTICIPANTS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_INVOICE_PARTICIPANTS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            step: 2,
            completedStep: 1,
            submitting: false,
            invoiceParticipants: data,
        },
    }),
    [types.SUBMIT_INVOICE_PARTICIPANTS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // submit third step
    [types.REQUEST_SUBMIT_INVOICE_EXTRAS]: (state) => ({
        ...state,
        create: {
            ...state.create,
            submitting: true,
            errors: null,
        },
    }),
    [types.SUBMIT_INVOICE_EXTRAS_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            step: 3,
            completedStep: 2,
            submitting: false,
            invoiceExtras: data,
        },
    }),
    [types.SUBMIT_INVOICE_EXTRAS_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            submitting: false,
            errors,
        },
    }),

    // customer list
    [types.REQUEST_GET_CUSTOMER_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            customerLoading: true,
            customerList: [],
            errors: null,
        },
    }),
    [types.GET_CUSTOMER_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            customerList: data,
            customerLoading: false,
        },
    }),
    [types.GET_CUSTOMER_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
            customerLoading: false,
        },
    }),

    // vendor list
    [types.REQUEST_GET_VENDOR_LIST]: (state) => ({
        ...state,
        create: {
            ...state.create,
            vendorLoading: true,
            vendorList: [],
            errors: null,
        },
    }),
    [types.GET_VENDOR_LIST_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            vendorList: data,
            vendorLoading: false,
        },
    }),
    [types.GET_VENDOR_LIST_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
            vendorLoading: false,
        },
    }),

    // get existing customer
    [types.REQUEST_GET_EXISTING_CUSTOMER]: (state) => ({
        ...state,
        create: { ...state.create, loading: true },
    }),
    [types.GET_EXISTING_CUSTOMER_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            invoiceCustomer: data,
        },
    }),
    [types.GET_EXISTING_CUSTOMER_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            loading: false,
            errors,
        },
    }),

    // get extra services
    [types.REQUEST_GET_EXTRA_SERVICES]: (state) => ({
        ...state,
        create: {
            ...state.create,
            loading: true,
            extraServices: [],
            errors: null,
        },
    }),
    [types.GET_EXTRA_SERVICES_SUCCEEDED]: (state, { data }) => ({
        ...state,
        create: {
            ...state.create,
            extraServices: data,
            loading: false,
        },
    }),
    [types.GET_EXTRA_SERVICES_FAILED]: (state, { errors }) => ({
        ...state,
        create: {
            ...state.create,
            errors,
            loading: false,
        },
    }),

    // clear state
    [types.CLEAR_INVOICE_STATE]: (state) => ({
        ...state,
        create: {
            ...state.create,
            step: 0,
            completedStep: null,
            reservationData: null,
            invoiceDetails: null,
            invoiceCustomer: null,
            invoiceParticipants: [],
            invoiceExtras: null,
            customerDetails: null,
        },
    }),
};
