import {
    Row,
    Col,
    Checkbox,
    Form,
} from 'antd';
import useWidgetsCategories from '@/hooks/useWidgetsCategories';

const WidgetCategory = () => {
    const { data, isValidating } = useWidgetsCategories();

    return (
        isValidating ? 'Loading...' : (
            <Form.Item name="widgets_category">
                <Checkbox.Group>
                    <Row gutter={[45, 20]}>
                        {data?.data.map((category) => (
                            <Col span={12} key={category.id}>
                                <Checkbox
                                    value={category.id}
                                >
                                    {category.name}
                                </Checkbox>
                            </Col>
                        ))}
                    </Row>
                </Checkbox.Group>
            </Form.Item>
        )
    );
};

export default WidgetCategory;
