import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
    Row,
    Col,
    Typography,
} from 'antd';
import AccommodationCard from '@/components/AccommodationCard';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { actions as shareActions } from '@/redux/b2b/share/reducer';
import { BedIcon, LocationIcon } from '@/assets/images/icons/screens';
import useNotification from '@/hooks/useNotification';

const AccommodationList = ({ data, viewMode, total }) => {
    const { t } = useTranslation();
    const { type } = useParams();

    const [itemName, setItemName] = useState('');

    const displayType = viewMode;
    const isList = (displayType === 'list');

    const messageSuccess = useSelector((state) => state.accommodation.hotel.delete.messageSuccess);
    const error = useSelector((state) => state.accommodation.hotel.delete.error);

    useNotification({
        messageSuccess,
        error,
        item: itemName,
        clearAction: actions.clearDeleteAccommodationState(),
        mode: 'list',
    });

    return (
        <Row gutter={isList ? [0, 32] : [30, 30]}>
            <Col xs={24}>
                <Typography.Text className="currently_text">
                    {`${t('Currently')}`}
                    {' '}
                    {total === 1 ? `${total} ${t(type)}` : `${total} ${t(type.concat('', 's'))}`}
                </Typography.Text>
            </Col>

            {data.map((d) => (
                <AccommodationCard
                    key={d.id}
                    name={d.name}
                    address={(
                        <div className="accommodation-list_address">
                            <LocationIcon />
                            {' '}
                            <span>{d.address}</span>
                        </div>
                    )}
                    rooms={(
                        <div className="accommodation-list_rooms">
                            <BedIcon />
                            {' '}
                            <span>{d.no_rooms === 1 ? `${d.no_rooms} ${t('Room')}` : `${d.no_rooms} ${t('Rooms')} `}</span>
                        </div>
                    )}
                    agency={(
                        <div className="accommodation-list_b2b_agency">
                            <span>
                                {d.b2b_agency !== null && ('Collected from: ')}
                                {d.b2b_agency}
                            </span>
                        </div>
                    )}
                    facilities={d.facilities}
                    avatar={d.image}
                    cover={d.image}
                    rate={d.stars}
                    price={d.price_from}
                    priceType={d.price_type}
                    displayType={displayType}
                    totalStep={4}
                    registerStep={d.register_hotel_step}
                    viewUrl={`/app/accommodations/${type}/${d.id}#tour-info`}
                    reservationUrl={`/app/accommodations/${type}/${d.id}#reservation`}
                    editUrl={`/app/accommodations/${type}/${d.id}/edit`}
                    deleteAction={actions.requestDeleteAccommodation(d.id, type)}
                    type={type}
                    removeSharedServiceAction={shareActions.requestRemoveSharedService(
                        d.id,
                        { type: 2 },
                    )}
                    removeSharedCollectedServiceAction={
                        shareActions.requestRemoveSharedCollectedService(
                            d.id,
                            { type: 2 },
                        )
                    }
                    isShared={d.share}
                    id={d.id}
                    myService={d.b2b_agency === null}
                    currency={d.currency_icon}
                    setItemName={setItemName}
                />
            ))}
        </Row>
    );
};

export default AccommodationList;
