export default {
    en: {
        translation: {
            from: 'from',
            night: 'night',
            'Make reservation': 'Make reservation',
            'More details': 'More details',
            more: 'more',
            'view less': 'view less',
            'First Name': 'First Name',
            'Last Name': 'Last Name',
            'ID Number': 'ID Number',
            Nationality: 'Nationality',
            'Phone Number': 'Phone Number',
            Email: 'Email',
            Gender: 'Gender',
            Birthdate: 'Birthdate',
            'Is a company': 'Is a company',
            'Company Name': 'Company Name',
            'Registration Number': 'Registration Number',
            Address: 'Address',
            'Service image': 'Service image',
            'your earning': 'your earning',
            collect: 'collect',
            collected: 'collected',
            'pending approval': 'pending approval',
            accept: 'accept',
            reject: 'reject',
            'per Night': 'per Night',
            'contact us for more info': 'contact us for more info',
            Save: 'Save',
            Close: 'Close',
            Commission: 'Commission',
            'There are no data stored yet': 'There are no data stored yet',
            Male: 'Male',
            Female: 'Female',
            'Date of Issue': 'Date of Issue',
            Back: 'Back',
            Cancel: 'Cancel',
            Edit: 'Edit',
            'Pay this invoice': 'Pay this invoice',
            'Credit Card': 'Credit Card',
            Bank: 'Bank',
            Cash: 'Cash',
            'Payment Transaction': 'Payment Transaction',
            'Choose the amount you want to pay': 'Choose the amount you want to pay',
            'Remaining amount': 'Remaining amount',
            'Pay full amount': 'Pay full amount',
            'Partial amount - from': 'Partial amount - from',
            'Pay a partial amount': 'Pay a partial amount',
            'Enter an amount': 'Enter an amount',
            'Select the method of payment': 'Select the method of payment',
            'Accept Terms and Condition': 'Accept Terms and Condition',
            'Add payment': 'Add payment',
            '%': '%',
            'Select extra services for': 'Select extra services for',
            'Offer / Discount': 'Offer / Discount',
            person: 'person',
            'You can assign individual service': 'You can assign individual service',
            'No. of Invoices': 'No. of Invoices',
            Revenue: 'Revenue',
            Currently: 'Currently',
            Invoice: 'Invoice',
            Invoices: 'Invoices',
            Search: 'Search',
            Filter: 'Filter',
            'Sort by': 'Sort by',
            Status: 'Status',
            Amount: 'Amount',
            'Created by': 'Created by',
            'Add custom Invoice': 'Add custom Invoice',
            'Created Date': 'Created Date',
            Web: 'Web',
            Internal: 'Internal',
            'Service Type': 'Service Type',
            'Tour Trip': 'Tour Trip',
            'Tour Package': 'Tour Package',
            Cruise: 'Cruise',
            Customer: 'Customer',
            Paid: 'Paid',
            Unpaid: 'Unpaid',
            Canceled: 'Canceled',
            'The first adult will be used for invoice details': 'The first adult will be used for invoice details',
            'Document Type': 'Document Type',
            'Document ID': 'Document ID',
            'Phone No. (optional)': 'Phone No. (optional)',
            'Email. (optional)': 'Email. (optional)',
            'Extra Bed': 'Extra Bed',
            'over room price': 'over room price',
            Extras: 'Extras',
            people: 'people',
            adult: 'adult',
            'Invoice to': 'Invoice to',
            Phone: 'Phone',
            Participants: 'Participants',
            'Check in - Check out': 'Check in - Check out',
            Guests: 'Guests',
            Adults: 'Adults',
            Children: 'Children',
            Infants: 'Infants',
            Location: 'Location',
            'Available Time': 'Available Time',
            'Reserved Date': 'Reserved Date',
            'Selected Start Times': 'Selected Start Times',
            'Cruise Line': 'Cruise Line',
            Duration: 'Duration',
            'Departure Port': 'Departure Port',
            'Arrival Port': 'Arrival Port',
            'Ship Name': 'Ship Name',
            'No. of cabins': 'No. of cabins',
            Accommodation: 'Accommodation',
            'Duration / Date': 'Duration / Date',
            'No. of rooms': 'No. of rooms',
            Itenerary: 'Itenerary',
            'Starting Date': 'Starting Date',
            'Added to': 'Added to',
            Rooms: 'Rooms',
            Subtotal: 'Subtotal',
            Discount: 'Discount',
            'Tax / VAT': 'Tax / VAT',
            Total: 'Total',
            'Current Language': 'Current Language',
            Help: 'Help',
            'Create New': 'Create New',
            'Publish to the website': 'Publish to the website',
            'View full list': 'View full list',
            'Previews step': 'Previews step',
            'Save and go to next step': 'Save and go to next step',
            'Waiting for Owner Approval': 'Waiting for Owner Approval',
            'Immediately after approval, you will have full access to make reservations for your customers': 'Immediately after approval, you will have full access to make reservations for your customers',
            'You can increase your revenue by selling the B2B products': 'You can increase your revenue by selling the B2B products',
            'You can increase your revenue by selling the B2B products below': 'You can increase your revenue by selling the B2B products below',
            'Make a request by collecting this product and be a worldwide vendor': 'Make a request by collecting this product and be a worldwide vendor',
            'collect now': 'collect now',
            Category: 'Category',
            'Assign to': 'Assign to',
            'Cabin type': 'Cabin type',
            'Date range': 'Date range',
            Adult: 'Adult',
            Child: 'Child',
            Infant: 'Infant',
            'Room type': 'Room type',
            'Start date': 'Start date',
            'End date': 'End date',
            'Check availability': 'Check availability',
            'Number of cabins': 'Number of cabins',
            'Book now': 'Book now',
            child: 'child',
            infant: 'infant',
            'Available Cabins': 'Available Cabins',
            'No. of guests': 'No. of guests',
            'Selected Cabins': 'Selected Cabins',
            'Total Price': 'Total Price',
            'Select this cabin for Reservation': 'Select this cabin for Reservation',
            'Extra Charges': 'Extra Charges',
            Amenities: 'Amenities',
            'View more': 'View more',
            'Extra Beds': 'Extra Beds',
            'Number of Rooms': 'Number of Rooms',
            'Number of Extra Beds': 'Number of Extra Beds',
            'Selected Rooms': 'Selected Rooms',
            'Select this room for Reservation': 'Select this room for Reservation',
            'Sales Overview': 'Sales Overview',
            Question: 'Question',
            Answer: 'Answer',
            Sharing: 'Sharing',
            'Enter the commission for vendors': 'Enter the commission for vendors',
            'Your Terms and Conditions': 'Your Terms and Conditions',
            'In order to have a fair B2B collaboration please enter your terms and conditions that will be used as an agreement to your new partner': 'In order to have a fair B2B collaboration, please enter your terms and conditions that will be used as an agreement with your new partner',
            'Share now': 'Share now',
            'Are you sure to delete this': 'Are you sure you want to delete this',
            'Terms and Conditions': 'Terms and Conditions',
            'Please accept terms and conditions in order to have a fair partnership with the owner of this product': 'Please accept the terms and conditions in order to have a fair partnership with the owner of this product',
            'Send request': 'Send request',
            Website: 'Website',
            'Profile Management': 'Profile Management',
            'History Log': 'History Log',
            Security: 'Security',
            'Help Desk': 'Help Desk',
            'Log out': 'Log out',
            'Name of Service/ Activity': 'Name of Service/ Activity',
            'Add New Extra Service': 'Add New Extra Service',
            'advanced filter': 'advanced filter',
            'Avg. Price': 'Avg. Price',
            Shared: 'Shared',
            'Edit Details': 'Edit Details',
            'Share to Worldwide B2B': 'Share to Worldwide B2B',
            'View details': 'View details',
            'Drag & Drop Photo Here': 'Drag & Drop Photo Here',
            or: 'or',
            Browse: 'Browse',
            'You can upload up to 5 photos for the tour gallery': 'You can upload up to 5 photos for the tour gallery',
            'Drag & Drop photos here': 'Drag & Drop photos here',
            'Resend OTP': 'Resend OTP',
            'Open Map': 'Open Map',
            'Position in Hotel': 'Position in Hotel',
            Webiste: 'Webiste',
            City: 'City',
            'State/ Province': 'State/ Province',
            'Zip Code': 'Zip Code',
            Country: 'Country',
            'Add location to the map (Optional)': 'Add location to the map (Optional)',
            'Map Latitude': 'Map Latitude',
            'Map Longitude': 'Map Longitude',
            'Check in Time': 'Check in Time',
            'Check out Time': 'Check out Time',
            'Payment Options (Optional)': 'Payment Options (Optional)',
            Add: 'Add',
            'Add Extra Bed': 'Add Extra Bed',
            'Price per Extra Bed': 'Price per Extra Bed',
            'Room Photo Gallery': 'Room Photo Gallery',
            Submit: 'Submit',
            'Bed Type for adult': 'Bed Type for adult',
            'Bed Type for child': 'Bed Type for child',
            'Bed Type for infant': 'Bed Type for infant',
            'Room Type Name': 'Room Type Name',
            'Room Size': 'Room Size',
            'Price per Night': 'Price per Night',
            'Price per Person': 'Price per Person',
            'My Commission': 'My Commission',
            'Add new room': 'Add new room',
            'Add New Room Type': 'Add New Room Type',
            'Season Name': 'Season Name',
            'Start Season': 'Start Season',
            'End Season': 'End Season',
            'Select the Room Types you want to assign this Season': 'Select the Room Types you want to assign this Season',
            'Assign to all': 'Assign to all',
            'Add new season': 'Add new season',
            'General Details': 'General Details',
            'Hotel Rates': 'Hotel Rates',
            'Hotel Name': 'Hotel Name',
            'Address & Location': 'Address & Location',
            Policy: 'Policy',
            'Payment Option': 'Payment Option',
            'Contact Person': 'Contact Person',
            'Photo Gallery': 'Photo Gallery',
            'Facilities & Services': 'Facilities & Services',
            Facilities: 'Facilities',
            Services: 'Services',
            'Seasonal Prices': 'Seasonal Prices',
            Seasons: 'Seasons',
            Room: 'Room',
            Accomodation: 'Accomodation',
            'Contact Details': 'Contact Details',
            'Custom Offer': 'Custom Offer',
            'Early Booker': 'Early Booker',
            'Last Minute': 'Last Minute',
            'Free Night': 'Free Night',
            'Create new Offer': 'Create new Offer',
            'Select a Promotion Type': 'Select a Promotion Type',
            'Promotion Name': 'Promotion Name',
            'Start Offer': 'Start Offer',
            'End Offer': 'End Offer',
            'Active Offers': 'Active Offers',
            'Add new Offer': 'Add new Offer',
            'Promotions / Offers': 'Promotions / Offers',
            Policies: 'Policies',
            'Payment Accept': 'Payment Accept',
            'Looks like your accommodations list is empty': 'Looks like your accommodations list is empty',
            'You can create unlimited properties based on the following property types': 'You can create unlimited properties based on the following property types',
            'Welcome to Hola Agency': 'Welcome to Hola Agency',
            'Need help': 'Need help',
            'average commission': 'average commission',
            'This is the commission that this Supplier offers to his Vendors': 'This is the commission that this Supplier offers to his Vendors',
            'B2B Role': 'B2B Role',
            'Supplies for Tour and Accommodation': 'Supplies for Tour and Accommodation',
            'Countries Operation': 'Countries Operation',
            'Filter by Service Type': 'Filter by Service Type',
            'Filter by Service Location': 'Filter by Service Location',
            'Filter by Status': 'Filter by Status',
            Pending: 'Pending',
            'Force Request': 'Force Request',
            Price: 'Price',
            'Create Agency Account': 'Create Agency Account',
            'All follows fields are required': 'All follows fields are required',
            'Search for existing account': 'Search for existing account',
            Password: 'Password',
            'Confirm Password': 'Confirm Password',
            'I agree to the': 'I agree to the',
            'Terms of Service': 'Terms of Service',
            'next step': 'next step',
            'Create Agency Info': 'Create Agency Info',
            'Agency Name': 'Agency Name',
            'Register Number (VAT)': 'Register Number (VAT)',
            'Company Size': 'Company Size',
            'Street Name': 'Street Name',
            'Zip / Postal Code': 'Zip / Postal Code',
            'State / Province': 'State / Province',
            Distribution: 'Distribution',
            'Choose products you want to share with': 'Choose products you want to share with',
            'Choose products you want to share with this partner': 'Choose products you want to share with this partner',
            Products: 'Products',
            Vendors: 'Vendors',
            Suppliers: 'Suppliers',
            'Add Vendor': 'Add Vendor',
            'Filter by Business Location': 'Filter by Business Location',
            'Filter by Country Operation': 'Filter by Country Operation',
            'Search with keyword (agency name, product name...)': 'Search with keyword (agency name, product name...)',
            'vendor found': 'vendor found',
            'vendors found': 'vendors found',
            'supplier found': 'supplier found',
            'suppliers found': 'suppliers found',
            'Distributor for Tour and Accommodation': 'Distributor for Tour and Accommodation',
            'Sales Revenue': 'Sales Revenue',
            'my earning': 'my earning',
            'Are you sure to delete this customer': 'Are you sure to delete this customer',
            'Filter by Tour Location': 'Filter by Tour Location',
            'requests found': 'requests found',
            Recent: 'Recent',
            requests: 'requests',
            'Click on the left item tabs to view full details of each': 'Click on the left item tabs to view full details of each',
            Approve: 'Approve',
            Approved: 'Approved',
            views: 'views',
            All: 'All',
            'My Collection': 'My Collection',
            'tour found': 'tour found',
            'tours found': 'tours found',
            Since: 'Since',
            'Personal No': 'Personal No',
            'Client Type': 'Client Type',
            Delete: 'Delete',
            'Export in XLS': 'Export in XLS',
            'Export in PDF': 'Export in PDF',
            'Select All': 'Select All',
            customers: 'customers',
            'Registered Date': 'Registered Date',
            'Add new Customer': 'Add new Customer',
            Individual: 'Individual',
            Business: 'Business',
            'Company Info': 'Company Info',
            'Edit this customer': 'Edit this customer',
            'Delete this customer': 'Delete this customer',
            Company: 'Company',
            Draft: 'Draft',
            Partial: 'Partial',
            'No discount': 'No discount',
            'View full reports': 'View full reports',
            'Sales by Locations': 'Sales by Locations',
            'Confirmed Orders': 'Confirmed Orders',
            'Pending Orders': 'Pending Orders',
            'Canceled Orders': 'Canceled Orders',
            'Total Customers': 'Total Customers',
            'Total Incoming': 'Total Incoming',
            'Costs since Jan': 'Costs since Jan',
            'Earning since Jan': 'Earning since Jan',
            'This is a revenue reports for the last year starting from January up to current time': 'This is a revenue report for the last year starting from January up to the current time',
            'You can view full reports in the reports menu': 'You can view full reports in the reports menu',
            'The best Deals for you': 'The best Deals for you',
            'Choose from B2B list the best deal for your partnership': 'Choose from B2B list the best deal for your partnership',
            'This email is going to be used for login account': 'This email is going to be used for the login account',
            'Account Details': 'Account Details',
            'Personal Details': 'Personal Details',
            'Employed since': 'Employed since',
            'Creating New Role': 'Creating New Role',
            'Title of Job Position / Role': 'Title of Job Position / Role',
            'Job Position / Role': 'Job Position / Role',
            'You can choose more than one job position for this employee': 'You can choose more than one job position for this employee',
            'Add new Role': 'Add new Role',
            'Salary Information': 'Salary Information',
            'Salary amount': 'Salary amount',
            Monthly: 'Monthly',
            Yearly: 'Yearly',
            'Salary and Job Position': 'Salary and Job Position',
            'Are you sure to delete this employee': 'Are you sure you want to delete this employee',
            Role: 'Role',
            Salary: 'Salary',
            'No. of Sales': 'No. of Sales',
            'Total Revenue': 'Total Revenue',
            'Add Points/Review': 'Add Points/Review',
            'Search by employee name': 'Search by employee name',
            Roles: 'Roles',
            Name: 'Name',
            'Add new Employee': 'Add new Employee',
            'Add new Evaluation': 'Add new Evaluation',
            'Job Knowledge': 'Job Knowledge',
            Productivity: 'Productivity',
            Teamwork: 'Teamwork',
            'Communication Skills': 'Communication Skills',
            'Enter your opinion about this evaluation': 'Enter your opinion about this evaluation',
            'Total scores': 'Total scores',
            Points: 'Points',
            Notes: 'Notes',
            'Last Evaluation': 'Last Evaluation',
            'Filter by date': 'Filter by date',
            month: 'month',
            'Edit this employee': 'Edit this employee',
            'Delete this employee': 'Delete this employee',
            'Overall Performance': 'Overall Performance',
            'View full story': 'View full story',
            Age: 'Age',
            'Total sales': 'Total sales',
            'Since the beginning': 'Since the beginning',
            'Total amount': 'Total amount',
            'This month': 'This month',
            'Last 30 days': 'Last 30 days',
            'Search action': 'Search action',
            'Select the following action to give access / permissions this type of role': 'Select the following action to give access/permissions to this type of role',
            'Roles / Job Positions': 'Roles / Job Positions',
            Passengers: 'Passengers',
            'years old': ' years old',
            Direction: 'Direction',
            'Type of Trip': 'Type of Trip',
            Date: 'Date',
            Time: 'Time',
            'Time zone': 'Time zone',
            Departure: 'Departure',
            Airline: 'Airline',
            'stop to': 'stop to',
            'Transit Airport': 'Transit Airport',
            Class: 'Class',
            'Flight Number': 'Flight Number',
            Arrival: 'Arrival',
            cm: 'cm',
            Return: 'Return',
            'Ticket No.': 'Ticket No.',
            Origin: 'Origin',
            'Airport name': 'Airport name',
            Destination: 'Destination',
            'Transit Airline': 'Transit Airline',
            'Arrival Date & Time': 'Arrival Date & Time',
            'Departure Date & Time': 'Departure Date & Time',
            'Date & Time': 'Date & Time',
            'Add transit': 'Add transit',
            'Extra Price': 'Extra Price',
            'One way': 'One way',
            'Round Trip': 'Round Trip',
            'Confirm ticket': 'Confirm ticket',
            'Flight Details': 'Flight Details',
            'Search passenger': 'Search passenger',
            'Add new ticket': 'Add new ticket',
            'Are you sure to delete this flight': 'Are you sure to delete this flight',
            'Invoice Ft': 'Invoice Ft',
            'Forgot your password': 'Forgot your password',
            'A new password will be sent to your email address': 'A new password will be sent to your email address',
            'Reset password': 'Reset password',
            'Create an account': 'Create an account',
            'Create new password': 'Create new password',
            'New Password': 'New Password',
            'Save password': 'Save password',
            'Email verification': 'Email verification',
            'Please enter the verification code sent to': 'Please enter the verification code sent to',
            'Otp code needs to be 6 characters': 'Otp code needs to be 6 characters',
            'Sign in': 'Sign in',
            '25 Tours are available based on your search': '25 Tours are available based on your search',
            Domain: 'Domain',
            'Availability search box': 'Availability search box',
            'Availability search box for': 'Availability search box for',
            'Search Results': 'Search Results',
            'Script Search box': 'Script Search box',
            'Script Search Result Page': 'Script Search Result Page',
            'Script Featured products by selected preview mode': 'Script Featured products by selected preview mode',
            'Choose the preview mode on website': 'Choose the preview mode on website',
            'Whitelist domain': 'Whitelist domain',
            'Booking engine': 'Booking engine',
            'Configure widgets for your website integration': 'Configure widgets for your website integration',
            'Choose the type of widget for the Search Box': 'Choose the type of widget for the Search Box',
            'Select the services you want to integrate to the website': 'Select the services you want to integrate to the website',
            'Generated Widgets': 'Generated Widgets',
            Flights: 'Flights',
            Tours: 'Tours',
            Transfers: 'Transfers',
            'Search for an Existing Customer': 'Search for an Existing Customer',
            'Invoice Details': 'Invoice Details',
            'Guests Information': 'Guests Information',
            'Extra Discount': 'Extra Discount',
            Service: 'Service',
            'Search for an Existing Service or create a new one': 'Search for an Existing Service or create a new one',
            'Enter a service or product name that is not listed in your database (ex. Flight Ticket for Tirana - Amsterdam)': 'Enter a service or product name that is not listed in your database (ex. Flight Ticket for Tirana - Amsterdam)',
            Description: 'Description',
            'Select a date': 'Select a date',
            'Add new Service': 'Add new Service',
            'Save invoice': 'Save invoice',
            'Billed For': 'Billed For',
            Extra: 'Extra',
            Overdue: 'Overdue',
            'Are you sure to delete this invoice': 'Are you sure to delete this invoice',
            'Sign in to your account': 'Sign in to your account',
            'Start managing your business in a few clicks': 'Start managing your business in a few clicks',
            'Remember me': 'Remember me',
            'Forgot password': 'Forgot password',
            'Street Address': 'Street Address',
            'Add agency details': 'Add agency details',
            Register: 'Register',
            'I have an account': 'I have an account',
            'Create an account and say Hola': 'Create an account and say Hola',
            'Select the features needed for your AGENCY management': 'Select the features needed for your AGENCY management',
            'Go back': 'Go back',
            'Add selected services': 'Add selected services',
            'Create account': 'Create account',
            'Email and Password will be used as login credential': 'Email and Password will be used as login credential',
            'Select services': 'Select services',
            'Add to your account our services for full managements': 'Add to your account our services for full managements',
            'Agency details': 'Agency details',
            'Add your Agency information': 'Add your Agency information',
            'Verify your email': 'Verify your email',
            'Phone no. verification': 'Phone no. verification',
            'Edit your phone number': 'Edit your phone number',
            'Verify your phone number': 'Verify your phone number',
            Profits: 'Profits',
            by: 'by',
            Export: 'Export',
            Send: 'Send',
            'B2B Incoming': 'B2B Incoming',
            'Partnership Costs': 'Partnership Costs',
            'Total Suppliers': 'Total Suppliers',
            'Collected Services': 'Collected Services',
            'Revenue from Suppliers': 'Revenue from Suppliers',
            'average profit': 'average profit',
            'Total Vendors': 'Total Vendors',
            'shared services': 'shared services',
            'Sales by Vendors': 'Sales by Vendors',
            'in win': 'in win',
            'in los': 'in los',
            'Select 2 different periods to compare the historic data between each other': 'Select 2 different periods to compare the historic data between each other',
            Incoming: 'Incoming',
            Costs: 'Costs',
            Earned: 'Earned',
            'Registered Customers': 'Registered Customers',
            Results: 'Results',
            'Your revenue is': 'Your revenue is',
            'To improve your revenue we suggest following steps below': 'To improve your revenue we suggest following steps below',
            'Use the Marketing Tools': 'Use the Marketing Tools',
            'Increase the B2B Partners': 'Increase the B2B Partners',
            'Stats From': 'Stats From',
            To: 'To',
            'All Services': 'All Services',
            'All Type of Partners': 'All Type of Partners',
            Vendor: 'Vendor',
            Supplier: 'Supplier',
            'Customers Origin / Country': 'Customers Origin / Country',
            'Partners Origin / Country': 'Partners Origin / Country',
            Compare: 'Compare',
            'Send to': 'Send to',
            'Drag & Drop your': 'Drag & Drop your',
            Logo: 'Logo',
            here: 'here',
            'Agency / Company Name': 'Agency / Company Name',
            'Registration Number (VAT)': 'Registration Number (VAT)',
            'Company Phone': 'Company Phone',
            'Company Email': 'Company Email',
            Timezone: 'Timezone',
            Currency: 'Currency',
            'Edit Tax': 'Edit Tax',
            'Add New Tax': 'Add New Tax',
            'Tax Name': 'Tax Name',
            'Tax Rate': 'Tax Rate',
            'Tax Type': 'Tax Type',
            Percentage: 'Percentage',
            'Fixed Amount': 'Fixed Amount',
            'Refundable Tax': 'Refundable Tax',
            'Are you sure to delete this tax': 'Are you sure to delete this tax',
            'Activity Name': 'Activity Name',
            'Default Price': 'Default Price',
            Availability: 'Availability',
            'Activity Description': 'Activity Description',
            'Enter the full address': 'Enter the full address',
            'Extra Services': 'Extra Services',
            'My Own Product': 'My Own Product',
            'Search for an Existing Partner': 'Search for an Existing Partner',
            '- or create new one below -': '- or create new one below -',
            'Time & Duration': 'Time & Duration',
            Partner: 'Partner',
            'Start time': 'Start time',
            'or Default Price': 'or Default Price',
            day: 'day',
            days: 'days',
            'Skip this step': 'Skip this step',
            'Duration in minutes / hours': 'Duration in minutes / hours',
            'Duration in days': 'Duration in days',
            minutes: 'minutes',
            hour: 'hour',
            hours: 'hours',
            'add new timeslot': 'add new timeslot',
            'How many times in day': 'How many times in day',
            times: 'times',
            'Delete this timeslot': 'Delete this timeslot',
            h: 'h',
            'Edit this activity': 'Edit this activity',
            'Delete this activity': 'Delete this activity',
            'Remove this shared activity': 'Remove this shared activity',
            'Remove this shared collected activity': 'Remove this shared collected activity',
            'Make a Reservation to this Activity': 'Make a Reservation to this Activity',
            'You can select more than one times above, depended by dates': 'You can select more than one times above, depended by dates',
            'Add new reservation for this activity': 'Add new reservation for this activity',
            'add new item': 'add new item',
            Title: 'Title',
            'Arrival time': 'Arrival time',
            'Departure time': 'Departure time',
            'Same Port for Departure': 'Same Port for Departure',
            'You can add a location by clicking over the map': 'You can add a location by clicking over the map',
            'delete item': 'delete item',
            'Cruise Name': 'Cruise Name',
            'Cruise Style': 'Cruise Style',
            'Start Date & Time': 'Start Date & Time',
            'End Date & Time': 'End Date & Time',
            'Departure Port / Location': 'Departure Port / Location',
            'Arrival Port / Location': 'Arrival Port / Location',
            'Cruise Description': 'Cruise Description',
            'Per person': 'Per person',
            'Per Night': 'Per Night',
            'Per cabin': 'Per cabin',
            'Avg. Price per Night': 'Avg. Price per Night',
            'Add new cabin type': 'Add new cabin type',
            'No. of Adults': 'No. of Adults',
            min: 'min',
            max: 'max',
            'Add Children or Infants': 'Add Children or Infants',
            'Max. Adults': 'Max. Adults',
            'Max. Children': 'Max. Children',
            'Max. Infants': 'Max. Infants',
            'Delete cabin type': 'Delete cabin type',
            'Add new ship': 'Add new ship',
            'Search for Ship': 'Search for Ship',
            'Select one of the Ships below for this Cruise Tour or': 'Select one of the Ships below for this Cruise Tour or',
            'Cabin Types': 'Cabin Types',
            "I don't have an account": "I don't have an account",
            'since last month': 'since last month',
            'Edit this ship': 'Edit this ship',
            'Delete this ship': 'Delete this ship',
            'Cruise Itenerary': 'Cruise Itinerary',
            'Cruise Type': 'Cruise Type',
            'Extra Service': 'Extra Service',
            inclusive: 'inclusive',
            'Inclusions / Exclusions': 'Inclusions / Exclusions',
            Inclusions: 'Inclusions',
            Exclusions: 'Exclusions',
            'Ship Details': 'Ship Details',
            'Incl. / Excl.': 'Incl. / Excl.',
            'Edit this cruise': 'Edit this cruise',
            'Delete this cruise': 'Delete this cruise',
            'Remove this shared cruise': 'Remove this shared cruise',
            'Remove this shared collected cruise': 'Remove this shared collected cruise',
            'Tour Type': 'Tour Type',
            'Looks you have empty tour categories': 'Looks like you have empty tour categories',
            'You can create unlimited tours based on follows categories': 'You can create unlimited tours based on the following categories',
            'Add new hotel': 'Add new hotel',
            'Add new resort': 'Add new resort',
            'Add new motel': 'Add new motel',
            'Add new hostel': 'Add new hostel',
            'View hotels': 'View hotels',
            'View resorts': 'View resorts',
            'View motels': 'View motels',
            'View hostels': 'View hostels',
            'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.': 'Detailed trips for Individual and Group participants, including full itinerary program based on an interactive map.',
            'Search for Hotel, Villa, Guesthouse': 'Search for Hotel, Villa, Guesthouse',
            'Select one of the Accommodation or': 'Select one of the Accommodation or',
            'Add New Accommodation': 'Add New Accommodation',
            'Room Types': 'Room Types',
            'Number of Rooms Available': 'Number of Rooms Available',
            'Edit this hotel': 'Edit this hotel',
            'Delete this hotel': 'Delete this hotel',
            'Package Description': 'Package Description',
            'Package Name': 'Package Name',
            'Package Style': 'Package Style',
            'Per adult': 'Per adult',
            'Per group': 'Per group',
            'Tour Style': 'Tour Style',
            'Default Prices': 'Default Prices',
            'Extra Prices': 'Extra Prices',
            'Tour Gallery': 'Tour Gallery',
            'Edit this package': 'Edit this package',
            'Delete this package': 'Delete this package',
            'Remove this shared package': 'Remove this shared package',
            'Remove this shared collected package': 'Remove this shared collected package',
            'Regular or charter depending on departures': 'Regular or charter depending on departures',
            'Tour Description': 'Tour Description',
            'Tour Name': 'Tour Name',
            Fixed: 'Fixed',
            Rules: 'Rules',
            'Adult Price': 'Adult Price',
            Popular: 'Popular',
            'add new rule': 'Add new rule',
            'Rule Type': 'Rule Type',
            'Less than': 'Less than',
            Range: 'Range',
            'Greater than': 'Greater than',
            'Group size and Prices': 'Group size and Prices',
            'Tour Itenerary': 'Tour Itinerary',
            'Add new group': 'Add new group',
            'This group has completed with number of participants': 'This group has completed with a number of participants',
            'Are you sure to delete this group': 'Are you sure you want to delete this group',
            'Group Name': 'Group Name',
            'Avg. Age': 'Avg. Age',
            'Add reservation': 'Add reservation',
            'Full Name': 'Full Name',
            'Edit this trip': 'Edit this trip',
            'Delete this trip': 'Delete this trip',
            'Remove this shared trip': 'Remove this shared trip',
            'Remove this shared collected trip': 'Remove this shared collected trip',
            Style: 'Style',
            Hotel: 'Hotel',
            Resort: 'Resort',
            Motel: 'Motel',
            Hostel: 'Hostel',
            'Tour Packages': 'Tour Packages',
            'Cruise Tour': 'Cruise Tour',
            Activities: 'Activities',
            'Add new tour': 'Add new tour',
            'Add new activity': 'Add new activity',
            'View tours': 'View tours',
            'View activities': 'View activities',
            'Something amazing is in the works! Check back soon for exciting new features, or get in touch with our team to learn more': 'Something amazing is in the works! Check back soon for exciting new features, or get in touch with our team to learn more',
            Employees: 'Employees',
            'Check in': 'Check in',
            'Check out': 'Check out',
            "This option will generate more than one search boxes based on the services you'll select": "This option will generate more than one search boxes based on the services you'll select",
            'Put all services into one search box': 'Put all services into one search box',
            'Split the services into separated search boxes': 'Split the services into separated search boxes',
            'Domain whitelist': 'Domain whitelist',
            Customers: 'Customers',
            "Make requests for the products you'll choose and be a worldwide vendor": "Make requests for the products you'll choose and be a worldwide vendor",
            'Worldwide Supplier': 'Worldwide Supplier',
            'My Partners': 'My Partners',
            Requests: 'Requests',
            'In-house Stats': 'In-house Stats',
            'B2B Stats': 'B2B Stats',
            'Booking Engine': 'Booking Engine',
            'Channel Manager': 'Channel Manager',
            Marketing: 'Marketing',
            Taxes: 'Taxes',
            Billing: 'Billing',
            General: 'General',
            'Add new tax': 'Add new tax',
            Items: 'Items',
            Period: 'Period',
            Created: 'Created',
            'Product /Service': 'Product /Service',
            Actions: 'Actions',
            'Customer origin': 'Customer origin',
            'Partner type': 'Partner type',
            'Order date': 'Order date',
            'Agency Info': 'Agency Info',
            'Requested by': 'Requested by',
            'Requested time': 'Requested time',
            sales: 'sales',
            'Add new Hotel': 'Add new Hotel',
            'Add new Resort': 'Add new Resort',
            'Add new Motel': 'Add new Motel',
            'Add new Hostel': 'Add new Hostel',
            hotel: 'hotel',
            hotels: 'hotels',
            resort: 'resort',
            resorts: 'resorts',
            motel: 'motel',
            motels: 'motels',
            hostel: 'hostel',
            hostels: 'hostels',
            'Hotel Details': 'Hotel Details',
            'Resort Details': 'Resort Details',
            'Motel Details': 'Motel Details',
            'Hostel Details': 'Hostel Details',
            'Hotel Facilities & Services': 'Hotel Facilities & Services',
            'Resort Facilities & Services': 'Resort Facilities & Services',
            'Motel Facilities & Services': 'Motel Facilities & Services',
            'Hostel Facilities & Services': 'Hostel Facilities & Services',
            'Resort Rates': 'Resort Rates',
            'Motel Rates': 'Motel Rates',
            'Hostel Rates': 'Hostel Rates',
            'In order to have a fair B2B collaboration please enter your terms and conditions that will be use as an agreement to your new partner': 'In order to have a fair B2B collaboration please enter your terms and conditions that will be use as an agreement to your new partner',
            Reservation: 'Reservation',
            'Accommodation Details': 'Accommodation Details',
            'Put your Hotel rate (Optional)': 'Put your Hotel rate (Optional)',
            'Put your Resort rate (Optional)': 'Put your Resort rate (Optional)',
            'Put your Motel rate (Optional)': 'Put your Motel rate (Optional)',
            'Put your Hostel rate (Optional)': 'Put your Hostel rate (Optional)',
            'Add New Payment Option': 'Add New Payment Option',
            'Hotel Photo Gallery': 'Hotel Photo Gallery',
            'Resort Photo Gallery': 'Resort Photo Gallery',
            'Motel Photo Gallery': 'Motel Photo Gallery',
            'Hostel Photo Gallery': 'Hostel Photo Gallery',
            'Resort Name': 'Resort Name',
            'Motel Name': 'Motel Name',
            'Hostel Name': 'Hostel Name',
            'Hotel Description': 'Hotel Description',
            'Resort Description': 'Resort Description',
            'Motel Description': 'Motel Description',
            'Hostel Description': 'Hostel Description',
            'Add New Facility': 'Add New Facility',
            'The price is inclusive to the tour': 'The price is inclusive to the tour',
            "Oops! Looks you haven't created yet any Room": "Oops! Looks you haven't created yet any Room",
            "Oops! Looks you haven't created yet any Season": "Oops! Looks you haven't created yet any Season",
            "Oops! Looks you haven't created yet any Hotel": "Oops! Looks you haven't created yet any Hotel",
            "Oops! Looks you haven't created yet any Resort": "Oops! Looks you haven't created yet any Resort",
            "Oops! Looks you haven't created yet any Motel": "Oops! Looks you haven't created yet any Motel",
            "Oops! Looks you haven't created yet any Hostel": "Oops! Looks you haven't created yet any Hostel",
            'Start creating Hotel': 'Start creating Hotel',
            'Start creating Resort': 'Start creating Resort',
            'Start creating Motel': 'Start creating Motel',
            'Start creating Hostel': 'Start creating Hostel',
            'View this hotel': 'View this hotel',
            'View this resort': 'View this resort',
            'View this motel': 'View this motel',
            'View this hostel': 'View this hostel',
            "Oops! Looks you haven't created yet any Tour Trip": "Oops! Looks you haven't created yet any Tour Trip",
            'Start creating tour trip': 'Start creating tour trip',
            'Tour name': 'Tour name',
            'Group size': 'Group size',
            'View all Tours': 'View all Tours',
            'Tour Info': 'Tour Info',
            "Oops! Looks you haven't created yet any Group": "Oops! Looks you haven't created yet any Group",
            'Starting date': 'Starting date',
            'Start Date': 'Start Date',
            'Incl. /Excl.': 'Incl. /Excl.',
            'Add New Inclusion Item': 'Add New Inclusion Item',
            'Add New Exclusion Item': 'Add New Exclusion Item',
            'View this tour': 'View this tour',
            "Oops! Looks you haven't created yet any Tour Packages": "Oops! Looks you haven't created yet any Tour Packages",
            'Start creating tour packages': 'Start creating tour packages',
            'Search for Package': 'Search for Package',
            'Hotel, Location': 'Hotel, Location',
            'View all Package Tours': 'View all Package Tours',
            'View this package': 'View this package',
            'Search for Activity name': 'Search for Activity name',
            'Date Range': 'Date Range',
            "Oops! Looks you haven't created yet any Activities": "Oops! Looks you haven't created yet any Activities",
            'Start creating activity': 'Start creating activity',
            'View all Activities': 'View all Activities',
            When: 'When',
            'The price is inclusive to the activity': 'The price is inclusive to the activity',
            "Partner's Product": "Partner's Product",
            'View this activity': 'View this activity',
            "Oops! Looks you haven't created yet any Cruise Tour": "Oops! Looks you haven't created yet any Cruise Tour",
            'Start creating cruise tour': 'Start creating cruise tour',
            'Search for Cruise Tour': 'Search for Cruise Tour',
            'Available cabins': 'Available cabins',
            'The price is inclusive to the cruise': 'The price is inclusive to the cruise',
            'End Date': 'End Date',
            'View this cruise': 'View this cruise',
            'View all Cruise Tours': 'View all Cruise Tours',
            'Add New Ship': 'Add New Ship',
            'Number of Cabins': 'Number of Cabins',
            'Cabin Type': 'Cabin Type',
            'Choose cabin and start/end date before reservation': 'Choose cabin and start/end date before reservation',
            'Marketing Tools': 'Marketing Tools',
            Dashboard: 'Dashboard',
            Accommodations: 'Accommodations',
            'B2B Partners': 'B2B Partners',
            'Stats. & Reports': 'Stats. & Reports',
            Integrations: 'Integrations',
            "First Name can't be empty": "First Name can't be empty",
            'First Name has to be at least 3 characters': 'First Name has to be at least 3 characters',
            'First Name cannot be more than 30 characters': 'First Name cannot be more than 30 characters',
            "Last Name can't be empty": "Last Name can't be empty",
            'Last Name has to be at least 3 characters': 'Last Name has to be at least 3 characters',
            'Last Name cannot be more than 30 characters': 'Last Name cannot be more than 30 characters',
            "Id can't be empty": "Id can't be empty",
            "Nationality can't be empty": "Nationality can't be empty",
            "Phone can't be empty": "Phone can't be empty",
            'Phone has to be at least 8 characters': 'Phone has to be at least 8 characters',
            'Phone cannot be more than 12 characters': 'Phone cannot be more than 12 characters',
            "Prefix can't be empty": "Prefix can't be empty",
            "Email can't be empty": "Email can't be empty",
            'Email has to be at least 2 characters': 'Email has to be at least 2 characters',
            'Email cannot be more than 150 characters': 'Email cannot be more than 150 characters',
            "Password can't be empty": "Password can't be empty",
            'Password has to be at least 6 characters': 'Password has to be at least 6 characters',
            'Password cannot be more than 40 characters': 'Password cannot be more than 40 characters',
            "Confirm Password can't be empty": "Confirm Password can't be empty",
            'Confirm Password has to be at least 6 characters': 'Confirm Password has to be at least 6 characters',
            'Confirm Password cannot be more than 40 characters': 'Confirm Password cannot be more than 40 characters',
            "Gender can't be empty": "Gender can't be empty",
            "Birthdate can't be empty": "Birthdate can't be empty",
            "Address can't be empty": "Address can't be empty",
            'Address has to be at least 5 characters': 'Address has to be at least 5 characters',
            'Address cannot be more than 150 characters': 'Address cannot be more than 150 characters',
            "Stars can't be empty": "Stars can't be empty",
            "Description can't be empty": "Description can't be empty",
            'Description has to be at least 5 characters': 'Description has to be at least 5 characters',
            'Description cannot be more than 300 characters': 'Description cannot be more than 300 characters',
            "City can't be empty": "City can't be empty",
            'City has to be at least 3 characters': 'City has to be at least 3 characters',
            'City cannot be more than 20 characters': 'City cannot be more than 20 characters',
            "State/Province can't be empty": "State/Province can't be empty",
            'State/Province has to be at least 3 characters': 'State/Province has to be at least 3 characters',
            'State/Province cannot be more than 20 characters': 'State/Province cannot be more than 20 characters',
            "Zip code can't be empty": "Zip code can't be empty",
            "Country can't be empty": "Country can't be empty",
            "Check in time can't be empty": "Check in time can't be empty",
            "Check out time can't be empty": "Check out time can't be empty",
            "Payment methods can't be empty": "Payment methods can't be empty",
            "Position can't be empty": "Position can't be empty",
            "Website can't be empty": "Website can't be empty",
            'Website has to be at least 8 characters': 'Website has to be at least 8 characters',
            'Website cannot be more than 50 characters': 'Website cannot be more than 50 characters',
            "Offer type can't be empty": "Offer type can't be empty",
            "Offer name can't be empty": "Offer name can't be empty",
            "Discount can't be empty": "Discount can't be empty",
            "Discount type can't be empty": "Discount type can't be empty",
            "Start date can't be empty": "Start date can't be empty",
            "End date can't be empty": "End date can't be empty",
            "Room types can't be empty": "Room types can't be empty",
            'Confirm password needs to be the same as password': 'Confirm password needs to be the same as password',
            "Terms of service can't be empty": "Terms of service can't be empty",
            "Company Name can't be empty": "Company Name can't be empty",
            'Company Name has to be at least 3 characters': 'Company Name has to be at least 3 characters',
            'Company Name cannot be more than 30 characters': 'Company Name cannot be more than 30 characters',
            "Register Number (VAT) can't be empty": "Register Number (VAT) can't be empty",
            'Register Number (VAT) has to be at least 3 characters': 'Register Number (VAT) has to be at least 3 characters',
            'Register Number (VAT) cannot be more than 30 characters': 'Register Number (VAT) cannot be more than 30 characters',
            "Company Size can't be empty": "Company Size can't be empty",
            "Street Address can't be empty": "Street Address can't be empty",
            'Street Address has to be at least 3 characters': 'Street Address has to be at least 3 characters',
            'Street Address cannot be more than 50 characters': 'Street Address cannot be more than 50 characters',
            "Date employed can't be empty": "Date employed can't be empty",
            "Phone number can't be empty": "Phone number can't be empty",
            "Image can't be empty": "Image can't be empty",
            'VAT Number can be empty': 'VAT Number can be empty',
            "Timezone can't be empty": "Timezone can't be empty",
            "Currency can't be empty": "Currency can't be empty",
            "Agency logo can't be empty": "Agency logo can't be empty",
            "Name can't be empty": "Name can't be empty",
            'Name has to be at least 5 characters': 'Name has to be at least 5 characters',
            'Name cannot be more than 150 characters': 'Name cannot be more than 150 characters',
            "Default Price can't be empty": "Default Price can't be empty",
            "Availability can't be empty": "Availability can't be empty",
            "Latitude can't be empty": "Latitude can't be empty",
            "Longitude can't be empty": "Longitude can't be empty",
            "Extra service name can't be empty": "Extra service name can't be empty",
            "Duration can't be empty": "Duration can't be empty",
            "Tour style can't be empty": "Tour style can't be empty",
            "Adults number can't be empty": "Adults number can't be empty",
            "Adults price can't be empty": "Adults price can't be empty",
            "Title can't be empty": "Title can't be empty",
            'Title has to be at least 5 characters': 'Title has to be at least 5 characters',
            'Title cannot be more than 150 characters': 'Title cannot be more than 150 characters',
            "Location can't be empty": "Location can't be empty",
            'Location has to be at least 5 characters': 'Location has to be at least 5 characters',
            'Location cannot be more than 150 characters': 'Location cannot be more than 150 characters',
            "Package style can't be empty": "Package style can't be empty",
            "Arrival time can't be empty": "Arrival time can't be empty",
            "Departure time can't be empty": "Departure time can't be empty",
            "Arrival port can't be empty": "Arrival port can't be empty",
            "Departure port can't be empty": "Departure port can't be empty",
            "Cruise line can't be empty": "Cruise line can't be empty",
            "Cruise style can't be empty": "Cruise style can't be empty",
            "Start date & time can't be empty": "Start date & time can't be empty",
            "End date & time can't be empty": "End date & time can't be empty",
            "Price can't be empty": "Price can't be empty",
            'Duration in min/hours': 'Duration in min/hours',
            time: 'time',
            'No. Reservation': 'No. Reservation',
            "Service name can't be empty": "Service name can't be empty",
            "Date can't be empty": "Date can't be empty",
            'Description cannot be more than 1000 characters': 'Description cannot be more than 1000 characters',
            "Commission can't be empty": "Commission can't be empty",
            "Commission type can't be empty": "Commission type can't be empty",
            'This email will be used for logging in': 'This email will be used for logging in',
            "Cost can't be empty": "Cost can't be empty",
            'Change Password': 'Change Password',
            'Confirm New Password needs to be the same as New Password': 'Confirm New Password needs to be the same as New Password',
            "The password shouldn't be less than 8 character, including at least a cap letter, a symbol and a number": "The password shouldn't be less than 8 character, including at least a cap letter, a symbol and a number",
            Cost: 'Cost',
            'Cruise style cannot be empty': 'Cruise style cannot be empty',
            'Package style cannot be empty': 'Package style cannot be empty',
            'Tour style cannot be empty': 'Tour style cannot be empty',
            'The request has been sent successfully': 'The request has been sent successfully',
            'The offer has been added successfully': 'The offer has been added successfully',
            'Domain added successfully': 'Domain added successfully',
            'Domain deleted successfully': 'Domain deleted successfully',
            'Group added successfully': 'Group added successfully',
            'Group name edited successfully': 'Group name edited successfully',
            'Group deleted successfully': 'Group deleted successfully',
            'hotel deleted successfully': 'hotel deleted successfully',
            'motel deleted successfully': 'motel deleted successfully',
            'resort deleted successfully': 'resort deleted successfully',
            'hostel deleted successfully': 'hostel deleted successfully',
            'trip deleted successfully': 'trip deleted successfully',
            'activity deleted successfully': 'activity deleted successfully',
            'cruise deleted successfully': 'cruise deleted successfully',
            'package deleted successfully': 'package deleted successfully',
            'invoice deleted successfully': 'invoice deleted successfully',
            'customer deleted successfully': 'customer deleted successfully',
            'employee deleted successfully': 'employee deleted successfully',
            'partner deleted successfully': 'partner deleted successfully',
            'Agency details updated successfully': 'Agency details updated successfully',
            'Tax details saved successfully': 'Tax details saved successfully',
            'tax deleted successfully': 'tax deleted successfully',
            'Your password has been reset successfully': 'Your password has been reset successfully',
            'Back to sign in': 'Back to sign in',
            'Widget Category': 'Widget Category',
            'OTP code resent successfully': 'OTP code resent successfully',
            'Search for an Existing Vendor': 'Search for an Existing Vendor',
            Optional: 'Optional',
            'Invoice status changed successfully': 'Invoice status changed successfully',
            'Change status': 'Change status',
            'Price per Night BB': 'Price per Night BB',
            'Price per Night HB': 'Price per Night HB',
            'Price per Night AI': 'Price per Night AI',
            'Price per Person BB': 'Price per Person BB',
            'Price per Person HB': 'Price per Person HB',
            'Price per Person AI': 'Price per Person AI',
            'Your choice': 'Your choice',
            'No available rooms': 'No available rooms',
            'No available cabins': 'No available cabins',
            'Choose a price type, room and start/end date before reservation': 'Choose a price type, room and start/end date before reservation',
            Confirmed: 'Confirmed',
            'Not Collected': 'Not Collected',
            'A-Z': 'A-Z',
            'Z-A': 'Z-A',
            'This service was canceled in the last request': 'This service was canceled in the last request',
            'From Age': 'From Age',
            'To Age': 'To Age',
            'Child Price Policy': 'Child Price Policy',
            'add child policy': 'Add child policy',
            'add policy': 'Add policy',
            'remove child policy': 'Remove child policy',
            'No available extra beds': 'No available extra beds',
            'year old': 'year old',
            'Charges Type': 'Charges Type',
        },
    },
    it: {
        translation: {
            from: 'da',
            night: 'notte',
            'Make reservation': 'Effettua prenotazione',
            'More details': 'Maggiori dettagli',
            more: 'più',
            'view less': 'mostra meno',
            'First Name': 'Nome',
            'Last Name': 'Cognome',
            'ID Number': 'Numero di identificazione',
            Nationality: 'Nazionalità',
            'Phone Number': 'Numero di telefono',
            Email: 'Email',
            Gender: 'Sesso',
            Birthdate: 'Data di nascita',
            'Is a company': 'È un\'azienda',
            'Company Name': 'Nome azienda',
            'Registration Number': 'Numero di registrazione',
            Address: 'Indirizzo',
            'Service image': 'Immagine del servizio',
            'your earning': 'le tue entrate',
            collect: 'raccogli',
            collected: 'raccolti',
            'pending approval': 'in attesa di approvazione',
            accept: 'accetta',
            reject: 'rifiuta',
            'per Night': 'per notte',
            'contact us for more info': 'contattaci per ulteriori informazioni',
            Save: 'Salva',
            Close: 'Chiudi',
            Commission: 'Commissione',
            'There are no data stored yet': 'Non ci sono ancora dati memorizzati',
            Male: 'Maschio',
            Female: 'Femmina',
            'Date of Issue': 'Data di emissione',
            Back: 'Indietro',
            Cancel: 'Annulla',
            Edit: 'Modifica',
            'Pay this invoice': 'Paga questa fattura',
            'Credit Card': 'Carta di credito',
            Bank: 'Banca',
            Cash: 'Contanti',
            'Payment Transaction': 'Transazione di pagamento',
            'Choose the amount you want to pay': 'Scegli l\'importo che desideri pagare',
            'Remaining amount': 'Importo rimanente',
            'Pay full amount': 'Paga importo intero',
            'Partial amount - from': 'Importo parziale - da',
            'Pay a partial amount': 'Paga importo parziale',
            'Enter an amount': 'Inserisci un importo',
            'Select the method of payment': 'Seleziona il metodo di pagamento',
            'Accept Terms and Condition': 'Accetta Termini e Condizioni',
            'Add payment': 'Aggiungi pagamento',
            '%': '%',
            'Select extra services for': 'Seleziona servizi aggiuntivi per',
            'Offer / Discount': 'Offerta / Sconto',
            person: 'persona',
            'You can assign individual service': 'Puoi assegnare un servizio individuale',
            'No. of Invoices': 'Numero di fatture',
            Revenue: 'Ricavo',
            Currently: 'Attualmente',
            Invoice: 'Fattura',
            Invoices: 'Fatture',
            Search: 'Cerca',
            Filter: 'Filtra',
            'Sort by': 'Ordina per',
            Status: 'Stato',
            Amount: 'Quantità',
            'Created by': 'Creato da',
            'Add custom Invoice': 'Aggiungi fattura personalizzata',
            'Created Date': 'Data di creazione',
            Web: 'Web',
            Internal: 'Interno',
            'Service Type': 'Tipo di servizio',
            'Tour Trip': 'Viaggio turistico',
            'Tour Package': 'Pacchetto turistico',
            Cruise: 'Crociere',
            Customer: 'Cliente',
            Paid: 'Pagato',
            Unpaid: 'Non pagato',
            Canceled: 'Annullato',
            'The first adult will be used for invoice details': 'Il primo adulto verrà utilizzato per i dettagli della fattura',
            'Document Type': 'Tipo di documento',
            'Document ID': 'ID documento',
            'Phone No. (optional)': 'Numero di telefono (opzionale)',
            'Email. (optional)': 'Email (opzionale)',
            'Extra Bed': 'Letto extra',
            'over room price': 'sopra il prezzo della camera',
            Extras: 'Extra',
            people: 'persone',
            adult: 'adulti',
            'Invoice to': 'Fattura a',
            Phone: 'Telefono',
            Participants: 'Partecipanti',
            'Check in - Check out': 'Check in - Check out',
            Guests: 'Ospiti',
            Adults: 'Adulti',
            Children: 'Bambini',
            Infants: 'Neonati',
            Location: 'Posizione',
            'Available Time': 'Orario disponibile',
            'Reserved Date': 'Data prenotata',
            'Selected Start Times': 'Orari di inizio selezionati',
            'Cruise Line': 'Compagnia di crociera',
            Duration: 'Durata',
            'Departure Port': 'Porto di partenza',
            'Arrival Port': 'Porto di arrivo',
            'Ship Name': 'Nome della nave',
            'No. of cabins': 'Numero di cabine',
            Accommodation: 'Alloggio',
            'Duration / Date': 'Durata / Data',
            'No. of rooms': 'Numero di camere',
            Itenerary: 'Itinerario',
            'Per Night': 'Per notte',
            'Starting Date': 'Data di inizio',
            'Added to': 'Aggiunto a',
            Rooms: 'Camere',
            Subtotal: 'Subtotale',
            Discount: 'Sconto',
            'Tax / VAT': 'Tassa / IVA',
            Total: 'Totale',
            'Current Language': 'Lingua corrente',
            Help: 'Aiuto',
            'Create New': 'Crea nuovo',
            'Publish to the website': 'Pubblica sul sito web',
            'View full list': 'Visualizza lista completa',
            'Previews step': 'Passaggio precedente',
            'Save and go to next step': 'Salva e passa al passaggio successivo',
            'Waiting for Owner Approval': 'In attesa di approvazione del proprietario',
            'Immediately after approval, you will have full access to make reservations for your customers': 'Immediatamente dopo l\'approvazione, avrai pieno accesso per effettuare prenotazioni per i tuoi clienti',
            'You can increase your revenue by selling the B2B products': 'Puoi aumentare il tuo fatturato vendendo i prodotti B2B',
            'You can increase your revenue by selling the B2B products below': 'Puoi aumentare il tuo fatturato vendendo i prodotti B2B di seguito',
            'Make a request by collecting this product and be a worldwide vendor': 'Fai una richiesta raccogliendo questo prodotto e diventa un fornitore globale',
            'collect now': 'raccogli adesso',
            Category: 'Categoria',
            'Assign to': 'Assegna a',
            'Cabin type': 'Tipo di cabina',
            'Date range': 'Intervallo di date',
            Adult: 'Adulto',
            Child: 'Bambino',
            Infant: 'Infante',
            'Room type': 'Tipo di camera',
            'Start date': 'Data di inizio',
            'End date': 'Data di fine',
            'Check availability': 'Verifica disponibilità',
            'Number of cabins': 'Numero di cabine',
            'Book now': 'Prenota ora',
            child: 'bambino',
            infant: 'infante',
            'Available Cabins': 'Cabine disponibili',
            'No. of guests': 'Numero di ospiti',
            'Selected Cabins': 'Cabine selezionate',
            'Total Price': 'Prezzo totale',
            'Select this cabin for Reservation': 'Seleziona questa cabina per la prenotazione',
            'Extra Charges': 'Costi aggiuntivi',
            Amenities: 'Servizi',
            'View more': 'Visualizza di più',
            'Extra Beds': 'Letti aggiuntivi',
            'Number of Rooms': 'Numero di camere',
            'Number of Extra Beds': 'Numero di letti aggiuntivi',
            'Selected Rooms': 'Camere selezionate',
            'Select this room for Reservation': 'Seleziona questa camera per la prenotazione',
            'Sales Overview': 'Panoramica delle vendite',
            Question: 'Domanda',
            Answer: 'Risposta',
            Sharing: 'Condivisione',
            'Enter the commission for vendors': 'Inserisci la commissione per i fornitori',
            'Your Terms and Conditions': 'Le tue condizioni generali',
            'In order to have a fair B2B collaboration, please enter your terms and conditions that will be used as an agreement with your new partner': 'Per avere una collaborazione B2B equa, inserisci le tue condizioni generali che saranno utilizzate come accordo con il tuo nuovo partner',
            'Share now': 'Condividi ora',
            'Are you sure to delete this': 'Sei sicuro di voler eliminare questo',
            'Terms and Conditions': 'Termini e Condizioni',
            'Please accept the terms and conditions in order to have a fair partnership with the owner of this product': 'Si prega di accettare i termini e le condizioni al fine di avere una partnership equa con il proprietario di questo prodotto',
            'Send request': 'Invia richiesta',
            Website: 'Sito web',
            'Profile Management': 'Gestione profilo',
            'History Log': 'Registro storico',
            Security: 'Sicurezza',
            'Help Desk': 'Servizio di assistenza',
            'Log out': 'Esci',
            'Name of Service/ Activity': 'Nome del servizio/attività',
            'Add New Extra Service': 'Aggiungi nuovo servizio extra',
            'advanced filter': 'filtro avanzato',
            'Avg. Price': 'Prezzo medio',
            Shared: 'Condiviso',
            'Edit Details': 'Modifica dettagli',
            'Share to Worldwide B2B': 'Condividi su Worldwide B2B',
            'View details': 'Vedi dettagli',
            'Drag & Drop Photo Here': 'Trascina e rilascia la foto qui',
            or: 'o',
            Browse: 'Sfoglia',
            'You can upload up to 5 photos for the tour gallery': 'Puoi caricare fino a 5 foto per la galleria del tour',
            'Drag & Drop photos here': 'Trascina e rilascia le foto qui',
            'Resend OTP': 'Rinvia OTP',
            'Open Map': 'Apri mappa',
            'Position in Hotel': "Posizione nell'hotel",
            Webiste: 'Sito web',
            City: 'Città',
            'State/ Province': 'Stato/Provincia',
            'Zip Code': 'Codice postale',
            Country: 'Paese',
            'Add location to the map (Optional)': 'Aggiungi posizione sulla mappa (Opzionale)',
            'Map Latitude': 'Latitudine mappa',
            'Map Longitude': 'Longitudine mappa',
            'Check in Time': 'Orario di check-in',
            'Check out Time': 'Orario di check-out',
            'Payment Options (Optional)': 'Opzioni di pagamento (Opzionale)',
            Add: 'Aggiungi',
            'Add Extra Bed': 'Aggiungi letto aggiuntivo',
            'Price per Extra Bed': 'Prezzo per letto aggiuntivo',
            'Room Photo Gallery': 'Galleria fotografica della camera',
            Submit: 'Invia',
            'Bed Type for adult': 'Tipo di letto per adulto',
            'Bed Type for child': 'Tipo di letto per bambino',
            'Bed Type for infant': 'Tipo di letto per neonato',
            'Room Type Name': 'Nome tipo di camera',
            'Room Size': 'Dimensione camera',
            'Price per Night': 'Prezzo per notte',
            'Price per Person': 'Prezzo per persona',
            'My Commission': 'La mia commissione',
            'Add new room': 'Aggiungi nuova camera',
            'Add New Room Type': 'Aggiungi nuovo tipo di camera',
            'Season Name': 'Nome stagione',
            'Start Season': 'Inizio stagione',
            'End Season': 'Fine stagione',
            'Select the Room Types you want to assign this Season': 'Seleziona i tipi di camera che desideri assegnare a questa stagione',
            'Assign to all': 'Assegna a tutti',
            'Add new season': 'Aggiungi nuova stagione',
            'General Details': 'Dettagli generali',
            'Hotel Rates': 'Tariffe dell\'hotel',
            'Hotel Name': 'Nome dell\'hotel',
            'Address & Location': 'Indirizzo e posizione',
            Policy: 'Politica',
            'Payment Option': 'Opzione di pagamento',
            'Contact Person': 'Persona di contatto',
            'Photo Gallery': 'Galleria fotografica',
            'Facilities & Services': 'Strutture e servizi',
            Facilities: 'Strutture',
            Services: 'Servizi',
            'Seasonal Prices': 'Prezzi stagionali',
            Seasons: 'Stagioni',
            Room: 'Camera',
            Accomodation: 'Alloggio',
            'Contact Details': 'Dettagli di contatto',
            'Custom Offer': 'Offerta personalizzata',
            'Early Booker': 'Prenotazione anticipata',
            'Last Minute': 'Ultimo minuto',
            'Free Night': 'Notte gratuita',
            'Create new Offer': 'Crea nuova offerta',
            'Select a Promotion Type': 'Seleziona un tipo di promozione',
            'Promotion Name': 'Nome promozione',
            'Start Offer': 'Inizio offerta',
            'End Offer': 'Fine offerta',
            'Active Offers': 'Offerte attive',
            'Add new Offer': 'Aggiungi nuova offerta',
            'Promotions / Offers': 'Promozioni / Offerte',
            Policies: 'Politiche',
            'Payment Accept': 'Accettazione pagamento',
            'Looks like your accommodations list is empty': 'Sembra che il tuo elenco di alloggi sia vuoto',
            'You can create unlimited properties based on the following property types': 'Puoi creare proprietà illimitate in base ai seguenti tipi di proprietà',
            'Welcome to Hola Agency': 'Benvenuto in Hola Agency',
            'Need help': 'Hai bisogno di aiuto',
            'average commission': 'commissione media',
            'This is the commission that this Supplier offers to his Vendors': 'Questa è la commissione che questo Fornitore offre ai suoi Rivenditori',
            'B2B Role': 'Ruolo B2B',
            'Supplies for Tour and Accommodation': 'Forniture per Tour e Alloggi',
            'Countries Operation': 'Paesi di operazione',
            'Filter by Service Type': 'Filtra per tipo di servizio',
            'Filter by Service Location': 'Filtra per posizione del servizio',
            'Filter by Status': 'Filtra per stato',
            Pending: 'In sospeso',
            'Force Request': 'Richiesta forzata',
            Price: 'Prezzo',
            'Create Agency Account': 'Crea un account di agenzia',
            'All follows fields are required': 'Tutti i campi seguenti sono obbligatori',
            'Search for existing account': 'Cerca un account esistente',
            Password: 'Password',
            'Confirm Password': 'Conferma password',
            'I agree to the': 'Accetto i',
            'Terms of Service': 'Termini di servizio',
            'next step': 'passaggio successivo',
            'Create Agency Info': 'Crea informazioni agenzia',
            'Agency Name': "Nome dell'agenzia",
            'Register Number (VAT)': 'Numero di registrazione (IVA)',
            'Company Size': 'Dimensione aziendale',
            'Street Name': 'Nome della strada',
            'Zip / Postal Code': 'Codice postale',
            'State / Province': 'Stato / Provincia',
            Distribution: 'Distribuzione',
            'Choose products you want to share with': 'Scegli i prodotti che desideri condividere con',
            'Choose products you want to share with this partner': 'Scegli i prodotti che desideri condividere con questo partner',
            Products: 'Prodotti',
            Vendors: 'Rivenditori',
            Suppliers: 'Fornitori',
            'Add Vendor': 'Aggiungi Rivenditore',
            'Filter by Business Location': 'Filtra per Località Aziendale',
            'Filter by Country Operation': 'Filtra per Operazioni Paese',
            'Search with keyword (agency name, product name...)': 'Cerca con parola chiave (nome agenzia, nome prodotto...)',
            'vendor found': 'fornitore trovato',
            'vendors found': 'fornitori trovati',
            'supplier found': 'fornitore trovato',
            'suppliers found': 'fornitori trovati',
            'Distributor for Tour and Accommodation': 'Distributore per Tour e Alloggi',
            'Sales Revenue': 'Fatturato delle vendite',
            'my earning': 'i miei guadagni',
            'Are you sure to delete this customer': 'Sei sicuro di voler eliminare questo cliente',
            'Filter by Tour Location': 'Filtra per Località Tour',
            'requests found': 'richieste trovate',
            Recent: 'Recente',
            requests: 'richieste',
            'Click on the left item tabs to view full details of each': 'Fai clic sulle schede degli elementi a sinistra per visualizzare i dettagli completi di ciascuno',
            Approve: 'Approva',
            Approved: 'Approvato',
            views: 'visualizzazioni',
            All: 'Tutti',
            'My Collection': 'La mia Collezione',
            'tour found': 'tour trovato',
            'tours found': 'tour trovati',
            Since: 'Dal',
            'Personal No': 'Numero Personale',
            'Client Type': 'Tipo di Cliente',
            Delete: 'Elimina',
            'Export in XLS': 'Esporta in XLS',
            'Export in PDF': 'Esporta in PDF',
            'Select All': 'Seleziona Tutto',
            customers: 'clienti',
            'Registered Date': 'Data di Registrazione',
            'Add new Customer': 'Aggiungi nuovo Cliente',
            Individual: 'Individuale',
            Business: 'Business',
            'Company Info': 'Informazioni Azienda',
            'Edit this customer': 'Modifica questo cliente',
            'Delete this customer': 'Elimina questo cliente',
            Company: 'Azienda',
            Draft: 'Bozza',
            Partial: 'Parziale',
            'No discount': 'Nessuno sconto',
            'View full reports': 'Visualizza report completi',
            'Sales by Locations': 'Vendite per località',
            'Confirmed Orders': 'Ordini confermati',
            'Pending Orders': 'Ordini in sospeso',
            'Canceled Orders': 'Ordini annullati',
            'Total Customers': 'Clienti totali',
            'Total Incoming': 'Totale entrate',
            'Costs since Jan': 'Costi da Gennaio',
            'Earning since Jan': 'Guadagni da Gennaio',
            'This is a revenue reports for the last year starting from January up to current time': "Questo è un report di guadagno per l'ultimo anno a partire da gennaio fino all'ora corrente",
            'You can view full reports in the reports menu': 'È possibile visualizzare i report completi nel menu dei report',
            'The best Deals for you': 'Le migliori offerte per te',
            'Choose from B2B list the best deal for your partnership': "Scegli dall'elenco B2B l'offerta migliore per la tua partnership",
            'This email is going to be used for login account': "Questa email verrà utilizzata per l'account di accesso",
            'Account Details': "Dettagli dell'account",
            'Personal Details': 'Dettagli Personali',
            'Employed since': 'Impiegato dal',
            'Creating New Role': 'Creazione di un Nuovo Ruolo',
            'Title of Job Position / Role': 'Titolo della Posizione di Lavoro / Ruolo',
            'Job Position / Role': 'Posizione di Lavoro / Ruolo',
            'You can choose more than one job position for this employee': 'È possibile scegliere più di una posizione di lavoro per questo dipendente',
            'Add new Role': 'Aggiungi nuovo Ruolo',
            'Salary Information': 'Informazioni sullo Stipendio',
            'Salary amount': 'Importo dello stipendio',
            Monthly: 'Mensile',
            Yearly: 'Annuale',
            'Salary and Job Position': 'Stipendio e Posizione di Lavoro',
            'Are you sure to delete this employee': 'Sei sicuro di voler eliminare questo dipendente',
            Role: 'Ruolo',
            Salary: 'Stipendio',
            'No. of Sales': 'Numero di Vendite',
            'Total Revenue': 'Ricavo Totale',
            'Add Points/Review': 'Aggiungi Punti/Recensione',
            'Search by employee name': 'Cerca per nome dipendente',
            Roles: 'Ruoli',
            Name: 'Nome',
            'Add new Employee': 'Aggiungi nuovo Dipendente',
            'Add new Evaluation': 'Aggiungi nuova Valutazione',
            'Job Knowledge': 'Conoscenza del lavoro',
            Productivity: 'Produttività',
            Teamwork: 'Lavoro di squadra',
            'Communication Skills': 'Capacità di comunicazione',
            'Enter your opinion about this evaluation': 'Inserisci la tua opinione su questa valutazione',
            'Total scores': 'Punteggi totali',
            Points: 'Punti',
            Notes: 'Note',
            'Last Evaluation': 'Ultima Valutazione',
            'Filter by date': 'Filtra per data',
            month: 'mese',
            'Edit this employee': 'Modifica questo dipendente',
            'Delete this employee': 'Elimina questo dipendente',
            'Overall Performance': 'Prestazioni generali',
            'View full story': 'Visualizza storia completa',
            Age: 'Età',
            'Total sales': 'Vendite totali',
            'Since the beginning': "Dall'inizio",
            'Total amount': 'Importo totale',
            'This month': 'Questo mese',
            'Last 30 days': 'Ultimi 30 giorni',
            'Search action': 'Cerca azione',
            'Select the following action to give access/permissions to this type of role': "Seleziona l'azione seguente per concedere accesso/autorizzazioni a questo tipo di ruolo",
            'Roles/Job Positions': 'Ruoli/Posizioni di lavoro',
            Passengers: 'Passeggeri',
            'years old': ' anni',
            Direction: 'Direzione',
            'Type of Trip': 'Tipo di Viaggio',
            Date: 'Data',
            Time: 'Ora',
            'Time zone': 'Time zone',
            Departure: 'Partenza',
            Airline: 'Compagnia aerea',
            'stop to': 'sosta a',
            'Transit Airport': 'Aeroporto di Transito',
            Class: 'Classe',
            'Flight Number': 'Numero di Volo',
            Arrival: 'Arrivo',
            cm: 'cm',
            Return: 'Ritorno',
            'Ticket No.': 'Numero di biglietto',
            Origin: 'Origine',
            'Airport name': "Nome dell'aeroporto",
            Destination: 'Destinazione',
            'Transit Airline': 'Compagnia aerea di transito',
            'Arrival Date & Time': 'Data e ora di arrivo',
            'Departure Date & Time': 'Data e ora di partenza',
            'Date & Time': 'Data e ora',
            'Add transit': 'Aggiungi transito',
            'Extra Price': 'Prezzo extra',
            'One way': 'Solo andata',
            'Round Trip': 'Andata e Ritorno',
            'Confirm ticket': 'Conferma biglietto',
            'Flight Details': 'Dettagli del Volo',
            'Search passenger': 'Cerca passeggero',
            'Add new ticket': 'Aggiungi nuovo biglietto',
            'Are you sure to delete this flight': 'Sei sicuro di voler eliminare questo volo',
            'Invoice Ft': 'Fattura Ft',
            'Forgot your password': 'Hai dimenticato la password',
            'A new password will be send to your email address': 'Una nuova password verrà inviata al tuo indirizzo email',
            'Reset password': 'Reimposta password',
            'Create an account': 'Crea un account',
            'Create new password': 'Crea nuova password',
            'New Password': 'Nuova password',
            'Save password': 'Salva password',
            'Email verification': 'Verifica email',
            'Please enter the verification code sent to': 'Inserisci il codice di verifica inviato a',
            'Otp code needs to be 6 characters': 'Il codice OTP deve essere di 6 caratteri',
            'Sign in': 'Accedi',
            '25 Tours are available based on your search': 'Sono disponibili 25 tour in base alla tua ricerca',
            Domain: 'Dominio',
            'Availability search box': 'Casella di ricerca disponibilità',
            'Availability search box for': 'Casella di ricerca disponibilità per',
            'Search Results': 'Risultati di ricerca',
            'Script Search box': 'Casella di ricerca script',
            'Script Search Result Page': 'Pagina dei risultati di ricerca script',
            'Script Featured products by selected preview mode': 'Prodotti in primo piano dello script in base alla modalità di anteprima selezionata',
            'Whitelist domain': 'Dominio whitelist',
            'Booking engine': 'Motore di prenotazione',
            'Configure widgets for your website integration': "Configura i widget per l'integrazione nel tuo sito web",
            'Choose the type of widget for the Search Box': 'Scegli il tipo di widget per la casella di ricerca',
            'Select the services you want to integrate to the website': 'Seleziona i servizi che desideri integrare nel sito web',
            'Choose the preview mode on website': 'Scegli la modalità di anteprima sul sito web',
            'Generated Widgets': 'Widget generati',
            Flights: 'Voli',
            Tours: 'Tour',
            Transfers: 'Trasferimenti',
            'Search for an Existing Customer': 'Cerca un Cliente Esistente',
            'Invoice Details': 'Dettagli della Fattura',
            'Guests Information': 'Informazioni sugli ospiti',
            'Extra Discount': 'Sconto Extra',
            Service: 'Servizio',
            'Search for an Existing Service or create a new one': 'Cerca un servizio esistente o crea uno nuovo',
            'Enter a service or product name that is not listed in your database (ex. Flight Ticket for Tirana - Amsterdam)': 'Inserisci il nome di un servizio o prodotto non elencato nel tuo database (es. Biglietto aereo per Tirana - Amsterdam)',
            Description: 'Descrizione',
            'Select a date': 'Seleziona una data',
            'Add new Service': 'Aggiungi nuovo Servizio',
            'Save invoice': 'Salva fattura',
            'Billed For': 'Fatturato per',
            Extra: 'Extra',
            Overdue: 'Scaduto',
            'Are you sure to delete this invoice': 'Sei sicuro di voler eliminare questa fattura',
            'Sign in to your account': 'Accedi al tuo account',
            'Start managing your business in a few clicks': 'Inizia a gestire la tua attività con pochi clic',
            'Remember me': 'Ricordami',
            'Forgot password': 'Password dimenticata',
            'Street Address': 'Indirizzo',
            'Add agency details': "Aggiungi dettagli dell'agenzia",
            Register: 'Registrati',
            'I have an account': 'Ho un account',
            'Create an account and say Hola': 'Crea un account e saluta',
            'Select the features needed for your AGENCY management': "Seleziona le funzionalità necessarie per la gestione dell'AGENZIA",
            'Go back': 'Torna indietro',
            'Add selected services': 'Aggiungi servizi selezionati',
            'Create account': 'Crea account',
            'Email and Password will be used as login credential': 'Email e password verranno utilizzate come credenziali di accesso',
            'Select services': 'Seleziona servizi',
            'Add to your account our services for full managements': 'Aggiungi al tuo account i nostri servizi per una gestione completa',
            'Agency details': 'Dettagli agenzia',
            'Add your Agency information': 'Aggiungi informazioni sulla tua agenzia',
            'Verify your email': 'Verifica la tua email',
            'Phone no. verification': 'Verifica numero di telefono',
            'Edit your phone number': 'Modifica il tuo numero di telefono',
            'Verify your phone number': 'Verifica il tuo numero di telefono',
            Profits: 'Profitti',
            by: 'da',
            Export: 'Esporta',
            Send: 'Invia',
            'B2B Incoming': 'Entrate B2B',
            'Partnership Costs': 'Costi di Partnership',
            'Total Suppliers': 'Fornitori Totali',
            'Collected Services': 'Servizi Raccolti',
            'Revenue from Suppliers': 'Ricavi dai Fornitori',
            'average profit': 'Profitto medio',
            'Total Vendors': 'Venditori Totali',
            'shared services': 'servizi condivisi',
            'Sales by Vendors': 'Vendite per Venditori',
            'in win': 'in vincita',
            'in los': 'in perdita',
            'Select 2 different periods to compare the historic data between each other': 'Seleziona 2 periodi diversi per confrontare i dati storici tra di loro',
            Incoming: 'Entrate',
            Costs: 'Costi',
            Earned: 'Guadagnato',
            'Registered Customers': 'Clienti registrati',
            Results: 'Risultati',
            'Your revenue is': 'Il tuo fatturato è',
            'To improve your revenue we suggest following steps below': 'Per migliorare il tuo fatturato ti suggeriamo i seguenti passaggi',
            'Use the Marketing Tools': 'Utilizza gli strumenti di marketing',
            'Increase the B2B Partners': 'Aumenta i partner B2B',
            'Stats From': 'Statistiche Da',
            To: 'A',
            'All Services': 'Tutti i servizi',
            'All Type of Partners': 'Tutti i tipi di partner',
            Vendor: 'Fornitore',
            Supplier: 'Distributore',
            'Customers Origin / Country': 'Origine / Paese dei clienti',
            'Partners Origin / Country': 'Origine / Paese dei partner',
            Compare: 'Confronta',
            'Send to': 'Invia a',
            'Drag & Drop your': 'Trascina e rilascia il tuo',
            Logo: 'Logo',
            here: 'qui',
            'Agency / Company Name': "Nome dell'agenzia / dell'azienda",
            'Registration Number (VAT)': 'Numero di registrazione (IVA)',
            'Company Phone': 'Telefono aziendale',
            'Company Email': 'Email aziendale',
            Timezone: 'Fuso orario',
            Currency: 'Valuta',
            'Edit Tax': 'Modifica Tassa',
            'Add New Tax': 'Aggiungi Nuova Tassa',
            'Tax Name': 'Nome Tassa',
            'Tax Rate': 'Aliquota Fiscale',
            'Tax Type': 'Tipo di Tassa',
            Percentage: 'Percentuale',
            'Fixed Amount': 'Importo Fisso',
            'Refundable Tax': 'Tassa Rimborsabile',
            'Are you sure to delete this tax': 'Sei sicuro di voler eliminare questa tassa',
            'Activity Name': 'Nome Attività',
            'Default Price': 'Prezzo Predefinito',
            Availability: 'Disponibilità',
            'Activity Description': 'Descrizione Attività',
            'Enter the full address': "Inserisci l'indirizzo completo",
            'Extra Services': 'Servizi Extra',
            'My Own Product': 'Il Mio Prodotto',
            'Search for an Existing Partner': 'Cerca un Partner Esistente',
            '- or create new one below -': '- o crea uno nuovo qui sotto -',
            'Time & Duration': 'Orario e Durata',
            Partner: 'Partner',
            'Start time': 'Orario di Inizio',
            'or Default Price': 'o Prezzo Predefinito',
            day: 'giorno',
            days: 'giorni',
            'Skip this step': 'Salta questo passaggio',
            'Duration in minutes / hours': 'Durata in minuti / ore',
            'Duration in days': 'Durata in giorni',
            minutes: 'minuti',
            hour: 'ora',
            hours: 'ore',
            'add new timeslot': 'aggiungi nuovo intervallo di tempo',
            'How many times in day': 'Quante volte al giorno',
            times: 'volte',
            'Delete this timeslot': 'Elimina questo intervallo di tempo',
            min: 'min',
            h: 'h',
            'Edit this activity': 'Modifica questa attività',
            'Delete this activity': 'Elimina questa attività',
            'Remove this shared activity': 'Rimuovi questa attività condivisa',
            'Remove this shared collected activity': 'Rimuovi questa attività raccolta condivisa',
            'Make a Reservation to this Activity': 'Effettua una Prenotazione per questa Attività',
            'You can select more than one times above, depended by dates': 'Puoi selezionare più volte sopra, in base alle date',
            'Add new reservation for this activity': 'Aggiungi una nuova prenotazione per questa attività',
            'add new item': 'aggiungi nuovo elemento',
            Title: 'Titolo',
            'Arrival time': 'Orario di Arrivo',
            'Departure time': 'Orario di Partenza',
            'Same Port for Departure': 'Stesso Porto per la Partenza',
            'You can add a location by clicking over the map': 'Puoi aggiungere una posizione cliccando sulla mappa',
            'delete item': 'elimina elemento',
            'Cruise Name': 'Nome della Crociera',
            'Cruise Style': 'Stile della Crociera',
            'Start Date & Time': 'Data e Ora di Inizio',
            'End Date & Time': 'Data e Ora di Fine',
            'Departure Port / Location': 'Porto di Partenza / Località',
            'Arrival Port / Location': 'Porto di Arrivo / Località',
            'Cruise Description': 'Descrizione della Crociera',
            'Per person': 'Per persona',
            'Per cabin': 'Per cabina',
            'Avg. Price per Night': 'Prezzo Medio per Notte',
            'Add new cabin type': 'Aggiungi nuovo tipo di cabina',
            'No. of Adults': 'Numero di Adulti',
            'Add Children or Infants': 'Aggiungi Bambini o Neonati',
            'Max. Adults': 'Max. Adulti',
            'Max. Children': 'Max. Bambini',
            'Max. Infants': 'Max. Neonati',
            'Delete cabin type': 'Elimina tipo di cabina',
            'Add new ship': 'Aggiungi nuova nave',
            'Search for Ship': 'Cerca Nave',
            'Select one of the Ships below for this Cruise Tour or': 'Seleziona una delle Navi di seguito per questo Tour in Crociera o',
            'Cabin Types': 'Tipi di Cabina',
            "I don't have an account": 'Non ho un account',
            'since last month': 'da ultimo mese',
            'Edit this ship': 'Modifica questa nave',
            'Delete this ship': 'Elimina questa nave',
            'Cruise Itenerary': 'Itinerario di Crociera',
            'Cruise Type': 'Tipo di Crociera',
            'Extra Service': 'Servizio Extra',
            inclusive: 'incluso',
            'Inclusions / Exclusions': 'Inclusioni / Esclusioni',
            Inclusions: 'Inclusioni',
            Exclusions: 'Esclusioni',
            'Ship Details': 'Dettagli della Nave',
            'Incl. / Excl.': 'Incl. / Escl.',
            'Edit this cruise': 'Modifica questa crociera',
            'Delete this cruise': 'Elimina questa crociera',
            'Remove this shared cruise': 'Rimuovi questa crociera condivisa',
            'Remove this shared collected cruise': 'Rimuovi questa crociera raccolta condivisa',
            'Tour Type': 'Tipo di Tour',
            'Looks you have empty tour categories': 'Sembra che tu abbia categorie di tour vuote',
            'You can create unlimited tours based on follows categories': 'Puoi creare tour illimitati basati sulle seguenti categorie',
            'Add new hotel': 'Aggiungi nuovo hotel',
            'Add new resort': 'Aggiungi nuovo resort',
            'Add new motel': 'Aggiungi nuovo motel',
            'Add new hostel': 'Aggiungi nuovo ostello',
            'View hotels': 'Visualizza hotel',
            'View resorts': 'Visualizza resort',
            'View motels': 'Visualizza motel',
            'View hostels': 'Visualizza ostelli',
            'Detailed trips for Individual and Group participants, including full itinerary program based on interactive map.': "Viaggi dettagliati per partecipanti individuali e di gruppo, incluso un programma completo dell'itinerario basato su una mappa interattiva.",
            'Search for Hotel, Villa, Guesthouse': 'Cerca Hotel, Villa, Ospite',
            'Select one of the Accommodation or': 'Seleziona una delle sistemazioni o',
            'Add New Accommodation': 'Aggiungi Nuova Sistemazione',
            'Room Types': 'Tipi di Camera',
            'Number of Rooms Available': 'Numero di Camere Disponibili',
            'Edit this hotel': 'Modifica questo hotel',
            'Delete this hotel': 'Elimina questo hotel',
            'Package Description': 'Descrizione del Pacchetto',
            'Package Name': 'Nome del Pacchetto',
            'Package Style': 'Stile del Pacchetto',
            'Per adult': 'Per adulto',
            'Per group': 'Per gruppo',
            'Tour Style': 'Stile del Tour',
            'Default Prices': 'Prezzi Predefiniti',
            'Extra Prices': 'Prezzi Extra',
            'Tour Gallery': 'Galleria del Tour',
            'Edit this package': 'Modifica questo pacchetto',
            'Delete this package': 'Elimina questo pacchetto',
            'Remove this shared package': 'Rimuovi questo pacchetto condiviso',
            'Remove this shared collected package': 'Rimuovi questo pacchetto raccolto condiviso',
            'Regular or charter depending on departures': 'Regolare o charter a seconda delle partenze',
            'Tour Description': 'Descrizione del Tour',
            'Tour Name': 'Nome del Tour',
            Fixed: 'Fisso',
            Rules: 'Regole',
            'Adult Price': 'Prezzo Adulti',
            Popular: 'Popolare',
            'add new rule': 'Aggiungi nuova regola',
            'Rule Type': 'Tipo di Regola',
            'Less than': 'Meno di',
            Range: 'Intervallo',
            'Greater than': 'Maggiore di',
            'Group size and Prices': 'Dimensione del Gruppo e Prezzi',
            'Tour Itenerary': 'Itinerario del Tour',
            'Add new group': 'Aggiungi nuovo gruppo',
            'This group has completed with number of participants': 'Questo gruppo è completato con un numero di partecipanti',
            'Are you sure to delete this group': 'Sei sicuro di voler eliminare questo gruppo',
            'Group Name': 'Nome del Gruppo',
            'Avg. Age': 'Età Media',
            'Add reservation': 'Aggiungi prenotazione',
            'Full Name': 'Nome Completo',
            'Edit this trip': 'Modifica questo viaggio',
            'Delete this trip': 'Elimina questo viaggio',
            'Remove this shared trip': 'Rimuovi questo viaggio condiviso',
            'Remove this shared collected trip': 'Rimuovi questo viaggio raccolto condiviso',
            Style: 'Stile',
            Hotel: 'Hotel',
            Resort: 'Resort',
            Motel: 'Motel',
            Hostel: 'Ostello',
            'Tour Packages': 'Pacchetti Tour',
            'Cruise Tour': 'Tour in Crociera',
            Activities: 'Attività',
            'Add new tour': 'Aggiungi nuovo tour',
            'Add new activity': 'Aggiungi nuova attività',
            'View tours': 'Visualizza tour',
            'View activities': 'Visualizza attività',
            'Something amazing is in the works! Check back soon for exciting new features, or get in touch with our team to learn more': 'Qualcosa di incredibile è in fase di sviluppo! Torna presto per nuove funzionalità interessanti o mettiti in contatto con il nostro team per saperne di più',
            'Check in': 'Check in',
            'Check out': 'Check out',
            "This option will generate more than one search boxes based on the services you'll select": 'Questa opzione genererà più di una casella di ricerca in base ai servizi che selezionerai',
            'Put all services into one search box': 'Metti tutti i servizi in una casella di ricerca',
            'Split the services into separated search boxes': 'Dividi i servizi in caselle di ricerca separate',
            'Domain whitelist': 'Lista di dominio consentiti',
            'Roles / Job Positions': 'Ruoli / Posizioni Lavorative',
            Customers: 'Clienti',
            "Make requests for the products you'll choose and be a worldwide vendor": 'Fai richieste per i prodotti che sceglierai e diventa un fornitore globale',
            'Worldwide Supplier': 'Fornitore Globale',
            'My Partners': 'I miei Partner',
            Requests: 'Richieste',
            'In-house Stats': 'Statistiche Interne',
            'B2B Stats': 'Statistiche B2B',
            'Booking Engine': 'Motore di Prenotazione',
            'Channel Manager': 'Gestore Canali',
            Marketing: 'Marketing',
            Taxes: 'Tasse',
            Billing: 'Fatturazione',
            General: 'Generale',
            'Add new tax': 'Aggiungi nuova tassa',
            Items: 'Elementi',
            Period: 'Periodo',
            Created: 'Creato',
            'Product /Service': 'Prodotto / Servizio',
            Actions: 'Azioni',
            'Customer origin': 'Origine del cliente',
            'Partner type': 'Tipo di partner',
            'Order date': 'Data ordine',
            'Agency Info': 'Informazioni Agenzia',
            'Requested by': 'Richiesto da',
            'Requested time': 'Orario richiesto',
            sales: 'vendite',
            'Add new Hotel': 'Aggiungi nuovo Hotel',
            'Add new Resort': 'Aggiungi nuovo Resort',
            'Add new Motel': 'Aggiungi nuovo Motel',
            'Add new Hostel': 'Aggiungi nuovo Ostello',
            hotel: 'hotel',
            hotels: 'alberghi',
            resort: 'resort',
            resorts: 'resort',
            motel: 'motel',
            motels: 'motel',
            hostel: 'ostello',
            hostels: 'ostelli',
            'Hotel Details': 'Dettagli Hotel',
            'Resort Details': 'Dettagli Resort',
            'Motel Details': 'Dettagli Motel',
            'Hostel Details': 'Dettagli Ostello',
            'Hotel Facilities & Services': 'Servizi e strutture dell hotel',
            'Resort Facilities & Services': 'Servizi e strutture del resort',
            'Motel Facilities & Services': 'Servizi e strutture del motel',
            'Hostel Facilities & Services': 'Servizi e strutture dell ostello',
            'Resort Rates': 'Tariffe del resort',
            'Motel Rates': 'Tariffe del motel',
            'Hostel Rates': 'Tariffe dell ostello',
            'In order to have a fair B2B collaboration please enter your terms and conditions that will be use as an agreement to your new partner': 'Per avere una collaborazione B2B equa, inserisci i tuoi termini e condizioni che verranno utilizzati come accordo con il tuo nuovo partner',
            Reservation: 'Prenotazione',
            'Accommodation Details': 'Dettagli della sistemazione',
            'Put your Hotel rate (Optional)': 'Inserisci la tariffa dell hotel (opzionale)',
            'Put your Resort rate (Optional)': 'Inserisci la tariffa del resort (opzionale)',
            'Put your Motel rate (Optional)': 'Inserisci la tariffa del motel (opzionale)',
            'Put your Hostel rate (Optional)': 'Inserisci la tariffa dell ostello (opzionale)',
            'Add New Payment Option': 'Aggiungi Nuova Opzione di Pagamento',
            'Hotel Photo Gallery': 'Galleria Fotografica dell Hotel',
            'Resort Photo Gallery': 'Galleria Fotografica del Resort',
            'Motel Photo Gallery': 'Galleria Fotografica del Motel',
            'Hostel Photo Gallery': 'Galleria Fotografica dell Ostello',
            'Resort Name': 'Nome del Resort',
            'Motel Name': 'Nome del Motel',
            'Hostel Name': 'Nome dell Ostello',
            'Hotel Description': 'Descrizione dell Hotel',
            'Resort Description': 'Descrizione del Resort',
            'Motel Description': 'Descrizione del Motel',
            'Hostel Description': 'Descrizione dell Ostello',
            'Add New Facility': 'Aggiungi Nuova Struttura',
            'The price is inclusive to the tour': 'Il prezzo è incluso nel tour',
            "Oops! Looks you haven't created yet any Hotel": 'Oops! Sembra che tu non abbia ancora creato alcun Hotel',
            "Oops! Looks you haven't created yet any Resort": 'Oops! Sembra che tu non abbia ancora creato alcun Resort',
            "Oops! Looks you haven't created yet any Motel": 'Oops! Sembra che tu non abbia ancora creato alcun Motel',
            "Oops! Looks you haven't created yet any Hostel": 'Oops! Sembra che tu non abbia ancora creato alcun Ostello',
            'Start creating Hotel': 'Inizia a creare Hotel',
            'Start creating Resort': 'Inizia a creare Resort',
            'Start creating Motel': 'Inizia a creare Motel',
            'Start creating Hostel': 'Inizia a creare Ostello',
            "Oops! Looks you haven't created yet any Room": 'Oops! Sembra che tu non abbia ancora creato alcuna Camera',
            "Oops! Looks you haven't created yet any Season": 'Oops! Sembra che tu non abbia ancora creato alcuna Stagione',
            'View this hotel': 'Visualizza questo hotel',
            'View this resort': 'Visualizza questo resort',
            'View this motel': 'Visualizza questo motel',
            'View this hostel': 'Visualizza questo ostello',
            "Oops! Looks you haven't created yet any Tour Trip": 'Oops! Sembra che tu non abbia ancora creato nessun Viaggio Turistico',
            'Start creating tour trip': 'Inizia a creare un viaggio turistico',
            'Tour name': 'Nome del tour',
            'Group size': 'Dimensione del gruppo',
            'View all Tours': 'Visualizza tutti i Tour',
            'Tour Info': 'Informazioni sul Tour',
            "Oops! Looks you haven't created yet any Group": 'Oops! Sembra che tu non abbia ancora creato alcun Gruppo',
            'Starting date': 'Data di inizio',
            'Start Date': 'Data di Inizio',
            'Incl. /Excl.': 'Incl. /Escl.',
            'Add New Inclusion Item': 'Aggiungi Nuovo Elemento di Inclusione',
            'Add New Exclusion Item': 'Aggiungi Nuovo Elemento di Esclusione',
            'View this tour': 'Visualizza questo tour',
            "Oops! Looks you haven't created yet any Tour Packages": 'Oops! Sembra che tu non abbia ancora creato alcun Pacchetto Turistico',
            'Start creating tour packages': 'Inizia a creare pacchetti turistici',
            'Search for Package': 'Cerca Pacchetto',
            'Hotel, Location': 'Hotel, Località',
            'View all Package Tours': 'Visualizza tutti i Pacchetti Turistici',
            'View this package': 'Visualizza questo pacchetto',
            'Search for Activity name': 'Cerca per nome Attività',
            'Date Range': 'Intervallo di Date',
            "Oops! Looks you haven't created yet any Activities": 'Oops! Sembra che tu non abbia ancora creato alcuna Attività',
            'Start creating activity': 'Inizia a creare attività',
            'View all Activities': 'Visualizza tutte le Attività',
            When: 'Quando',
            'The price is inclusive to the activity': 'Il prezzo è incluso nell attività',
            "Partner's Product": 'Prodotto del Partner',
            'View this activity': 'Visualizza questa attività',
            "Oops! Looks you haven't created yet any Cruise Tour": 'Oops! Sembra che tu non abbia ancora creato alcun Tour in Crociera',
            'Start creating cruise tour': 'Inizia a creare un tour in crociera',
            'Search for Cruise Tour': 'Cerca Tour in Crociera',
            'Available cabins': 'Cabine disponibili',
            'The price is inclusive to the cruise': 'Il prezzo è incluso nella crociera',
            'End Date': 'Data di Fine',
            'View this cruise': 'Visualizza questa crociera',
            'View all Cruise Tours': 'Visualizza tutti i Tour in Crociera',
            'Add New Ship': 'Aggiungi Nuova Nave',
            'Number of Cabins': 'Numero di Cabine',
            'Cabin Type': 'Tipo di Cabina',
            'Choose cabin and  start/end date before reservation': 'Scegli la cabina e la data di inizio/fine prima della prenotazione',
            'Marketing Tools': 'Strumenti di Marketing',
            Dashboard: 'Dashboard',
            Accommodations: 'Sistemazioni',
            'B2B Partners': 'Partner B2B',
            'Stats. & Reports': 'Statistiche e Report',
            Integrations: 'Integrazioni',
            "First Name can't be empty": 'Il nome non può essere vuoto',
            'First Name has to be at least 3 characters': 'Il nome deve essere di almeno 3 caratteri',
            'First Name cannot be more than 30 characters': 'Il nome non può contenere più di 30 caratteri',
            "Last Name can't be empty": 'Il cognome non può essere vuoto',
            'Last Name has to be at least 3 characters': 'Il cognome deve essere di almeno 3 caratteri',
            'Last Name cannot be more than 30 characters': 'Il cognome non può contenere più di 30 caratteri',
            "Id can't be empty": 'L id non può essere vuoto',
            "Nationality can't be empty": 'La nazionalità non può essere vuota',
            "Phone can't be empty": 'Il telefono non può essere vuoto',
            'Phone has to be at least 8 characters': 'Il telefono deve essere di almeno 8 caratteri',
            'Phone cannot be more than 12 characters': 'Il telefono non può contenere più di 12 caratteri',
            "Prefix can't be empty": 'Il prefisso non può essere vuoto',
            "Email can't be empty": 'L email non può essere vuota',
            'Email has to be at least 2 characters': 'L email deve essere di almeno 2 caratteri',
            'Email cannot be more than 150 characters': 'L email non può contenere più di 150 caratteri',
            "Password can't be empty": 'La password non può essere vuota',
            'Password has to be at least 6 characters': 'La password deve essere di almeno 6 caratteri',
            'Password cannot be more than 40 characters': 'La password non può contenere più di 40 caratteri',
            "Confirm password can't be empty": 'La password di conferma non può essere vuota',
            'Confirm Password has to be at least 6 characters': 'La Password di Conferma deve essere di almeno 6 caratteri',
            'Confirm Password cannot be more than 40 characters': 'La Password di Conferma non può contenere più di 40 caratteri',
            "Gender can't be empty": 'Il genere non può essere vuoto',
            "Birthdate can't be empty": 'La data di nascita non può essere vuota',
            "Address can't be empty": "L'indirizzo non può essere vuoto",
            'Address has to be at least 5 characters': "L'indirizzo deve essere di almeno 5 caratteri",
            'Address cannot be more than 150 characters': "L'indirizzo non può contenere più di 150 caratteri",
            "Stars can't be empty": 'Le stelle non possono essere vuote',
            "Description can't be empty": 'La descrizione non può essere vuota',
            'Description has to be at least 5 characters': 'La descrizione deve essere di almeno 5 caratteri',
            'Description cannot be more than 300 characters': 'La descrizione non può contenere più di 300 caratteri',
            "City can't be empty": 'La città non può essere vuota',
            'City has to be at least 3 characters': 'La città deve essere di almeno 3 caratteri',
            'City cannot be more than 20 characters': 'La città non può contenere più di 20 caratteri',
            "State/Province can't be empty": 'Stato/Provincia non può essere vuoto',
            'State/Province has to be at least 3 characters': 'Lo stato/provincia deve essere di almeno 3 caratteri',
            'State/Province cannot be more than 20 characters': 'Lo stato/provincia non può contenere più di 20 caratteri',
            "Zip code can't be empty": 'Il codice postale non può essere vuoto',
            "Country can't be empty": 'Il paese non può essere vuoto',
            "Check in time can't be empty": 'L orario di check-in non può essere vuoto',
            "Check out time can't be empty": 'L orario di check-out non può essere vuoto',
            "Payment methods can't be empty": 'I metodi di pagamento non possono essere vuoti',
            "Position can't be empty": 'La posizione non può essere vuota',
            "Website can't be empty": 'Il sito web non può essere vuoto',
            'Website has to be at least 8 characters': 'Il sito web deve essere di almeno 8 caratteri',
            'Website cannot be more than 50 characters': 'Il sito web non può contenere più di 50 caratteri',
            "Offer type can't be empty": 'Il tipo di offerta non può essere vuoto',
            "Offer name can't be empty": 'Il nome dell offerta non può essere vuoto',
            "Discount can't be empty": 'Lo sconto non può essere vuoto',
            "Discount type can't be empty": 'Il tipo di sconto non può essere vuoto',
            "Start date can't be empty": 'La data di inizio non può essere vuota',
            "End date can't be empty": 'La data di fine non può essere vuota',
            "Room types can't be empty": 'I tipi di camera non possono essere vuoti',
            'Confirm password needs to be the same as password': 'La password di conferma deve essere la stessa della password',
            "Terms of service can't be empty": 'I Termini di servizio non possono essere vuoti',
            "Company Name can't be empty": "Il Nome dell'Azienda non può essere vuoto",
            'Company Name has to be at least 3 characters': "Il Nome dell'Azienda deve essere di almeno 3 caratteri",
            'Company Name cannot be more than 30 characters': "Il Nome dell'Azienda non può contenere più di 30 caratteri",
            "Register Number (VAT) can't be empty": 'Il Numero di Registrazione (IVA) non può essere vuoto',
            'Register Number (VAT) has to be at least 3 characters': 'Il Numero di Registrazione (IVA) deve essere di almeno 3 caratteri',
            'Register Number (VAT) cannot be more than 30 characters': 'Il Numero di Registrazione (IVA) non può contenere più di 30 caratteri',
            "Company Size can't be empty": 'La Dimensione dell Azienda non può essere vuota',
            "Street Address can't be empty": "L'Indirizzo non può essere vuoto",
            'Street Address has to be at least 3 characters': "L'Indirizzo deve essere di almeno 3 caratteri",
            'Street Address cannot be more than 50 characters': "L'Indirizzo non può contenere più di 50 caratteri",
            "Date employed can't be empty": 'La Data di Assunzione non può essere vuota',
            "Phone number can't be empty": 'Il Numero di Telefono non può essere vuoto',
            "Image can't be empty": 'L Immagine non può essere vuota',
            'VAT Number can be empty': 'Il Numero di Partita IVA può essere vuoto',
            "Timezone can't be empty": 'Il Fuso Orario non può essere vuoto',
            "Currency can't be empty": 'La Valuta non può essere vuota',
            "Agency logo can't be empty": 'Il Logo dell Agenzia non può essere vuoto',
            "Name can't be empty": 'Il Nome non può essere vuoto',
            'Name has to be at least 5 characters': 'Il Nome dell Attività deve essere di almeno 5 caratteri',
            'Name cannot be more than 150 characters': 'Il Nome dell Attività non può contenere più di 150 caratteri',
            "Default Price can't be empty": 'Il Prezzo Predefinito non può essere vuoto',
            "Availability can't be empty": 'La Disponibilità non può essere vuota',
            "Latitude can't be empty": 'La Latitudine non può essere vuota',
            "Longitude can't be empty": 'La Longitudine non può essere vuota',
            "Extra service name can't be empty": 'Il Nome del Servizio Extra non può essere vuoto',
            "Duration can't be empty": 'La Durata non può essere vuota',
            "Tour style can't be empty": 'Lo Stile del Tour non può essere vuoto',
            "Adults number can't be empty": 'Il Numero di Adulti non può essere vuoto',
            "Adults price can't be empty": 'Il Prezzo per Adulti non può essere vuoto',
            "Title can't be empty": 'Il Titolo non può essere vuoto',
            'Title has to be at least 5 characters': 'Il Titolo deve essere di almeno 5 caratteri',
            'Title cannot be more than 150 characters': 'Il Titolo non può contenere più di 150 caratteri',
            "Location can't be empty": 'La Località non può essere vuota',
            'Location has to be at least 5 characters': 'La Località deve essere di almeno 5 caratteri',
            'Location cannot be more than 150 characters': 'La Località non può contenere più di 150 caratteri',
            "Package style can't be empty": 'Lo Stile del Pacchetto non può essere vuoto',
            "Arrival time can't be empty": 'L Orario di Arrivo non può essere vuoto',
            "Departure time can't be empty": 'L Orario di Partenza non può essere vuoto',
            "Arrival port can't be empty": 'Il Porto di Arrivo non può essere vuoto',
            "Departure port can't be empty": 'Il Porto di Partenza non può essere vuoto',
            "Cruise line can't be empty": 'La Compagnia di Crociera non può essere vuota',
            "Cruise style can't be empty": 'Lo Stile della Crociera non può essere vuoto',
            "Start date & time can't be empty": 'La Data e l Orario di Inizio non possono essere vuoti',
            "End date & time can't be empty": 'La Data e l Orario di Fine non possono essere vuoti',
            "Price can't be empty": 'Il Prezzo non può essere vuoto',
            'Duration in min/hours': 'Durata in min/ore',
            time: 'ora',
            'No. Reservation': 'N. Prenotazione',
            "Service name can't be empty": 'Il Nome del Servizio non può essere vuoto',
            "Date can't be empty": 'La Data non può essere vuota',
            'Description cannot be more than 1000 characters': 'La Descrizione non può contenere più di 1000 caratteri',
            "Commission can't be empty": 'La Commissione non può essere vuota',
            "Commission type can't be empty": 'Il Tipo di Commissione non può essere vuoto',
            'This email will be used for logging in': 'Questa email verrà utilizzata per l accesso',
            "Cost can't be empty": 'Il Costo non può essere vuoto',
            'Change Password': 'Cambia Password',
            'Confirm New Password needs to be the same as New Password': 'La Password di Conferma deve essere la stessa della Nuova Password',
            "The password shouldn't be less than 8 character, including at least a cap letter, a symbol and a number": 'La password non deve contenere meno di 8 caratteri, inclusa almeno una lettera maiuscola, un simbolo e un numero',
            Cost: 'Costo',
            'Cruise style cannot be empty': 'Lo Stile della Crociera non può essere vuoto',
            'Package style cannot be empty': 'Lo Stile del Pacchetto non può essere vuoto',
            'Tour style cannot be empty': 'Lo Stile del Tour non può essere vuoto',
            'The request has been sent successfully': 'La richiesta è stata inviata con successo',
            'The offer has been added successfully': 'L offerta è stata aggiunta con successo',
            'Domain added successfully': 'Dominio aggiunto con successo',
            'Domain deleted successfully': 'Dominio eliminato con successo',
            'Group added successfully': 'Gruppo aggiunto con successo',
            'Group edited successfully': 'Gruppo modificato con successo',
            'Group deleted successfully': 'Gruppo eliminato con successo',
            'hotel deleted successfully': 'hotel eliminato con successo',
            'resort deleted successfully': 'resort eliminato con successo',
            'motel deleted successfully': 'motel eliminato con successo',
            'hostel deleted successfully': 'ostello eliminato con successo',
            'trip deleted successfully': 'viaggio eliminato con successo',
            'activity deleted successfully': 'attività eliminata con successo',
            'cruise deleted successfully': 'crociera eliminata con successo',
            'package deleted successfully': 'pacchetto eliminato con successo',
            'invoice deleted successfully': 'fattura eliminata con successo',
            'customer deleted successfully': 'cliente eliminato con successo',
            'employee deleted successfully': 'dipendente eliminato con successo',
            'partner deleted successfully': 'partner eliminato con successo',
            'Agency details updated successfully': 'Dettagli dell Agenzia aggiornati con successo',
            'Tax details saved successfully': 'Dettagli della tassa salvati con successo',
            'tax deleted successfully': 'tassa eliminata con successo',
            'Your password has been reset successfully': 'La tua password è stata reimpostata con successo',
            'Back to sign in': 'Torna al login',
            'Widget Category': 'Categoria Widget',
            'OTP code resent successfully': 'Codice OTP inviato nuovamente con successo',
            'OTP code sent successfully': 'Codice OTP inviato con successo',
            'Search for an Existing Vendor': 'Cerca un Fornitore Esistente',
            Optional: 'Opzionale',
            'Invoice status changed successfully': 'Stato della fattura modificato con successo',
            'Change status': 'Cambia stato',
            'Price per Night BB': 'Price per Night BB',
            'Price per Night HB': 'Price per Night HB',
            'Price per Night AI': 'Price per Night AI',
            'Price per Person BB': 'Price per Person BB',
            'Price per Person HB': 'Price per Person HB',
            'Price per Person AI': 'Price per Person AI',
            'Your choice': 'La tua scelta',
            'No available rooms': 'Nessuna camera disponibile',
            'No available cabins': 'Nessuna cabina disponibile',
            'Choose a price type, room and start/end date before reservation': 'Scegli un tipo di prezzo, una camera e una data di inizio/fine prima della prenotazione',
            Confirmed: 'Confermato',
            'Not Collected': 'Non Raccolto',
            'A-Z': 'A-Z',
            'Z-A': 'Z-A',
            'This service was canceled in the last request': 'Questo servizio è stato annullato nell ultima richiesta',
            'From Age': 'Dall Età',
            'To Age': 'All Età',
            'Child Price Policy': 'Politica Prezzo Bambino',
            'add child policy': 'Aggiungi politica bambino',
            'add policy': 'Add policy',
            'remove child policy': 'Remove child policy',
            'No available extra beds': 'Nessun letto aggiuntivo disponibile',
            'year old': 'anno vecchio',
            'Charges Type': 'Tipo di Spese',
        },
    },
};
