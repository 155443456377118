import { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Divider,
    Space,
    Pagination,
    Popconfirm,
    Avatar,
} from 'antd';
import BlockDescription from '@/components/Information/WithImage/Block';
import InfoBlock from '@/components/Information/WithoutImage/Block';
import { DeleteIcon, EditIcon, EyeIcon } from '@/assets/images/icons/screens';
import ActionIcon from '@/components/ActionIcon';
import { actions } from '@/redux/b2b/partner/reducer';
import { useCurrency } from '@/hooks/useCurrency';
import useNotification from '@/hooks/useNotification';
import Filters from './Filters';
import ViewVendor from './ViewVendor';

const initialFilters = {
    type: 0,
};

const MyPartners = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const [addModalVisible, setAddModalVisible] = useState(false);
    const [viewModalVisible, setViewModalVisible] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [partnerId, setPartnerId] = useState(null);
    const [isSupplier, setIsSupplier] = useState(false);
    const [filters, baseSetFilters] = useState(initialFilters);

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [partnerName, setPartnerName] = useState('');

    const partners = useSelector((state) => state.b2b.partner.data);
    const totalItems = useSelector((state) => state.b2b.partner.totalItems);
    const loading = useSelector((state) => state.b2b.partner.fetchingLoading);
    const messageSuccess = useSelector((state) => state.b2b.partner.delete.messageSuccess);
    const error = useSelector((state) => state.b2b.partner.delete.error);

    const setFilters = useCallback((name, value) => {
        baseSetFilters((prev) => ({ ...prev, [name]: value }));
    }, [baseSetFilters]);

    useEffect(() => {
        const data = {
            ...filters,
            type: filters.type ? 1 : 0, // 0 - vendor, 1 - supplier
            country: filters.country?.value,
            page: currentPage,
            perPage: itemsPerPage,
        };

        if (data.type || data.location || data.name) {
            const timer = setTimeout(() => {
                dispatch(actions.requestFetchPartnerList(data));
            }, 500);
            return () => clearTimeout(timer);
        } else {
            dispatch(actions.requestFetchPartnerList(data));
        }
        return () => {};
    }, [dispatch, filters, currentPage, itemsPerPage]);

    const viewPartner = useCallback((id) => {
        dispatch(actions.requestViewPartner(id));
        setViewModalVisible(true);
    }, [dispatch]);

    const editPartner = useCallback((id) => {
        setPartnerId(id);
        setAddModalVisible(true);
        setIsEdit(true);
        dispatch(actions.clearAgencyState());
    }, [dispatch]);

    const deletePartner = useCallback((id) => {
        dispatch(actions.requestDeletePartner(id));
    }, [dispatch]);

    useNotification({
        messageSuccess,
        error,
        item: partnerName,
        clearAction: actions.clearDeletePartnerState(),
        mode: 'list',
    });

    return (
        <div className="my-partner-page">
            <Filters
                isSupplier={isSupplier}
                setIsSupplier={setIsSupplier}
                modalVisible={addModalVisible}
                setModalVisible={setAddModalVisible}
                filters={filters}
                setFilters={setFilters}
                partnerId={partnerId}
                setPartnerId={setPartnerId}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                totalItems={totalItems}
            />
            { loading
                ? 'Loading...'
                : (
                    <Row className="partner-table">
                        {partners.map((partner) => (
                            <Col xs={24} key={partner.id}>
                                <Row align="middle">
                                    <Col span={4}>
                                        <BlockDescription
                                            icon={<Avatar size={40} src={partner.icon} />}
                                            label={(
                                                <Link to={`/app/b2b-partners/agency-profile/${partner.id}`}>
                                                    {partner.name}
                                                </Link>
                                            )}
                                            // content={partner.country}
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <InfoBlock name={`${t('Countries Operation')}:`}>
                                            {partner.country}
                                        </InfoBlock>
                                    </Col>
                                    <Col span={6}>
                                        <InfoBlock
                                            name={
                                                !isSupplier
                                                    ? t('Distributor for Tour and Accommodation')
                                                    : t('Supplies for Tour and Accommodation')
                                            }
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <InfoBlock name={!isSupplier ? `${t('Sales Revenue')}:` : `${t('Collected')}:`}>
                                            {!isSupplier
                                                ? `${currencyIcon}${partner.sales_revenue}`
                                                : partner.collected}
                                        </InfoBlock>
                                    </Col>
                                    <Col span={6}>
                                        <Space size={20} className="actions">
                                            <ActionIcon>
                                                <EyeIcon onClick={() => viewPartner(
                                                    partner.id,
                                                )}
                                                />
                                            </ActionIcon>
                                            {!isSupplier && (
                                                <ActionIcon onClick={() => editPartner(partner.id)}>
                                                    <EditIcon />
                                                </ActionIcon>
                                            )}
                                            {!isSupplier && (
                                                <Popconfirm
                                                    title={`${t('Are you sure to delete this customer')} ?`}
                                                    onConfirm={() => {
                                                        deletePartner(partner.id);
                                                        setPartnerName(partner.name);
                                                    }}
                                                >
                                                    <ActionIcon>
                                                        <DeleteIcon />
                                                    </ActionIcon>
                                                </Popconfirm>
                                            )}
                                        </Space>
                                    </Col>
                                </Row>
                                <div className="partner-divider"><Divider /></div>
                            </Col>
                        ))}
                    </Row>
                )}
            {totalItems > 0 && (
                <div className="partner-list-pagination">
                    <Pagination
                        size="small"
                        current={currentPage}
                        pageSize={itemsPerPage}
                        total={totalItems}
                        onChange={(current, pageSize) => {
                            setCurrentPage(current);
                            setItemsPerPage(pageSize);
                        }}
                    />
                </div>
            )}
            <ViewVendor
                modalVisible={viewModalVisible}
                setModalVisible={setViewModalVisible}
            />
        </div>
    );
};

export default MyPartners;
