/* eslint-disable max-len */
import { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Row,
    Col,
    Space,
    Typography,
    Button,
    Avatar,
} from 'antd';
import {
    RightIcon,
    ClockIcon,
    LocationIcon,
    TickIcon,
    ShipIcon,
    DeleteIcon,
    InfoCircleIcon,
} from '@/assets/images/icons/screens';
import { HotelIcon, ActivityIcon } from '@/assets/images/icons/topbar';
import { TourIcon } from '@/assets/images/icons/sidebar';
import ActionIcon from '@/components/ActionIcon';
import TermsAndConditions from '@/components/TermsAndConditions';

const B2BCard = ({
    data,
    sendRequest,
    xlColValue = 6,
}) => {
    const { t } = useTranslation();
    const { push } = useHistory();

    const [modalVisible, setModalVisible] = useState(false);
    const [serviceId, setServiceId] = useState(null);
    const [type, setType] = useState(null);
    const [text, setText] = useState('');

    return (
        <>
            <Row gutter={[20, 20]} className="tour-list">
                {data.map((item) => (
                    <Col
                        xl={xlColValue}
                        sm={12}
                        xs={24}
                        key={item.id}
                    >
                        <div className="tour-item">
                            <div className="tour-details">
                                <div className="remove-icon-wrapper">
                                    {(item.status_id === '1' || item.status_id === '2')
                                        && (
                                            <ActionIcon onClick={() => sendRequest({
                                                status: 3,
                                                service_id: item.id,
                                                type: item.type,
                                            })}
                                            >
                                                <DeleteIcon />
                                            </ActionIcon>
                                        )}
                                </div>
                                <Space align="start" size={20}>
                                    <div>
                                        <Avatar
                                            size={90}
                                            src={item.image_path}
                                        />
                                    </div>
                                    <div>
                                        <Button
                                            className="view-tour"
                                            onClick={() => push(`/app/b2b-partners/worldwide-supplier/${item.type}/${item.id}`)}
                                        >
                                            {(item.type === 'trip' || item.type === 'package') && <TourIcon />}
                                            {item.type === 'cruise' && <ShipIcon />}
                                            {item.type === 'activity' && <ActivityIcon />}
                                            {t(item.type_name).toUpperCase()}
                                        </Button>
                                        <div className="tour-name">
                                            <Typography.Text>{item.name}</Typography.Text>
                                        </div>
                                        <div className="details">
                                            {item.type === 'package' ? (
                                                <div>
                                                    <HotelIcon />
                                                    {item.location}
                                                </div>
                                            ) : (
                                                <div>
                                                    <LocationIcon />
                                                    {item.location}
                                                </div>
                                            )}
                                            <div className="agency_info">
                                                <Avatar size={15} src={item.agency_icon} />
                                                <Link to={`/app/b2b-partners/agency-profile/${item.agency_id}`}>
                                                    {item.agency_name}
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </Space>
                            </div>
                            <div className="tour-price-section">
                                <Row justify="space-between">
                                    <Col>
                                        <div className="price-content">
                                            <div>
                                                {`${item.currency_icon} ${item.price} / ${item.price_type === 'Per Person' ? t('person') : `${item.price_type}`}`}
                                            </div>
                                            <div
                                                className={clsx(
                                                    item.status_id === '2' ? 'second-color' : 'base-color',
                                                )}
                                            >
                                                <span>
                                                    {item.commission_type === 'Percentage'
                                                        ? `${item.commission}%`
                                                        : `${item.currency_icon} ${item.commission}`}
                                                </span>
                                                <span>{t('your earning')}</span>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        {item.status_id !== '5' ? (
                                            <Button
                                                onClick={() => {
                                                    item.status_id === null
                                                    && setModalVisible(true);
                                                    setServiceId(item.id);
                                                    setType(item.type);
                                                    setText(item.termsofservice);
                                                }}
                                                className={clsx(
                                                    (item.status_id === null || item.status_id === '3') && 'base-button',
                                                    item.status_id === '1' && 'collected-button',
                                                    item.status_id === '2' && 'pending-button',
                                                )}
                                            >
                                                {(item.status_id === null || item.status_id === '3') && (
                                                    <>
                                                        <span>{t('collect').toUpperCase()}</span>
                                                        <RightIcon />
                                                    </>
                                                )}
                                                {item.status_id === '1' && (
                                                    <span className="btn-text">
                                                        <span className="svg-wrapper">
                                                            <TickIcon />
                                                        </span>
                                                        {t('collected').toUpperCase()}
                                                    </span>
                                                )}
                                                {item.status_id === '2' && (
                                                    <span className="btn-text">
                                                        <span className="svg-wrapper">
                                                            <ClockIcon />
                                                        </span>
                                                        {t('pending approval').toUpperCase()}
                                                    </span>
                                                )}
                                            </Button>
                                        ) : (
                                            <Space>
                                                <Button
                                                    className="accept-button"
                                                    onClick={() => sendRequest({
                                                        status: 1,
                                                        service_id: item.id,
                                                        type: item.type,
                                                    })}
                                                >
                                                    {t('accept').toUpperCase()}
                                                </Button>
                                                <Button
                                                    className="reject-button"
                                                    onClick={() => sendRequest({
                                                        status: 3,
                                                        service_id: item.id,
                                                        type: item.type,
                                                    })}
                                                >
                                                    {t('reject').toUpperCase()}
                                                </Button>
                                            </Space>
                                        )}
                                    </Col>
                                </Row>
                                {item.status_id === '3' && (
                                    <Space size={10} className="request-info">
                                        <InfoCircleIcon />
                                        {`${t('This service was canceled in the last request')}.`}
                                    </Space>
                                )}
                            </div>
                        </div>
                    </Col>
                ))}
            </Row>
            <TermsAndConditions
                modalVisible={modalVisible}
                setModalVisible={setModalVisible}
                serviceId={serviceId}
                type={type}
                text={text}
            />
        </>
    );
};

export default B2BCard;
