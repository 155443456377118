import axios from 'axios';
import StorageManager from '@/helpers/StorageManager';
import {
    removeToken,
    removeRefreshToken,
    setToken,
    setRefreshToken,
    getRefreshToken,
} from '@/helpers/auth';

class BaseService {
    constructor(baseUrl = process.env.REACT_APP_API_URL) {
        this.token = StorageManager.get('token');
        this.isRefreshing = false;
        this.pendingRequests = [];

        this.api = axios.create({
            baseURL: baseUrl,
            headers: { 'Content-type': 'application/json' },
        });

        // this will be removed, temporary fix
        this.fileUploadApi = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            headers: { 'Content-type': 'application/json' },
        });

        // this will be removed, temporary fix
        this.fileUploadApi.interceptors.request.use((config) => {
            const token = StorageManager.get('token');
            return {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                },
            };
        });

        this.authInterceptor = this.api.interceptors.request.use((config) => {
            const token = StorageManager.get('token');
            return {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${token}`,
                },
            };
        });

        this.responseInterceptors = this.api.interceptors.response.use(
            (res) => res.data,
            async (error) => {
                const originalRequest = error.config;
                if (error.response && error.response.status === 499 && !originalRequest._retry) {
                    if (!this.isRefreshing) {
                        this.isRefreshing = true;
                        originalRequest._retry = true;
                        try {
                            const newAccessToken = await this.refreshAccessToken();

                            // Retry all the requests in the queue with the new token
                            this.pendingRequests.forEach((callback) => callback(newAccessToken));
                            this.pendingRequests = [];

                            return this.api(originalRequest);
                        } catch (refreshError) {
                            removeToken();
                            removeRefreshToken();
                            window.location.reload();
                        } finally {
                            this.isRefreshing = false;
                        }
                    }

                    // Queue the requests while the token is being refreshed
                    return new Promise((resolve) => {
                        this.addRequestToQueue((newAccessToken) => {
                            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
                            resolve(this.api(originalRequest));
                        });
                    });
                }
                if (error.response && error.response.status === 498) {
                    removeToken();
                    removeRefreshToken();
                    window.location.reload();
                }
                return Promise.reject(error);
            },
        );
    }

    addRequestToQueue(callback) {
        this.pendingRequests.push(callback);
    }

    async refreshAccessToken() {
        const newTokenResponse = await this.api.post('/token/refresh', {
            refresh_token: getRefreshToken(),
        });

        const {
            token: accessToken,
            refresh_token: refreshToken,
        } = newTokenResponse.data;

        setToken(accessToken);
        setRefreshToken(refreshToken);

        return accessToken;
    }

    get = (url, options) => this.api.get(url, options);

    post = (url, data, options) => this.api.post(url, data, options);

    put = (url, data, options) => this.api.put(url, data, options);

    patch = (url, data, options) => this.api.patch(url, data, options);

    delete = (url, options) => this.api.delete(url, options);
}

export default BaseService;
