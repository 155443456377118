import { useTranslation } from 'react-i18next';
import { Image } from 'antd';
import Menu from './Menu';

const Cover = ({
    src,
    rooms,
    price,
    priceType,
    editUrl,
    deleteAction,
    type,
    isShared,
    agency,
    removeSharedServiceAction,
    removeSharedCollectedServiceAction,
    myService,
    currency,
    name,
    setItemName,
}) => {
    const { t } = useTranslation();

    return (
        <div>
            <Image
                src={src}
                preview={false}
            />

            <div className="cover-content">
                <div className="small-text">
                    {rooms}
                </div>

                <div className="small-text">
                    {agency}
                </div>

                <div>
                    <span>{t('from')}</span>
                    {' '}
                    <span className="price">
                        {currency}
                        {price}
                    </span>
                    {' '}
                    <span>
                        {priceType}
                    </span>
                </div>
            </div>

            <Menu
                editUrl={editUrl}
                deleteAction={deleteAction}
                type={type}
                isShared={isShared}
                removeSharedServiceAction={removeSharedServiceAction}
                removeSharedCollectedServiceAction={
                    removeSharedCollectedServiceAction
                }
                myService={myService}
                name={name}
                setItemName={setItemName}
            />
        </div>
    );
};

export default Cover;
