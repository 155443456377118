import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Row,
    Col,
    Button,
    Space,
    Rate,
    Modal,
} from 'antd';
import { ArrowLeftIcon, LocationIcon } from '@/assets/images/icons/screens';

import GoogleMap from '../GoogleMap';

const HeaderWithMap = ({
    goBackUrl,
    title,
    rate,
    address,
    lat,
    lng,
    children,
}) => {
    const { t } = useTranslation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    return (
        <Row className="view-one-header-with-map" justify="space-between">
            <Col>
                <Row gutter={30}>
                    <Col>
                        <Link to={goBackUrl}>
                            <Button className="go-back">
                                <ArrowLeftIcon />
                            </Button>
                        </Link>
                    </Col>

                    <Col>
                        <div className="header-title">
                            <Space size={15}>
                                {title}

                                <Rate
                                    disabled
                                    value={rate}
                                    count={rate}
                                />
                            </Space>
                        </div>

                        <div className="location">
                            <Space size={2}>
                                <span>
                                    <LocationIcon />
                                    {address}
                                </span>

                                <Button
                                    type="text"
                                    onClick={() => setIsModalVisible(true)}
                                >
                                    {t('Open Map')}
                                </Button>
                            </Space>
                        </div>
                    </Col>
                </Row>
            </Col>

            <Col>
                {children}
            </Col>

            <Modal
                open={isModalVisible}
                onCancel={() => setIsModalVisible(false)}
                closable={false}
                centered
                footer={null}
                className="map-modal"
            >
                <GoogleMap latitude={lat} longitude={lng} zoom={8} />
            </Modal>
        </Row>
    );
};

export default HeaderWithMap;
