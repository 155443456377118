import { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import { LocationIcon } from '@/assets/images/icons/screens';

const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const MyCustomMarker = () => (
    <div
        className="location"
        style={{ transform: 'translate(-50%, -50%)' }}
    >
        <LocationIcon />
    </div>
);

const GoogleMap = ({
    latitude,
    longitude,
    itenerary,
    zoom = 3,
    fieldKey,
    address,
    form,
}) => {
    const [defaultCenter, setDefaultCenter] = useState({ lat: 0, lng: 0 });

    const parseCoordinate = (coord) => {
        if (typeof coord === 'string' && coord.includes(',')) {
            const [first] = coord.split(',');
            return Number(first);
        }
        return Number(coord);
    };

    // set coordinates from latitude and longitude
    // used in views and edit mode
    useEffect(() => {
        if (latitude !== undefined && longitude !== undefined) {
            const lat = parseCoordinate(latitude);
            const lng = parseCoordinate(longitude);
            if (!Number.isNaN(lat) && !Number.isNaN(lng)) {
                setDefaultCenter({ lat, lng });
            }
        }
    }, [latitude, longitude]);

    const markers = itenerary?.map((itenerar) => ({
        lat: parseCoordinate(itenerar.latitude),
        lng: parseCoordinate(itenerar.longitude),
    }));

    // render polylines
    const renderPolylines = (map, maps) => {
        const geodesicPolyline = new maps.Polyline({
            path: markers,
            geodesic: true,
            strokeColor: '#3B55EB',
            strokeOpacity: 1.0,
            strokeWeight: 4,
        });
        geodesicPolyline.setMap(map);
    };

    // get coordinates from map click
    // update address field
    const handleMapClick = ({ lat, lng }) => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat, lng } }, (results, status) => {
            if (status === 'OK') {
                if (results[0]) {
                    // at this case we are in itenerary mode
                    if (fieldKey !== undefined) {
                        const fields = form.getFieldsValue();

                        Object.assign(
                            fields.iteneraries[fieldKey],
                            {
                                latitude: lat,
                                longitude: lng,
                                location: results[0].formatted_address,
                            },
                        );

                        form?.setFieldsValue({
                            iteneraries: fields.iteneraries,
                        });
                    } else {
                        // at this case the map is used in general details
                        form?.setFieldsValue({
                            address: results[0].formatted_address,
                            latitude: lat,
                            longitude: lng,
                        });
                    }
                }
            }
        });
        setDefaultCenter({ lat, lng });
    };

    // set coordinates from address
    useEffect(() => {
        if (address !== undefined) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode({ address }, (results, status) => {
                if (status === 'OK' && results[0]) {
                    const lat = results[0].geometry.location.lat();
                    const lng = results[0].geometry.location.lng();
                    if (fieldKey !== undefined) {
                        const fields = form.getFieldsValue();

                        Object.assign(
                            fields.iteneraries[fieldKey],
                            {
                                latitude: lat,
                                longitude: lng,
                            },
                        );

                        form?.setFieldsValue({
                            iteneraries: fields.iteneraries,
                        });
                    } else {
                        form?.setFieldsValue({
                            latitude: lat,
                            longitude: lng,
                        });
                    }
                    setDefaultCenter({ lat, lng });
                }
            });
        }
    }, [address]);

    return (
        <GoogleMapReact
            bootstrapURLKeys={{ key: apiKey }}
            zoom={zoom}
            style={{ height: '300px' }}
            yesIWantToUseGoogleMapApiInternals
            onClick={handleMapClick}
            center={defaultCenter}
            onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
        >
            {markers && markers.length !== 0
                ? markers.map((marker, i) => (
                    <MyCustomMarker
                        key={i.toString()}
                        lat={marker.lat}
                        lng={marker.lng}
                    />
                )) : (
                    <MyCustomMarker
                        lat={defaultCenter.lat}
                        lng={defaultCenter.lng}
                    />
                )}
        </GoogleMapReact>
    );
};

export default GoogleMap;
