import {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Form, Divider } from 'antd';

import HeaderWithHelp from '@/components/HeaderWithHelp';
import DashedContainer from '@/components/DashedContainer';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import useTranslatedValidationConfig from '@/hooks/useTranslatedValidationConfig';
import { firstToUpper } from '@/helpers/string';
import { timeFormat } from '@/helpers/dateFormat';
import { extractUrlFromFile } from '@/helpers/upload';
import { returnDeletedImages } from '@/helpers/deleteImages';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { selectors } from '@/redux/accommodation/hotel/create/model';

import Loading from '@/components/Loading';
import WidgetCategory from '@/components/WidgetCategory';
import GeneralDetails from './GeneralDetails';
import Location from './Location';
import Policy from './Policy';
import ContactPerson from './ContactPerson';
import ImagesUpload from './ImagesUpload';
import baseValidations from './validations';

const question = 'What is this?';
const answer = 'Lorem ipsum dolor sit amet...';

const formFields = [
    'name',
    'stars',
    'description',
    'address',
    'city',
    'country',
    'state',
    'zip_code',
    'latitude',
    'longitude',
    'check_in_time',
    'check_out_time',
    'charges_type',
    'payment_methods',
    'first_name',
    'last_name',
    'position',
    'phone',
    'email',
    'website',
];

const formatPaymentMethods = (items) => items.map((item) => item.id);

const formatDataToSubmit = (id, urlType, values, initialImages) => ({
    id,
    hotel_type: urlType,
    name: values.name,
    stars: values.stars,
    description: values.description,
    address: values.address,
    city: values.city,
    country: values.country?.value,
    state: values.state,
    zip_code: values.zip_code,
    latitude: values.latitude,
    longitude: values.longitude,
    policy: {
        check_in_time: values.check_in_time?.format(timeFormat),
        check_out_time: values.check_out_time?.format(timeFormat),
        payment_methods: values.payment_methods,
        charges_type: values.charges_type,
    },
    widgets_category: values.widgets_category,
    contact: {
        first_name: values.first_name,
        last_name: values.last_name,
        position: values.position,
        phone: values.phone,
        email: values.email,
        website: values.website,
    },
    images: values.images
        ? values.images.fileList?.map(extractUrlFromFile)
        : [],
    deleted_images: returnDeletedImages(initialImages, values.images?.fileList),
});

const Details = ({ form, id, regStep }) => {
    const { type } = useParams();
    const dispatch = useDispatch();

    const [address, setAddress] = useState(undefined);
    const [urlType, setUrlType] = useState(null);

    const validations = useTranslatedValidationConfig(baseValidations);

    useEffect(() => {
        let newUrlType = null;

        if (type === 'hotel') {
            newUrlType = 3;
        } else if (type === 'motel') {
            newUrlType = 4;
        } else if (type === 'resort') {
            newUrlType = 7;
        } else {
            newUrlType = 10;
        }
        setUrlType(newUrlType);
    }, [type, dispatch]);

    const details = useSelector(selectors.accommodationDetails);

    const initialImages = details?.images;

    // add mode
    const onAdd = useCallback(
        (values) => {
            const data = formatDataToSubmit(id, urlType, values, initialImages);
            const step = 1;
            const completedStep = 0;
            dispatch(
                actions.requestSubmitAccommodationDetails(
                    data,
                    step,
                    completedStep,
                ),
            );
        },
        [dispatch, id, urlType, initialImages],
    );

    // edit mode
    const onEdit = useCallback(
        (values) => {
            const data = formatDataToSubmit(id, urlType, values, initialImages);
            const step = 1;
            const completedStep = 1;
            dispatch(
                actions.requestSubmitAccommodationDetails(
                    data,
                    step,
                    completedStep,
                ),
            );
        },
        [dispatch, id, urlType, initialImages],
    );

    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    const initialData = useMemo(() => {
        if (details) {
            return {
                name: details.name,
                stars: details.stars,
                description: details.description,
                address: details.address,
                city: details.city,
                country: {
                    value: details.country_id,
                },
                state: details.state,
                zip_code: details.zip_code,
                latitude: details.latitude,
                longitude: details.longitude,
                check_in_time: moment(details.policy.check_in_time, 'HH:mm:ss'),
                check_out_time: moment(
                    details.policy.check_out_time,
                    'HH:mm:ss',
                ),
                payment_methods: formatPaymentMethods(
                    details.policy.selected_methods,
                ),
                charges_type: details.policy.charges_type?.id,
                widgets_category: details.widget_categories.map(
                    (category) => category.id,
                ),
                first_name: details.contact.first_name,
                last_name: details.contact.last_name,
                position: details.contact.position,
                phone: details.contact.phone,
                email: details.contact.email,
                website: details.contact.website,
                images: details.images.map((image) => (
                    {
                        id: image.id,
                        url: image.path,
                        thumbUrl: image.path,
                    }
                )),
            };
        } else return null;
    }, [details]);

    // In Every edit form before rendering the form should be this check
    // for getting all the initial data and setting in the form before it being render
    if (id && !initialData) {
        // Here we can return Null or a loading component
        return <Loading />;
    }

    return (
        <Form
            id="accommodation-details-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={regStep === undefined || regStep === 1 || !id ? onAdd : onEdit}
            initialValues={initialData || {}}
        >
            <HeaderWithHelp
                title={`${firstToUpper(type)} Details`}
                question={question}
                answer={answer}
            />

            <Divider />

            <Form.Item>
                <GeneralDetails
                    validations={validations}
                    setAddress={setAddress}
                />
            </Form.Item>

            <Form.Item>
                <Location
                    lat={details?.latitude}
                    lng={details?.longitude}
                    address={address}
                    form={form}
                />
            </Form.Item>

            <DashedContainer title="Policy">
                <Form.Item>
                    <Policy
                        validations={validations}
                        initialPaymentData={initialData?.payment_methods}
                    />
                </Form.Item>
            </DashedContainer>

            <DashedContainer title="Contact Person">
                <Form.Item>
                    <ContactPerson validations={validations} />
                </Form.Item>
            </DashedContainer>

            <DashedContainer title="Widget Category">
                <Form.Item>
                    <WidgetCategory />
                </Form.Item>
            </DashedContainer>

            <Form.Item>
                <ImagesUpload />
            </Form.Item>
        </Form>
    );
};

export default Details;
