import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    Row,
    Col,
    Form,
    Input,
    DatePicker,
    Typography,
    Button,
} from 'antd';
import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import MUIFormItem from '@/components/MUIFormItem';
import { CalendarIcon } from '@/assets/images/icons/screens';
import useApiValidationsForForm from '@/hooks/useApiValidationsForForm';
import { dateFormat } from '@/helpers/dateFormat';
import { useCurrency } from '@/hooks/useCurrency';
import { actions } from '@/redux/accommodation/hotel/reducer';
import { selectors } from '@/redux/accommodation/hotel/create/model';

import Price from '../../Price';

const formatRoomTypes = (items) => items.map((item) => (
    item.id
));

const formFields = [
    'name',
    'price',
    'start_date',
    'end_date',
    'room_types',
];

const AddNewSeason = ({ roomTypesList }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { icon: currencyIcon } = useCurrency();

    const options = useMemo(() => ([
        { label: t('%'), value: 4 },
        { label: t(currencyIcon), value: 5 },
    ]), [t]);

    const goToSeasonList = useCallback(() => {
        dispatch(actions.goToViewSeasonList());
    }, [dispatch]);

    const onFinish = useCallback((values) => {
        const data = {
            name: values.name,

            price: values.price,
            commission: values.commission,
            commission_type: values.commission_type,

            price_bb: values.price_bb,
            commission_bb: values.commission_bb,
            commission_type_bb: values.commission_type_bb,

            price_bh: values.price_bh,
            commission_bh: values.commission_bh,
            commission_type_bh: values.commission_type_bh,

            price_ai: values.price_ai,
            commission_ai: values.commission_ai,
            commission_type_ai: values.commission_type_ai,

            start_date: values.start_date?.format(dateFormat),
            end_date: values.end_date?.format(dateFormat),
            room_types: values.room_types,
        };
        dispatch(actions.requestSubmitAccommodationSeason(data));
    }, [dispatch]);

    const season = useSelector(selectors.seasonDetails);
    const errors = useSelector(selectors.errors);

    useApiValidationsForForm({ form, errors, formFields });

    const initialData = useMemo(() => (season ? {
        name: season?.name,

        price: season?.price,
        commission: season?.commission,
        commission_type: season?.commission_type === 'Percentage' ? 4 : 5,

        price_bb: season?.price_bb,
        commission_bb: season?.commission_bb,
        commission_type_bb: season?.commission_type_bb === 'Percentage' ? 4 : 5,

        price_bh: season?.price_bh,
        commission_bh: season?.commission_bh,
        commission_type_bh: season?.commission_type_bh === 'Percentage' ? 4 : 5,

        price_ai: season?.price_ai,
        commission_ai: season?.commission_ai,
        commission_type_ai: season?.commission_type_ai === 'Percentage' ? 4 : 5,

        start_date: moment(season?.start_date),
        end_date: moment(season?.end_date),
        room_types: season?.room_types ? formatRoomTypes(season?.room_types) : [],
    } : null), [season]);

    return (
        <Form
            id="hotel-seasonal-price-form"
            form={form}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            initialValues={initialData || {}}
        >
            <MUIFormItem
                label={t('Season Name')}
                name="name"
            >
                <Input />
            </MUIFormItem>

            <Row gutter={[28, 0]}>
                <Price
                    currency={currencyIcon}
                    options={options}
                    priceLabel="Price per Night"
                    priceName="price"
                    commissionLabel="My Commission"
                    commissionName="commission"
                    commissionTypeName="commission_type"
                />
                <Price
                    currency={currencyIcon}
                    options={options}
                    priceLabel="Price per Night BB"
                    priceName="price_bb"
                    commissionLabel="My Commission"
                    commissionName="commission_bb"
                    commissionTypeName="commission_type_bb"
                />
                <Price
                    currency={currencyIcon}
                    options={options}
                    priceLabel="Price per Night HB"
                    priceName="price_bh"
                    commissionLabel="My Commission"
                    commissionName="commission_bh"
                    commissionTypeName="commission_type_bh"
                />
                <Price
                    currency={currencyIcon}
                    options={options}
                    priceLabel="Price per Night AI"
                    priceName="price_ai"
                    commissionLabel="My Commission"
                    commissionName="commission_ai"
                    commissionTypeName="commission_type_ai"
                />
            </Row>

            <Row gutter={0}>
                <Col xl={11} lg={24} md={12} sm={12} xs={24}>
                    <MUIFormItem
                        label={t('Start Season')}
                        name="start_date"
                    >
                        <DatePicker suffixIcon={<CalendarIcon />} />
                    </MUIFormItem>
                </Col>

                <Col xs={2}>
                    <div className="date-divider" />
                </Col>

                <Col xl={11} lg={24} md={12} sm={12} xs={24}>
                    <MUIFormItem
                        label={t('End Season')}
                        name="end_date"
                    >
                        <DatePicker suffixIcon={<CalendarIcon />} />
                    </MUIFormItem>
                </Col>
            </Row>

            <div className="checkbox-block">
                <Typography.Text>
                    {t('Select the Room Types you want to assign this Season')}
                </Typography.Text>
                <Form.Item name="room_types">
                    <CheckboxMultiSelect
                        options={roomTypesList}
                        labelParamName="type_name"
                        valueParamName="id"
                        selectAllLabel={t('Assign to all')}
                        initialCheckedList={initialData?.room_types || []}
                        col={12}
                    />
                </Form.Item>
            </div>

            <Form.Item>
                <Row justify="end">
                    <Col pull={1}>
                        <Button
                            danger
                            type="primary"
                            onClick={goToSeasonList}
                        >
                            {t('Cancel')}
                        </Button>
                    </Col>
                    <Col>
                        <Button type="primary" htmlType="submit">
                            {t('Submit')}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};

export default AddNewSeason;
