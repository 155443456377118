import { useTranslation } from 'react-i18next';
import { Row, Col, Space } from 'antd';

import BlockDescription from '@/components/Information/WithImage/Block';
import { ClockIconGreen, ClockIconRed, TickIcon } from '@/assets/images/icons/screens';

const Policies = ({ data }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="tour-info-section-title">
                {t('Policies')}
            </div>

            <div className="charges-type">
                <Space size={10}>
                    <span>
                        {t('Charges Type')}
                        :
                    </span>
                    <span>{data.charges_type?.name}</span>
                </Space>
            </div>

            <div className="time">
                <Row gutter={30}>
                    <Col xs={12} lg={24} xl={12}>
                        <BlockDescription
                            icon={<ClockIconGreen />}
                            label={t('Check in Time')}
                            content={data.check_in_time}
                            spaceSize={14}
                        />
                    </Col>
                    <Col xs={12} lg={24} xl={12}>
                        <BlockDescription
                            icon={<ClockIconRed />}
                            label={t('Check out Time')}
                            content={data.check_out_time}
                            spaceSize={14}
                        />
                    </Col>
                </Row>
            </div>

            <div className="payment-block">{t('Payment Accept')}</div>

            <Row gutter={[30, 15]}>
                {data.methods.map((method) => (
                    <Col key={method.id} xs={24} xl={12} lg={24} className="tour-info-section-content">
                        <Space size={10}>
                            <div className="payment-icon-bg">
                                <TickIcon />
                            </div>
                            {method.name}
                        </Space>
                    </Col>
                ))}
            </Row>
        </>
    );
};

export default Policies;
