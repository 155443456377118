import {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Form,
    Typography,
    Button,
    Select,
    Radio,
    Input,
} from 'antd';
import { selectors } from '@/redux/b2b/partner/create/model';
import { actions } from '@/redux/b2b/partner/create/reducer';
import MUIFormItem from '@/components/MUIFormItem';
import { useCurrency } from '@/hooks/useCurrency';

const formatAllServices = (serviceList) => serviceList.map((service) => {
    const updatedServices = {
        label: service.name,
        options: service.services.map((product) => ({
            label: product.name,
            value: `${service.id}-${product.id}`,
        })),
    };
    return updatedServices;
});

const Distribution = ({
    formStep3,
    submitting,
    partnerId,
    isEdit,
    checkedList,
    setCheckedList,
    agencyId,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { icon: currencyIcon } = useCurrency();

    const options = useMemo(() => ([
        { label: '%', value: 1 },
        { label: currencyIcon, value: 2 },
    ]), []);

    const allAgencyServices = useSelector(selectors.allAgencyServices);
    const formattedAllAgencyServices = formatAllServices(allAgencyServices);

    // new partner id
    const newAgencyId = useSelector((state) => state.b2b.partner.create.agencyAccount?.id);
    // get agency(partner) name
    const agencyName = useSelector(selectors.agencyName);

    useEffect(() => {
        dispatch(actions.requestGetAllAgencyServices());
    }, [dispatch]);

    // returns checked services with commission and commission type (partner services)
    const checkedServices = checkedList?.map((checkedItem) => {
        const [serviceId, productId] = checkedItem.id.split('-');
        const product = allAgencyServices.find((item) => {
            return item.id === parseInt(serviceId, 10);
        })?.services.find((item) => {
            return item.id === productId;
        });
        return {
            id: checkedItem.id,
            name: product?.name,
            commission: checkedItem?.commission,
            commission_type: checkedItem?.commission_type
                ? checkedItem?.commission_type
                : parseInt(product?.commission_type, 10),
        };
    });

    // submit form
    const onFinish = useCallback((values) => {
        const servicesToSubmit = {
            tour: [],
            accommodation: [],
        };
        values.products.forEach((product) => {
            const [serviceId, productId] = product.split('-');
            if (serviceId === '1') {
                servicesToSubmit.tour.push({
                    id: Number(productId),
                    commission: values[`commission${product}`],
                    commission_type: values[`commission_type${product}`],
                });
            } else {
                servicesToSubmit.accommodation.push({
                    id: Number(productId),
                    commission: values[`commission${product}`],
                    commission_type: values[`commission_type${product}`],
                });
            }
        });
        let data = {};
        if (isEdit) { // edit mode, edit partner
            data = {
                id: partnerId,
                services: servicesToSubmit,
            };
        } if (agencyId) { // create mode (new partner from existing agency list)
            data = {
                id: agencyId,
                services: servicesToSubmit,
            };
        } if (newAgencyId) { // create mode (new partner)
            data = {
                id: newAgencyId,
                services: servicesToSubmit,
            };
        }
        dispatch(actions.requestSubmitAgencyDistribution(data));
    }, [dispatch, isEdit, partnerId, agencyId, newAgencyId]);

    useEffect(() => {
        let initialData = {};
        initialData = {
            products: checkedServices.map((product) => product.id),
        };
        checkedServices.forEach((product) => {
            initialData = {
                ...initialData,
                [`commission${product.id}`]: product.commission,
                [`commission_type${product.id}`]: parseInt(product.commission_type, 10),
            };
        });
        formStep3.setFieldsValue(initialData);
    }, [checkedServices, isEdit, formStep3]);

    return (
        <Form
            form={formStep3}
            layout="vertical"
            requiredMark={false}
            onFinish={onFinish}
            className={clsx(isEdit && 'padding-top')}
        >
            <div className="distribution">
                <Typography.Text className="step-title">{t('Distribution')}</Typography.Text>
                <div className="step-subtitle">
                    {isEdit ? (
                        <>
                            {`${t('Choose products you want to share with')}`}
                            {' '}
                            <span className="agency-name">{agencyName}</span>
                        </>
                    ) : (
                        `${t('Choose products you want to share with this partner')}`
                    )}
                </div>
                <MUIFormItem name="products" label={t('Products')}>
                    <Select
                        mode="multiple"
                        options={formattedAllAgencyServices}
                        onSelect={(value) => setCheckedList([...checkedList, { id: value }])}
                        onDeselect={(value) => setCheckedList(
                            checkedList.filter((item) => item.id !== value),
                        )}
                    />
                </MUIFormItem>
                {checkedServices.map((product) => (
                    <MUIFormItem
                        label={product?.name?.concat(' ', t('commission'))}
                        name={'commission'.concat(product.id)}
                        key={product.id}
                    >
                        <Input
                            suffix={(
                                <>
                                    <span>0.00</span>
                                    <Form.Item
                                        name={'commission_type'.concat(product.id)}
                                        noStyle
                                    >
                                        <Radio.Group
                                            options={options}
                                            optionType="button"
                                            buttonStyle="solid"
                                        />
                                    </Form.Item>
                                </>
                            )}
                        />
                    </MUIFormItem>
                ))}
            </div>
            <Button
                block
                className="save-vendor-btn"
                loading={submitting}
                onClick={() => formStep3.submit()}
            >
                {t('save').toUpperCase()}
            </Button>
        </Form>
    );
};

export default Distribution;
