import { createSelectorsFromModel } from '@/helpers/redux';

const model = {
    invoiceDetails: null,
    invoiceCustomer: null,
    invoiceParticipants: [],
    invoiceExtras: null,
    statusTypeList: [],
    statusLoading: false,
    statusSuccess: '',
    statusError: null,
    submiting: false,
    message: '',
    loading: false,
    error: null,

};

export const selectors = createSelectorsFromModel(
    (state) => state.invoice.view,
    model,
);

export default model;
