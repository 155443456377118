import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    Row,
    Col,
    Typography,
    Space,
    Divider,
    Button,
    Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import ActionIcon from '@/components/ActionIcon';
import LinkButton from '@/components/LinkButton';
import Preview from '@/components/Invoice/InvoicePreview';
import {
    ArrowLeftIcon,
    CloseCircleIcon,
    DownLoadIcon,
    // EditIcon,
} from '@/assets/images/icons/screens';
import useNotification from '@/hooks/useNotification';
import { actions } from '@/redux/invoice/view/reducer';
import { selectors } from '@/redux/invoice/view/model';

import PayInvoice from './PayInvoice';

const Finished = ({
    // addUrl,
    backUrl,
    invoiceDetails,
    invoiceCustomer,
    invoiceParticipants,
    invoiceExtras,
    statusTypeList,
}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [isOpen, setIsOpen] = useState(false);
    const [status, changeStatus] = useState();
    const [openDropdown, setOpenDropdown] = useState(false);

    const invoiceId = invoiceCustomer.invoice_id;
    const { invoice_data: invoiceCharge } = invoiceDetails;
    const statusSuccess = useSelector(selectors.statusSuccess);
    const statusError = useSelector(selectors.statusError);
    const statusLoading = useSelector(selectors.statusLoading);

    // change order status
    useEffect(() => {
        if (status) {
            const data = {
                status_id: status.value,
                invoice_id: invoiceId,
            };
            dispatch(actions.requestChangeInvoiceStatus(data));
        }
    }, [dispatch, status, invoiceId]);

    useNotification({
        messageSuccess: statusSuccess,
        error: statusError,
        clearAction: actions.clearInvoiceStatus(),
    });

    return (
        <div className="finished">
            <Row>
                <Col>
                    <Space size={12}>
                        <LinkButton
                            className="go-back"
                            to={backUrl}
                        >
                            <ArrowLeftIcon />
                        </LinkButton>

                        <Typography.Text>
                            {t('Back')}
                        </Typography.Text>
                    </Space>
                </Col>
            </Row>

            <div className={isOpen ? 'extended-block' : 'block'}>
                <Row justify="space-between" className="invoice-title">
                    <Col>
                        <Typography.Title level={5}>
                            {`${t('Invoice')} ${invoiceId}`}
                        </Typography.Title>
                    </Col>

                    <Col>
                        <Space size={30}>
                            <div className="change-status-wrapper">
                                {openDropdown ? (
                                    <Select
                                        value={{
                                            value: status
                                                ? status.label
                                                : parseInt(invoiceCharge.status, 10),
                                        }}
                                        labelInValue
                                        onSelect={(value) => {
                                            changeStatus(value);
                                        }}
                                    >
                                        {statusTypeList.map((type) => (
                                            <Select.Option
                                                key={type.id}
                                                value={type.id}
                                            >
                                                {type.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                ) : (
                                    <Button
                                        className="change-status-btn"
                                        onClick={() => setOpenDropdown(true)}
                                        loading={statusLoading}
                                    >
                                        {t('Change status')}
                                    </Button>
                                )}
                            </div>
                            <ActionIcon>
                                <DownLoadIcon />
                            </ActionIcon>
                        </Space>
                    </Col>
                </Row>

                <Divider />

                <Row>
                    <Col xs={isOpen ? 24 : 24} lg={isOpen ? 14 : 24}>
                        <Preview
                            invoiceDetails={invoiceDetails}
                            invoiceCustomer={invoiceCustomer}
                            invoiceParticipants={invoiceParticipants}
                            invoiceExtras={invoiceExtras}
                        />
                    </Col>
                    {isOpen && (
                        <Col lg={10} xs={24} className="invoice-payment">
                            <PayInvoice
                                invoiceId={invoiceId}
                                invoiceCharge={invoiceCharge}
                            />
                        </Col>
                    )}
                </Row>

                {!isOpen && (
                    <Row className="action-step">
                        <Col md={6} xs={12}>
                            <LinkButton
                                to={backUrl}
                                className="cancel"
                                block
                            >
                                <Space size={22}>
                                    <CloseCircleIcon />
                                    <Typography.Text>
                                        {t('Cancel').toUpperCase()}
                                    </Typography.Text>
                                </Space>
                            </LinkButton>
                        </Col>
                        {/* <Col md={6} xs={12}>
                            {invoiceCharge.status !== '1' && (
                                <LinkButton
                                    to={addUrl}
                                    className="edit"
                                    block
                                >
                                    <Space size={22}>
                                        <EditIcon />
                                        <Typography.Text>
                                            {t('Edit').toUpperCase()}
                                        </Typography.Text>
                                    </Space>
                                </LinkButton>
                            )}
                        </Col> */}

                        <Col md={12} xs={24}>
                            {invoiceCharge.status !== '1' && (
                                <Button
                                    type="primary"
                                    className="pay"
                                    block
                                    onClick={() => setIsOpen(true)}
                                >
                                    {t('Pay this invoice').toUpperCase()}
                                </Button>
                            )}
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    );
};

export default Finished;
