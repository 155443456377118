import { useTranslation } from 'react-i18next';
import {
    Col,
    Form,
    Input,
    Radio,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';

const Price = ({
    currency,
    options,
    priceLabel,
    priceName,
    commissionLabel,
    commissionName,
    commissionTypeName,
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Col span={12}>
                <MUIFormItem
                    label={t(priceLabel)}
                    name={priceName}
                    // rules={validations.measurement}
                    // validateFirst
                    // hasFeedback
                >
                    <Input suffix={`${currency}0.00`} />
                </MUIFormItem>
            </Col>
            <Col span={12}>
                <MUIFormItem
                    label={t(commissionLabel)}
                    name={commissionName}
                    // validateFirst
                    // hasFeedback
                    // rules={validations.base_price}
                >
                    <Input
                        suffix={(
                            <>
                                0.00
                                <Form.Item
                                    name={commissionTypeName}
                                    noStyle
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            </>
                        )}
                    />
                </MUIFormItem>
            </Col>
        </>
    );
};

export default Price;
