import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Select,
} from 'antd';
import { actions } from '@/redux/invoice/reducer';
import { selectors } from '@/redux/invoice/create/model';

const FindOrCreateResource = ({
    customerSearchText,
    vendorSearchText,
    seperator,
    createForm,
    setVendorId,
}) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const customerList = useSelector(selectors.customerList);
    const loading = useSelector(selectors.customerLoading);

    const vendorList = useSelector(selectors.vendorList);
    const vendorLoading = useSelector(selectors.vendorLoading);

    // get customer list by search
    const onSearch = useCallback((search) => {
        if (search && search.length === 3) {
            const data = {
                full_name: search,
            };
            dispatch(actions.requestGetCustomerList(data));
        }
    }, [dispatch]);

    // get vendor list by search
    const onVendorSearch = useCallback((search) => {
        if (search && search.length === 3) {
            const data = {
                name: search,
                page: 1,
                perPage: 10,
                type: 0,
            };
            dispatch(actions.requestGetVendorList(data));
        }
    }, [dispatch]);

    // get existing customer
    const getExistingCustomer = useCallback((id) => {
        dispatch(actions.requestGetExistingCustomer(id));
    }, [dispatch]);

    return (
        <div className="find-or-create-resource">
            <Row gutter={30}>
                <Col span={12}>
                    <Select
                        className="select-customer"
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        notFoundContent={null}
                        loading={loading}
                        onSearch={onSearch}
                        placeholder={customerSearchText}
                        onSelect={(customerId) => getExistingCustomer(customerId)}
                    >
                        {customerList.map((customer) => (
                            <Select.Option
                                key={customer.id}
                                value={customer.id}
                            >
                                {customer.full_name}
                            </Select.Option>
                        ))}
                    </Select>
                </Col>
                <Col span={12}>
                    <Select
                        className="select-vendor"
                        showSearch
                        filterOption={false}
                        defaultActiveFirstOption={false}
                        notFoundContent={null}
                        loading={vendorLoading}
                        onSearch={onVendorSearch}
                        placeholder={vendorSearchText}
                        onSelect={(vendorId) => setVendorId(vendorId)}
                        allowClear
                    >
                        {vendorList.map((vendor) => (
                            <Select.Option
                                key={vendor.id}
                                value={vendor.id}
                            >
                                {vendor.name}
                            </Select.Option>
                        ))}
                    </Select>
                    <span className="info">
                        **
                        {t('Optional')}
                    </span>
                </Col>
            </Row>

            {seperator}

            {createForm}
        </div>
    );
};

export default FindOrCreateResource;
