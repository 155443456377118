import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
    Button,
    Form,
    Checkbox,
    Divider,
} from 'antd';

import MUIFormItem from '@/components/MUIFormItem';
import ActionIcon from '@/components/ActionIcon';
import { DeleteIcon, PlusIcon } from '@/assets/images/icons/screens';
import { useCurrency } from '@/hooks/useCurrency';

const ExtraServices = ({
    name,
    validations,
    checkboxText,
}) => {
    const { t } = useTranslation();
    const { icon: currencyIcon } = useCurrency();

    const [checkedFields, setCheckedFields] = useState([]);
    const uncheckField = (field) => {
        setCheckedFields(checkedFields.filter((f) => f !== field));
    };

    return (
        <Form.Item>
            <Form.List name={name}>
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field) => (
                            <div key={field.key}>
                                <Row gutter={25} className="tour-extra-services">
                                    <Col xxl={11} xs={24}>
                                        <MUIFormItem
                                            name={[field.name, 'service_name']}
                                            label={t('Name of Service/ Activity')}
                                            rules={validations && validations.extraServiceName}
                                            formListName={name}
                                        >
                                            <Input />
                                        </MUIFormItem>
                                    </Col>

                                    <Col xxl={11} xs={22}>
                                        <MUIFormItem
                                            name={[field.name, 'price']}
                                            rules={validations && validations.extraServicePrice}
                                        >
                                            <Input
                                                disabled={checkedFields.includes(field.name)}
                                                placeholder={`${currencyIcon}0.00 / ${t('person')}`}
                                            />
                                        </MUIFormItem>
                                        <Form.Item name={[field.name, 'price_type']} valuePropName="checked">
                                            <Checkbox
                                                checked={checkedFields.includes(field.name)}
                                                onChange={(e) => {
                                                    e.target.checked
                                                        ? setCheckedFields(
                                                            (prev) => [...prev, field.name],
                                                        )
                                                        : uncheckField(field.name);
                                                }}
                                            >
                                                {t(checkboxText)}
                                            </Checkbox>
                                        </Form.Item>
                                    </Col>

                                    <Col
                                        xl={{ span: 2, order: 1 }}
                                        lg={{ span: 2, order: 1 }}
                                        md={{ span: 2, order: 2 }}
                                        sm={{ span: 2, order: 2 }}
                                        xs={{ span: 2, order: 2 }}
                                    >
                                        <Form.Item>
                                            <ActionIcon
                                                className="circle-delete-button"
                                                onClick={() => remove(field.name)}
                                            >
                                                <DeleteIcon />
                                            </ActionIcon>
                                        </Form.Item>
                                    </Col>

                                </Row>
                                <Divider dashed />
                            </div>
                        ))}

                        <Button
                            type="text"
                            className="text-circle-button"
                            onClick={() => add()}
                        >
                            <div className="create-section_button">
                                <PlusIcon />
                            </div>
                            {t('Add New Extra Service')}
                        </Button>
                    </>
                )}
            </Form.List>
        </Form.Item>
    );
};

export default ExtraServices;
