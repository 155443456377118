import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Form,
    TimePicker,
    Typography,
    Select,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import CheckboxMultiSelect from '@/components/CheckboxMultiSelect';
import CreateSection from '@/components/CreateInclExclSection';
import useAccommodationPolicies from '@/hooks/useAccommodationPolicies';
import AccommodationService from '@/services/AccommodationService';

const Policy = ({ validations, initialPaymentData }) => {
    const { t } = useTranslation();
    const { type } = useParams();

    const { data, isValidating, mutate } = useAccommodationPolicies();

    const [urlType, setUrlType] = useState(null);
    useEffect(() => {
        if (type === 'hotel') {
            setUrlType(3);
        } else if (type === 'motel') {
            setUrlType(4);
        } else if (type === 'resort') {
            setUrlType(7);
        } else {
            setUrlType(10);
        }
    }, [type]);

    return (
        <>
            <Row
                className="hotel-policy"
                gutter={30}
            >
                <Col span={24}>
                    <MUIFormItem
                        label={t('Charges Type')}
                        name="charges_type"
                        rules={validations.charges_type}
                    >
                        <Select allowClear>
                            <Select.Option value={1}>{t('Per Person')}</Select.Option>
                            <Select.Option value={2}>{t('Per Night')}</Select.Option>
                        </Select>
                    </MUIFormItem>
                </Col>
                <Col xl={12} lg={24} md={24} xs={24}>
                    <MUIFormItem
                        label={t('Check in Time')}
                        name="check_in_time"
                        rules={validations.check_in_time}
                    >
                        <TimePicker
                            format="HH:mm a"
                            showNow={false}
                        />
                    </MUIFormItem>
                </Col>

                <Col xl={12} lg={24} md={24} xs={24}>
                    <MUIFormItem
                        label={t('Check out Time')}
                        name="check_out_time"
                        rules={validations.check_out_time}
                    >
                        <TimePicker
                            format="HH:mm a"
                            showNow={false}
                        />
                    </MUIFormItem>
                </Col>
            </Row>

            <Typography.Text>
                {t('Payment Options (Optional)')}
            </Typography.Text>
            {isValidating ? 'loading' : (
                <>
                    <Form.Item
                        className="row-typography"
                        name="payment_methods"
                        rules={validations.payment_methods}
                    >
                        <CheckboxMultiSelect
                            options={data?.data}
                            labelParamName="name"
                            valueParamName="id"
                            hasSelectAll={false}
                            initialCheckedList={initialPaymentData || []}
                            col={6}
                        />
                    </Form.Item>
                    <CreateSection
                        data={data}
                        mutate={mutate}
                        buttonText="Add New Payment Option"
                        addNewItem={AccommodationService.addNewPaymentOption}
                        accommodationType={urlType}
                        // validations={validations}
                    />
                </>
            )}
        </>
    );
};

export default Policy;
