import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Row,
    Col,
    Input,
    Space,
    Button,
    Form,
    Radio,
} from 'antd';
import MUIFormItem from '@/components/MUIFormItem';
import { DeleteIcon } from '@/assets/images/icons/screens';
import { firstToUpper } from '@/helpers/string';

const PriceItem = ({
    field,
    remove,
    formListName,
    nestedFormListName,
    sectionIndex,
    currency,
}) => {
    const { t } = useTranslation();

    const options = useMemo(() => ([
        { label: t('%'), value: 4 },
        { label: t(currency), value: 5 },
    ]), [t]);

    return (
        <Row gutter={30}>
            <Col xxl={4} sm={4} xs={4} className="hidden-input-wrapper">
                <Form.Item>
                    <Input
                        value={`${firstToUpper(t('child'))} ${sectionIndex + 1}`}
                        readOnly
                    />
                </Form.Item>
            </Col>
            <Col xxl={10} sm={24} xs={24}>
                <Space size={30}>
                    <MUIFormItem
                        label={t('From Age')}
                        name={[field.name, 'from_age']}
                        formListName={formListName}
                        nestedFormListName={nestedFormListName}
                        sectionIndex={sectionIndex}
                    >
                        <Input />
                    </MUIFormItem>
                    <MUIFormItem
                        label={t('To Age')}
                        name={[field.name, 'to_age']}
                        formListName={formListName}
                        nestedFormListName={nestedFormListName}
                        sectionIndex={sectionIndex}
                    >
                        <Input />
                    </MUIFormItem>
                </Space>
            </Col>
            <Col xxl={8} sm={20} xs={20}>
                <MUIFormItem
                    label={t('Price')}
                    name={[field.name, 'price']}
                    formListName={formListName}
                    nestedFormListName={nestedFormListName}
                    sectionIndex={sectionIndex}
                >
                    <Input
                        suffix={(
                            <>
                                0.00
                                <Form.Item
                                    name={[field.name, 'price_type']}
                                    noStyle
                                >
                                    <Radio.Group
                                        options={options}
                                        optionType="button"
                                        buttonStyle="solid"
                                    />
                                </Form.Item>
                            </>
                        )}
                    />
                </MUIFormItem>
            </Col>
            <Col xxl={2} sm={4} xs={4}>
                <Form.Item>
                    <Button
                        className="button btn-grey"
                        onClick={() => remove(field.name)}
                    >
                        <DeleteIcon />
                    </Button>
                </Form.Item>
            </Col>
        </Row>
    );
};

export default PriceItem;
