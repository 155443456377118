import { useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import {
    Row,
    Col,
    Space,
    Button,
    Form,
    Typography,
} from 'antd';

import { actions } from '@/redux/invoice/create/reducer';
import { selectors } from '@/redux/invoice/create/model';
import Preview from '@/components/Invoice/InvoicePreview';
import { ArrowLeftIcon, ArrowRightIcon } from '@/assets/images/icons/screens';

import Details from './Details';
import Participants from './Participants';
import Extras from './Extras';
import StepDisplay from './StepDisplay';

const steps = [Details, Participants, Extras];

const Create = ({ isEdit = false }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { push } = useHistory();
    const history = useHistory();

    const { id: urlId } = useParams();
    const reduxId = useSelector((state) => state.invoice.create.invoiceCustomer?.invoice_id);

    const step = useSelector((state) => state.invoice.create.step);

    const id = useMemo(() => {
        return isEdit ? urlId : reduxId;
    }, [isEdit, urlId, reduxId]);

    const submitting = useSelector(
        (state) => state.invoice.create.submitting,
    );

    const goToStep = useCallback((s) => {
        dispatch(actions.goToCreateInvoiceStep(s));
    }, [dispatch]);

    const FormStep = useMemo(() => {
        if (step >= 0 && step < steps.length) {
            return steps[step];
        } else if (steps.length === step) {
            return steps[steps.length - 1];
        } else {
            return steps[0];
        }
    }, [step]);

    useEffect(() => {
        if (step === steps.length) {
            push(`/app/invoices/${id}`);
        }
    }, [step, push, id]);

    const invoiceDetails = useSelector(selectors.invoiceDetails);
    const invoiceCustomer = useSelector(selectors.invoiceCustomer);
    const invoiceParticipants = useSelector(selectors.invoiceParticipants);
    const invoiceExtras = useSelector(selectors.invoiceExtras);

    useEffect(() => {
        if (isEdit && id) {
            dispatch(actions.requestFetchInvoiceData(id));
        }
    }, [dispatch, isEdit, id]);

    return (
        <div className="invoice">
            <Row>
                <Col>
                    <Space size="middle">
                        <Button
                            className="go-back"
                            onClick={() => history.goBack()}
                        >
                            <ArrowLeftIcon />
                        </Button>

                        <Typography.Text>
                            {t('Back')}
                        </Typography.Text>
                    </Space>
                </Col>
            </Row>

            <StepDisplay step={step} />

            <Row
                className="form"
                gutter={3}
            >
                <Col lg={14} xs={24}>
                    <div className="form-step">
                        <FormStep
                            form={form}
                            invoiceId={id}
                        />
                    </div>
                </Col>

                <Col lg={10} xs={24}>
                    <div className="form-preview">
                        <Preview
                            invoiceDetails={invoiceDetails}
                            invoiceCustomer={invoiceCustomer}
                            invoiceParticipants={invoiceParticipants}
                            invoiceExtras={invoiceExtras}
                        />
                    </div>
                </Col>
            </Row>

            <Row
                className="form-button"
                justify="end"
            >
                <Col>
                    <Space size={20}>
                        {(step > 0) && (
                            <Button onClick={() => goToStep(step - 1)}>
                                <Space size={25}>
                                    <ArrowLeftIcon />
                                    {t('Back').toUpperCase()}
                                </Space>
                            </Button>
                        )}

                        <Button
                            type="primary"
                            loading={submitting}
                            onClick={() => form.submit()}
                        >
                            <Space size={25}>
                                {step === 2 ? (
                                    t('Save').toUpperCase()
                                ) : (
                                    t('Save and go to next step').toUpperCase()
                                )}
                                <ArrowRightIcon />
                            </Space>
                        </Button>
                    </Space>
                </Col>
            </Row>
        </div>
    );
};

export default Create;
