/* eslint-disable max-len */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
    Row,
    Col,
    Divider,
    Select,
    Button,
    Card,
    Avatar,
    Tooltip,
    Typography,
    Space,
    Checkbox,
} from 'antd';

import BlockDescription from '@/components/Information/WithImage/Block';
import InlineDescription from '@/components/Information/WithoutImage/Inline';
import IncrementNumberInput from '@/components/IncrementNumberInput';
import {
    AdultIcon,
    BedIcon,
    ChildIcon,
    InfantIcon,
    TickIcon,
    InfoCircleIcon,
} from '@/assets/images/icons/screens';
import SelectRoom from './SelectRoom';

const returnPrice = (roomPriceId, prices) => {
    const price = prices.find((item) => item.id === roomPriceId);
    return price?.price.split(' ')[0];
};

const RoomDetails = ({
    roomListSelected,
    setRoomListSelected,
    dateStartEnd,
    item,
    type,
    tourId,
}) => {
    const { t } = useTranslation();

    const [isVisible, setVisible] = useState(false);

    const [checkedRoom, setCheckedRoom] = useState(false);

    const visibleAmenities = item.amenities?.length >= 6
        ? item.amenities?.slice(0, 5)
        : item.amenities;
    const tooltipAmenities = item.amenities?.length >= 6
        ? item.amenities?.slice(5, item.amenities.length)
        : [];
    const tooltipAmenitiesLength = tooltipAmenities.length;
    // data to submit
    const [noRooms, setNoRooms] = useState(0);
    const [extraBeds, setExtraBeds] = useState(0);
    const [roomPriceId, setRoomPriceId] = useState(null);
    const [selectedExtraServices, setSelectedExtraServices] = useState([]);

    return (
        <Row className="room">
            <Col lg={17}>
                <Row gutter={[0, 10]}>
                    <Col lg={6}>
                        <BlockDescription
                            icon={(
                                <AdultIcon />
                            )}
                            label={`${t('Max. Adults')} ${item.adults}`}
                            content={`${item.currency_icon}${item.price} / ${t('adult')}`}
                            spaceSize={14}
                        />
                        <BlockDescription
                            icon={(
                                <ChildIcon />
                            )}
                            label={`${t('Max. Children')} ${item.children}`}
                            content={`${item.currency_icon}${item.child_charges} / ${t('child')}`}
                            spaceSize={14}
                        />
                        {type !== 'Hotel' && (
                            <BlockDescription
                                icon={(
                                    <InfantIcon />
                                )}
                                label={`${t('Max. Infants')} ${item.infants}`}
                                content={`${item.currency_icon}${item.infant_charges} / ${t('infant')}`}
                                spaceSize={14}
                            />
                        )}
                        {type === 'Hotel' && (
                            <BlockDescription
                                icon={(
                                    <BedIcon />
                                )}
                                label={`${t('Extra Bed')} ${item.extra_beds}`}
                                content={`${t('Extra Charges')} ${item.extra_bed_charge}${item.extra_bed_charges_type === '0' ? item.currency_icon : item.extra_bed_charges_type}`}
                                spaceSize={14}
                            />
                        )}
                    </Col>
                    <Col lg={18}>
                        <Row>
                            <Col lg={2}>
                                <Divider type="vertical" />
                            </Col>
                            <Col lg={11}>
                                <div>
                                    <span className="title-amenitites">{t('Amenities')}</span>
                                    <div className="amenities">
                                        {visibleAmenities.map((amenity) => (
                                            <Col lg={24} key={amenity.id}>
                                                <Space size={10}>
                                                    <TickIcon />
                                                    {amenity.name}
                                                </Space>
                                            </Col>
                                        ))}
                                    </div>
                                    <Tooltip
                                        color="#ffff"
                                        open={isVisible}
                                        overlayClassName="amenities-tooltip"
                                        title={(
                                            <Typography.Text>
                                                {tooltipAmenities?.map((amenity) => (
                                                    <div key={amenity.id}>
                                                        <Space size={10}>
                                                            <TickIcon />
                                                            {amenity.name}
                                                        </Space>
                                                    </div>
                                                ))}
                                            </Typography.Text>
                                        )}
                                    >
                                        {tooltipAmenitiesLength !== 0 && (
                                            <Button
                                                type="text"
                                                onClick={() => setVisible(!isVisible)}
                                            >
                                                <Typography.Text underline>
                                                    {`${t('View more')}...`}
                                                </Typography.Text>
                                            </Button>
                                        )}
                                    </Tooltip>
                                </div>
                            </Col>
                            <Col lg={11}>
                                <span className="title-extra-services">{t('Extra Services')}</span>
                                <Space direction="vertical" className="extra-services">
                                    {item.extra_services.map((service) => (
                                        <Checkbox
                                            key={service.id}
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    setSelectedExtraServices(
                                                        (prev) => [...prev, service.id],
                                                    );
                                                } else {
                                                    setSelectedExtraServices(
                                                        (prev) => prev.filter((id) => id !== service.id),
                                                    );
                                                }
                                            }}
                                        >
                                            {service.name}
                                        </Checkbox>
                                    ))}
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                    {item.offer && (
                        <Col lg={24}>
                            <Card className="promotion-card-dashed">
                                <Card.Meta
                                    avatar={(
                                        <Avatar
                                            size={80}
                                            className={clsx(
                                                item.offer.type === 'Early booking' && 'early-booking',
                                                item.offer.type === 'Last minute' && 'last-minute',
                                                item.offer.type === 'Custom offer' && 'custom-offer',
                                                item.offer.type === 'free night' && 'free-night',
                                            )}
                                        >
                                            {`- ${item.offer.discount} ${item.offer.discount_type === 'Percentage' ? '%' : `${item.currency_icon}`}`}
                                        </Avatar>
                                    )}
                                    description={(
                                        <>
                                            <div
                                                className={clsx(
                                                    'offer-name',
                                                    item.offer.type === 'Early booking' && 'early-booking',
                                                    item.offer.type === 'Last minute' && 'last-minute',
                                                    item.offer.type === 'Custom offer' && 'custom-offer',
                                                    item.offer.type === 'free night' && 'free-night',
                                                )}
                                            >
                                                {item.offer.name}
                                            </div>
                                            <InlineDescription
                                                label={t('Category')}
                                                content={item.offer.type}
                                            />
                                            <InlineDescription
                                                label={t('Duration')}
                                                content={`${item.offer.date_start} - ${item.offer.date_end}`}
                                            />
                                        </>
                                    )}
                                />
                            </Card>
                        </Col>
                    )}
                </Row>
            </Col>

            <Col lg={1} className="reservation-list-divider">
                <Divider type="vertical" />
            </Col>

            <Col lg={6}>
                <Row gutter={[0, 10]} className="reservation-list_right-col">
                    <Col lg={24}>
                        {type === 'Hotel' && (
                            <div className="room_price">
                                <Select
                                    placeholder={t('Your choice')}
                                    onSelect={(value) => setRoomPriceId(value)}
                                    disabled={checkedRoom}
                                >
                                    {item.prices.map((option) => (
                                        <Select.Option
                                            key={option.id}
                                            value={option.id}
                                        >
                                            <Card>
                                                <Card.Meta
                                                    title={option.price}
                                                    description={option.name}
                                                />
                                            </Card>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </div>
                        )}
                    </Col>
                    <Col lg={24}>
                        {item.no_rooms !== '0' ? (
                            <div className="no-rooms">
                                <Row justify="space-between" align="middle">
                                    <Col span={12}>
                                        <span>{t('Rooms')}</span>
                                    </Col>
                                    <Col span={12}>
                                        <IncrementNumberInput
                                            max={item.no_rooms}
                                            hasInputBorder={false}
                                            onChange={setNoRooms}
                                            defaultCount={noRooms}
                                            disableButton={checkedRoom}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        ) : (
                            <div className="no-rooms">
                                <Typography.Text>
                                    {t('No available rooms')}
                                </Typography.Text>
                            </div>
                        )}
                    </Col>
                    <Col lg={24}>
                        <div>
                            {type === 'Hotel' && (
                                item.extra_beds !== '0' ? (
                                    <div>
                                        <Row justify="space-between" align="middle">
                                            <Col span={12}>
                                                <span>{t('Extra Beds')}</span>
                                            </Col>
                                            <Col span={12}>
                                                <IncrementNumberInput
                                                    max={item.extra_beds * noRooms}
                                                    hasInputBorder={false}
                                                    onChange={setExtraBeds}
                                                    defaultCount={extraBeds}
                                                    disableButton={checkedRoom}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                ) : (
                                    <div>
                                        <Typography.Text>
                                            {t('No available extra beds')}
                                        </Typography.Text>
                                    </div>
                                )
                            )}
                        </div>
                    </Col>
                    {item.no_rooms !== '0' && (
                        <Col lg={24}>
                            <SelectRoom
                                roomListSelected={roomListSelected}
                                setRoomListSelected={setRoomListSelected}
                                checkedRoom={checkedRoom}
                                setCheckedRoom={setCheckedRoom}
                                dateStartEnd={dateStartEnd}
                                roomId={item.id}
                                roomName={item.type_name}
                                roomPrice={type === 'Hotel' ? returnPrice(roomPriceId, item.prices) : item.price}
                                noRooms={noRooms}
                                adultNumber={noRooms * item.adults}
                                childNumber={noRooms * item.children}
                                infantNumber={noRooms * item.infants}
                                extraBeds={extraBeds}
                                roomPriceId={roomPriceId}
                                selectedExtraServices={selectedExtraServices}
                                extraBedCharge={item.extra_bed_charge}
                                extraBedChargeType={item.extra_bed_charges_type === '0' ? item.currency_icon : item.extra_bed_charges_type}
                                type={type}
                                tourId={tourId}
                                roomImg={item.images?.[0]?.path}
                                currency={item.currency_icon}
                            />
                        </Col>
                    )}
                    {item.no_rooms !== '0' && (dateStartEnd.length === 0 || !roomPriceId || noRooms === 0) && (
                        <Col lg={24} className="reservation-info">
                            <Space>
                                <InfoCircleIcon />
                                <span>{t('Choose a price type, room and start/end date before reservation')}</span>
                            </Space>
                        </Col>
                    )}
                </Row>
            </Col>
        </Row>
    );
};

export default RoomDetails;
